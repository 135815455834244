import swal from 'sweetalert'
import withAuth from '../../Security/withAuth'
import { useState } from 'react'
import { deleteProfileVisiteur } from '../../../api/ProfilVisiteur'

interface ParametresProps {
  authState: {
    email: string
    firstname: string
    lastname: string
    phoneNumber: string
    createdAt: Date
    updatedAt: Date
    userImg: string
    birthdate: string
    city: string
    isVisiteur: boolean
    connectedAccountID: string
  }
  authProps: {
    token: string
    isLoggedIn: boolean
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
  }
}

const Parametres: React.FC<ParametresProps> = ({ authState, authProps }: ParametresProps) => {
  const [isEnabled, setIsEnabled] = useState(true)

  const handleSubmit = async () => {
    swal({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Une fois supprimé, vous ne pourrez plus récupérer votre compte !',
      icon: 'warning',
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        let response
        if (authState.isVisiteur) response = await deleteProfileVisiteur(authProps.token)
        if (response && response?.success) {
          swal('Pouf ! Votre compte a été supprimé !', {
            icon: 'success',
          }).then(() => {
            window.location.href = '/'
          })
        } else {
          swal('Pouf ! Impossible de désactiver votre compte !', {
            icon: 'error',
          })
        }
      } else {
        swal('Votre compte est en sécurité !')
      }
    })
  }

  const toggleSwitch = () => {
    setIsEnabled(!isEnabled)
  }

  return (
    <div>
      <div className='dashboard-content-text'>
        <h1>Paramètres</h1>
        <h2>Définissez votre fuseau horaire</h2>
        <p>Gérez vos paramètres de fuseau horaire.</p>
        <div>
          <p>Fuseau horaire</p>
          <select className='select select-fuseau'>
            <option>Heure normale du Pacifique (PST) UTC-08h00</option>
          </select>
        </div>
      </div>

      <h2>Notifications</h2>
      <p>Gérez vos paramètres de notifications.</p>
      <div className='switch-container'>
        <p>
          Notifier par email lors de la réception d&apos;un message ou de demandes
          d&apos;actualités.
        </p>
        <label className='switch'>
          <input type='checkbox' checked={isEnabled} onChange={toggleSwitch} />
          <span className='slider round'></span>
        </label>
      </div>

      <hr />
      <div className='switch-container'>
        <p>Recevez des e-mails promotionnels.</p>
        <label className='switch'>
          <input type='checkbox' />
          <span className='slider round'></span>
        </label>
      </div>

      <hr />
      <div className='switch-container'>
        <p>Recevez des e-mails promotionnels.</p>
        <label className='switch'>
          <input type='checkbox' />
          <span className='slider round'></span>
        </label>
      </div>

      <button className='bg-red-500 text-white mt-10' onClick={handleSubmit}>
        Désactiver mon compte
      </button>
    </div>
  )
}

export default withAuth(Parametres)
