import Axios from 'axios'
import { ConnexionData } from '../components/Authentification/Connexion/Connexion.type'
import { InscriptionData } from '../components/Authentification/Inscription/Inscription.type'

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN

export const register = async (data: InscriptionData) => {
  try {
    const response = await Axios.post(`${SERVER_APP_DOMAIN}api/users/register`, data)
    return response
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const login = async (data: ConnexionData) => {
  try {
    const response = await Axios.post(`${SERVER_APP_DOMAIN}api/users/authenticate`, data)
    return response
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const resetPassword = async (email: string) => {
  try {
    const response = await Axios.put(`${SERVER_APP_DOMAIN}api/users/reset-password`, {
      email: email,
    })
    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const authenticate = async () => {
  try {
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/users/authenticate`)
    return response
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const isAuthenticate = async (token: string) => {
  try {
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/users/authenticate`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data // Supposons que les informations de l'utilisateur sont dans response.data
  } catch (error) {
    localStorage.removeItem('token')
    window.location.reload()
    window.location.href = '/inscription'
    return
  }
}
