import Axios from 'axios'

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN

export const createChat = async (token: string, firstEmail: string, secondEmail: string) => {
  try {
    const response = await Axios.post(
      `${SERVER_APP_DOMAIN}api/chats`,
      {
        firstEmail,
        secondEmail,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const getChatsByUser = async (token: string) => {
  try {
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/chat`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const deleteChat = async (token: string, chatId: number) => {
  try {
    const response = await Axios.delete(`${SERVER_APP_DOMAIN}api/chats/${chatId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const setStatusChat = async (token: string, chatId:number,status:number) => {
  try {
    const response = await Axios.put(`${SERVER_APP_DOMAIN}api/chat/${chatId}`, {status},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}
