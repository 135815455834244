/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from 'react'
import withAuth from '../../Security/withAuth'
import { updateAccount } from '../../../api/User'
import { updateProfileInfo } from '../../../api/Profile'
import etourneau from '../../../assets/images/image_oiseaux/etourneau.png'
import { getProfileVisiteur, updateProfileVisiteur } from '../../../api/ProfilVisiteur'
import { getAuthLink, verifyInfosAccountStripe, verifyIdentity } from '../../../api/Paiement'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import swal from 'sweetalert'
import { idCardStatus } from '../../../types/stripe'

interface DashboardProfilProps {
  authState: {
    email: string
    firstname: string
    lastname: string
    phoneNumber: string
    createdAt: Date
    updatedAt: Date
    userImg: string
    birthdate: string
    city: string
    isVisiteur: boolean
    connectedAccountID: string
    isVerifiedStripeAccount: boolean
    identityCardStatus: idCardStatus
  }
  authProps: {
    token: string
    isLoggedIn: boolean
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
  }
}

const Profil: React.FC<DashboardProfilProps> = ({ authState, authProps }: DashboardProfilProps) => {
  const { token } = authProps

  const [prenom, setPrenom] = useState(authState.firstname)
  const [nom, setNom] = useState(authState.lastname)
  const [city, setCity] = useState(authState.city)
  const [telephone, setTelephone] = useState(authState.phoneNumber)
  const [dateNaissance, setDateNaissance] = useState(authState.birthdate)
  const [imgSrc, setImgSrc] = useState(authState.userImg)
  const [isAvailable, setIsAvailable] = useState(true)
  const [loading, setLoading] = useState(true)
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const [identityNotVerified, setIdentityNotVerified] = useState(false)
  const [stripeAccountTosAccepted, setStripeAccountTosAccepted] = useState(false)
  const [loadedStripeInfos, setLoadedStripeInfos] = useState(false)
  // const [identityCardStatus, setIdentityCardStatus] = useState('')
  // const [identityVerificationSended, setIdentityVerificationSended] = useState(false)

  const verifAccountWithStripe = async () => {
    if (authState.connectedAccountID) {
      if (!loading) setLoading(true)

      const res = (await getAuthLink(
        authProps.token,
        authState.connectedAccountID,
        authState.email,
      )) as any
      if (res) {
        window.location.href = res
        return
      }
    }
  }

  // const initializeStripe = async () => {
  //   if (process.env.REACT_APP_STRIPE) {
  //     const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE)
  //     setStripe(stripeInstance)
  //   }
  // }

  // const checkIdentity = async () => {
  //   const res = await verifyIdentity(authState?.connectedAccountID)
  //   try {
  //     if (stripe && res) {
  //       const isVerified = await stripe.verifyIdentity(res.data.token)

  //       if (isVerified.error === null) {
  //         swal({
  //           title: 'Success !',
  //           text: 'Youpi !\n Votre identité est en cours de vérification par Stripe !',
  //           icon: 'success',
  //           className: 'text-center',
  //         })
  //       }
  //     }
  //     setIdentityVerificationSended(true)
  //     setTimeout(() => {
  //       setIdentityVerificationSended(false)
  //     }, 50)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  const checkInfosStripeAccount = async () => {
    if (authState?.connectedAccountID) {
      const res = await verifyInfosAccountStripe(authState?.connectedAccountID)

      if (res) {
        setLoadedStripeInfos(true)
        if (res.data.tos?.date) setStripeAccountTosAccepted(true)
        if (res.data.refresh) setTimeout(() => window.location.reload(), 50)
        res.data.due.forEach((due: string) => {
          if (due === 'individual.verification.document') setIdentityNotVerified(true)
        })
      }
    }
  }

  useEffect(() => {
    // initializeStripe()
    checkInfosStripeAccount()
  }, [])

  const handleToggle = async () => {
    setIsAvailable((prev) => !prev)
    const data = {
      disponibleImmediatement: !isAvailable,
    }
    await updateProfileVisiteur(token, data)
  }

  const handleImageClick = () => {
    document?.getElementById('fileInput')?.click()
  }

  const handleFileChange = async (event: any) => {
    const file = event.target.files?.[0]
    const formData = new FormData()
    formData.append('userImg', file)
    try {
      const response = await updateProfileInfo(token, formData)

      if (file && response.success === true) {
        const reader = new FileReader()
        reader.onload = () => {
          const imageUrl = reader.result as string
          setImgSrc(imageUrl)
        }
        reader.readAsDataURL(file)
      }
    } catch (error: any) {
      console.log(error)
    }
  }
  const fetchData = async () => {
    if (token) {
      try {
        const response = await getProfileVisiteur(token)
        // setIdentityCardStatus(response.data.profileVisiteur.identityCardStatus)
        setIsAvailable(response.data.profileVisiteur.disponibleImmediatement)
      } catch (error) {
        console.error(error)
      }
    }
  }

  useEffect(() => {
    if (authState.isVisiteur) {
      fetchData()
    }
  }, [token])

  return (
    <div>
      <div className='dashboard-content-text'>
        <h1>Profil</h1>
      </div>

      <div className='flex relative w-fit mb-10'>
        <img
          className='w-24 h-24 rounded-full shadow-md object-cover cover'
          src={imgSrc ? imgSrc : etourneau}
          alt='profile'
        />

        <div
          onClick={() => handleImageClick()}
          className='w-8 h-8 flex items-center justify-center rounded-full shadow-md object-cover cover bg-red-500 absolute bottom-0 right-0 cursor-pointer'
        >
          <input
            type='file'
            id='fileInput'
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
          >
            <path
              d='M14.0514 3.73889L15.4576 2.33265C16.0678 1.72245 17.0572 1.72245 17.6674 2.33265C18.2775 2.94284 18.2775 3.93216 17.6674 4.54235L5.69349 16.5162C5.25292 16.9568 4.70953 17.2806 4.11241 17.4585L1.875 18.125L2.54148 15.8876C2.71936 15.2905 3.04321 14.7471 3.48377 14.3065L14.0514 3.73889ZM14.0514 3.73889L16.25 5.93749'
              stroke='white'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        </div>
      </div>

      <div className='dashboard-content-profil-infos space-y-8'>
        <div className='space-y-8'>
          <div className='flex space-x-4'>
            <div className='w-1/2'>
              <label className='label'>Prénom :</label>
              <div className='input input-email'>
                <input
                  type='text'
                  placeholder={prenom}
                  className='input text-gray-400 cursor-not-allowed'
                  value={prenom || ''}
                  onChange={(e) => setPrenom(e.target.value)}
                  readOnly
                />
              </div>
            </div>
            <div className='w-1/2'>
              <label className='label'>Nom de famille :</label>
              <div className='input input-email'>
                <input
                  type='text'
                  placeholder={nom}
                  className='input text-gray-400 cursor-not-allowed'
                  value={nom || ''}
                  onChange={(e) => setNom(e.target.value)}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>

        <div className='space-y-8'>
          <div className='flex space-x-4'>
            <div className='w-1/2'>
              <label className='label'>Ville :</label>
              <div className='input input-email'>
                <input
                  type='text'
                  placeholder={city}
                  className='input'
                  value={city || ''}
                  onChange={async (e) => {
                    setCity(e.target.value)
                    const data = {
                      city: e.target.value,
                    }
                    await updateAccount(token, data)
                  }}
                />
              </div>
            </div>
            <div className='w-1/2'>
              <label className='label'>N ° de téléphone :</label>
              <div className='input input-email'>
                <input
                  type='text'
                  placeholder={telephone}
                  className='input'
                  value={telephone || ''}
                  onChange={async (e) => {
                    setTelephone(e.target.value)
                    const data = {
                      phoneNumber: e.target.value,
                    }
                    await updateAccount(token, data)
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='space-y-8 '>
          <div className='flex space-x-4 pr-10 justify-between items-end'>
            <div className='w-1/2'>
              <label className='label'>Date de naissance :</label>
              <div className='input input-email'>
                <input
                  type='date'
                  placeholder={dateNaissance}
                  className='input'
                  value={new Date(dateNaissance).toISOString().split('T')[0] || ''}
                  onChange={async (e) => {
                    setDateNaissance(e.target.value)
                    const data = {
                      birthdate: e.target.value,
                    }

                    await updateAccount(token, data)
                  }}
                />
              </div>
            </div>
            {authState.isVisiteur && (
              <div className='flex items-center'>
                <label htmlFor='toggle' className='flex items-center cursor-pointer'>
                  <div className='relative'>
                    <input
                      type='checkbox'
                      id='toggle'
                      className='sr-only'
                      checked={isAvailable}
                      onChange={handleToggle}
                    />
                    <div
                      className={`block w-10 h-6 rounded-full ${
                        isAvailable ? 'bg-orange-600' : 'bg-orange-200'
                      }`}
                    ></div>
                    <div
                      className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform ${
                        isAvailable ? 'transform translate-x-full' : ''
                      }`}
                    ></div>
                  </div>

                  <div className='ml-3'>
                    <span
                      className={`block text-sm ${isAvailable ? 'text-black' : 'text-gray-400'}`}
                    >
                      Disponibilité immédiate
                    </span>
                  </div>
                </label>
              </div>
            )}
          </div>
        </div>

        {/* 
identity for connected user is verified on account_onboarding */}
        {/* {authState.isVisiteur && (
          <div className='flex flex-col gap-1 border p-3 border-yellow-400 rounded-lg'>
            {!identityCardStatus && (
              <p className='font-semibold'>
                Vérification d'identité nécessaire pour commencer à effectuer vos premières visites.
              </p>
            )}
            {identityCardStatus === 'verified' && (
              <p className='font-semibold'>Votre identité a été vérifiée avec succès.</p>
            )}
            {identityCardStatus === 'failed' && (
              <p className='font-semibold'>
                La vérification de votre identité a échoué. Veuillez réessayer ou contacter le
                support.
              </p>
            )}
            {identityCardStatus === 'requires_input' && (
              <p className='font-semibold'>
                Des informations supplémentaires sont nécessaires pour vérifier votre identité.
                Veuillez compléter les informations demandées.
              </p>
            )}
            {identityCardStatus === 'processing' && (
              <p className='font-semibold'>
                Votre vérification d'identité est en cours de traitement par stripe. Veuillez
                patienter un moment.
              </p>
            )}
            {identityCardStatus === 'canceled' && (
              <p className='font-semibold'>
                La vérification de votre identité a été annulée. Veuillez recommencer le processus.
              </p>
            )}
            {identityCardStatus !== 'verified' && (
              <div className='flex justify-end'>
                <button
                  onClick={checkIdentity}
                  className='border rounded-lg hover:bg-gray-50 hover:border-gray-400 transition-all duration-100'
                >
                  Vérifier
                </button>
              </div>
            )}
          </div>
        )} */}
        {loadedStripeInfos && (
          <>
            {authState.isVisiteur &&
              authState.connectedAccountID &&
              authState.isVerifiedStripeAccount && (
                <div className='flex flex-col gap-3 border p-3  border-yellow-400 rounded-lg  bg-yellow-50'>
                  <p className='font-semibold  text-gray-800'>
                    Accédez à vos informations de paiement via votre dashboard sur notre site
                    partenaire Stripe
                  </p>
                  <div className='flex justify-end'>
                    <button
                      onClick={() =>
                        window.open('https://connect.stripe.com/express_login', '_blank')
                      }
                      className='px-4 py-2 bg-yellow-400 text-white font-bold rounded-lg shadow-md hover:bg-yellow-500 transition-all duration-200'
                    >
                      Accédez
                    </button>
                  </div>
                </div>
              )}

            {authState.isVisiteur && !stripeAccountTosAccepted && (
              <div className=' flex flex-col gap-1  border p-3 border-red-400 rounded-lg bg-red-50'>
                <p className='font-semibold'>
                  Vérification de votre compte Stripe obligatoire pour effectuer vos premières
                  visites
                </p>
                <div className='flex justify-end'>
                  <button
                    onClick={() => verifAccountWithStripe()}
                    className=' px-4 py-2 bg-red-400 text-white font-bold rounded-lg shadow-md hover:bg-red-500 transition-all duration-200'
                  >
                    Vérifier
                  </button>
                </div>
              </div>
            )}

            {authState.isVisiteur && stripeAccountTosAccepted && identityNotVerified && (
              <div className=' flex flex-col gap-1  border p-3 border-red-400 rounded-lg bg-red-50'>
                <p className='font-semibold'>
                  Vérification de votre pièce d'identité obligatoire pour effectuer vos premières
                  visites
                </p>
                <div className='flex justify-end'>
                  <button
                    onClick={() => verifAccountWithStripe()}
                    className=' px-4 py-2 bg-red-400 text-white font-bold rounded-lg shadow-md hover:bg-red-500 transition-all duration-200'
                  >
                    Vérifier
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default withAuth(Profil)
