import Axios from 'axios'
import { io } from 'socket.io-client'

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN
const serverAppDomain = process.env.REACT_APP_WEBSOCKET_URL
const socket = io(serverAppDomain as string, { transports: ['websocket'] })

export const createNotifications = async (token: string,name:string, userEmail: string[], content: object) => {
  try {
    const id  = `${socket.id}${Math.random()}`

const response = []
    for(const email of userEmail){  socket.emit('notif', {
      content,
      name,
      userEmail:email,
      notifId:id,
      socketID: socket.id,
    })
 const res = await Axios.post(
    `${SERVER_APP_DOMAIN}api/notifications`,
    {
      userEmail:email,
      content,
      id
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
  response.push(res.data)

}
  
    return response
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const getNotifications = async (token: string, userEmail: string) => {
  try {
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/notifications/${userEmail}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}



export const setNotificationWatched = async (token: string, notifId: string) => {
  try {
    const response = await Axios.put(`${SERVER_APP_DOMAIN}api/notifications/${notifId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}



export const deleteNotification = async (token: string, notifId: string) => {
    try {
      const response = await Axios.delete(`${SERVER_APP_DOMAIN}api/notifications/${notifId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return response.data
    } catch (error) {
      console.error('Erreur lors de la requête :', error)
      throw error
    }
  }
  