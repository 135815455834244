import DateSlider from '../../Swiper/DateSlider'

interface DisponibiliteProps {
  token: string
}

const Disponibilite: React.FC<DisponibiliteProps> = ({ token }: DisponibiliteProps) => {
  return (
    <div>
      <div className='dashboard-content-text'>
        <h1>Tableau de bord</h1>
        <h2>Votre disponibilité pour les 2 prochaines semaines</h2>
        <p>
          Confirmez votre disponibilité pour mettre en avant votre profil dans les résultats de
          recherche. Désélectionnez les jours où vous n’êtes pas disponible.
        </p>
        <p className='info-calendrier'>Dernière mise à jour : il y a 7 jours</p>
      </div>

      <DateSlider token={token} isEditable={true} />
    </div>
  )
}

export default Disponibilite
