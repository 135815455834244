import Axios from 'axios'

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN

export const getProfileInfo = async (token: string) => {
  try {
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/profiles/my-profile`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const updateProfileInfo = async (token: string, data: FormData) => {
  try {
    // Définissez les en-têtes de la requête
    const myHeaders = {
      Authorization: `Bearer ${token}`,
    }

    // Effectuez la requête pour mettre à jour le profil
    const response = await Axios.put(`${SERVER_APP_DOMAIN}api/profiles/update-profile`, data, {
      headers: myHeaders,
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}
