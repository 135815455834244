import Axios from 'axios'

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN

export const createAvis = async (token: string, avisData: object) => {
  try {
    const response = await Axios.post(`${SERVER_APP_DOMAIN}api/avis`, avisData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const getAvisByUser = async (token: string) => {
  try {
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/avis`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const updateAvis = async (token: string, avisId: number, avisData: object) => {
  try {
    const response = await Axios.put(`${SERVER_APP_DOMAIN}api/avis/${avisId}`, avisData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const deleteAvis = async (token: string, avisId: number) => {
  try {
    const response = await Axios.delete(`${SERVER_APP_DOMAIN}api/avis/${avisId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}
