import React, { useState } from 'react'
import kiwi from '../../../assets/gif/bird.gif'
import birdHouse from '../../../assets/images/bird-house.png'
import logoGoogle from '../../../assets/svg/Googlelogo.svg'
import Highlight1 from '../../../assets/svg/Highlight_1.svg'
import Highlight2 from '../../../assets/svg/Highlight_2.svg'
import Highlight3 from '../../../assets/svg/Highlight_3.svg'
import './index.css'
import './index-tablette.css'
import './index-mobile.css'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { MailOutline } from '@mui/icons-material'
import { ConnexionData } from './Connexion.type'
import { login } from '../../../api/Authentification'
import Alert from '../../Alert/Alert'

const Connexion: React.FC = () => {
  const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN
  const [showPassword, setShowPassword] = useState(false)
  const [showAlert, setShowAlert] = useState({
    email: false,
    password: false,
    invalid: false,
  })

  const handleShowAlert = (name: string) => {
    setShowAlert((prevData) => ({
      ...prevData,
      [name]: true,
    }))
  }

  const handleCloseAlert = (name: string) => {
    setShowAlert((prevData) => ({
      ...prevData,
      [name]: false,
    }))
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const initialData: ConnexionData = {
    email: '',
    password: '',
  }

  const [data, setData] = useState(initialData)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  function handleLogin() {
    if (data.email === '') {
      handleShowAlert('email')
    } else if (data.password === '') {
      handleCloseAlert('email')
      handleShowAlert('password')
    } else {
      login(data)
        .then((response) => {
          localStorage.setItem('token', JSON.stringify(response?.data.token.replace('Bearer ', '')))
          localStorage.setItem('userData', JSON.stringify(response?.data.user))
          const dataBasket = localStorage.getItem('basketToFinish')
          const basketToFinish = dataBasket ? JSON.parse(dataBasket) : ''
          let url = '/'
          if (basketToFinish) url = '/programmation-visite'
          window.location.href = url
        })
        .catch(() => {
          handleShowAlert('invalid')
        })
    }
  }

  return (
    <div className='connexion'>
      <div className='connexion-content'>
        <h1>Connectez-vous</h1>
        <div className='connexion-form'>
          <a href={SERVER_APP_DOMAIN + 'api/auth/google'} className='connexionGoogle'>
            <img src={logoGoogle} alt='logo de Google' />
            Continuer avec Google
          </a>
          <div className='ou-container'>
            <div className='line'></div>
            <div className='or'>OU</div>
            <div className='line'></div>
          </div>
          <div>
            <label className='label'>E-mail :</label>
            <div className='input input-email'>
              <input
                type='text'
                placeholder='Entrez votre adresse email'
                className='input'
                name='email'
                value={data.email}
                onChange={handleInputChange}
              />
              <MailOutline />
            </div>
          </div>
          <div>
            <label className='label'>Mot de passe :</label>
            <div className='input input-password'>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder='Tapez votre mot de passe'
                className='input'
                name='password'
                value={data.password}
                onChange={handleInputChange}
              />
              {showPassword ? (
                <VisibilityOffIcon onClick={togglePasswordVisibility} />
              ) : (
                <VisibilityIcon onClick={togglePasswordVisibility} />
              )}
            </div>
          </div>
          {showAlert.email && (
            <Alert
              type='error'
              message='Email invalide'
              onClose={() => handleCloseAlert('email')}
            />
          )}
          {showAlert.password && (
            <Alert
              type='error'
              message='Mot de passe invalide'
              onClose={() => handleCloseAlert('password')}
            />
          )}
          {showAlert.invalid && (
            <Alert
              type='error'
              message='Identifiant ou mot de passe invalide'
              onClose={() => handleCloseAlert('invalid')}
            />
          )}
        </div>

        <button className='bg-orange' onClick={handleLogin}>
          Se connecter
        </button>
        <p className='connexion-compte text-white flex items-center mt-5'>
          Vous avez oubliez votre
          <a href='/mot-de-passe-oublie' className='bg-orange py-1 px-3 rounded-lg'>
            <span className='text-white'>mot de passe ?</span>
          </a>
        </p>
      </div>
      <div className='authentification-bird-house'>
        <div className='authentification-bird-house-highlight'>
          <img src={birdHouse} alt='birdHouse' className='birdHouse' />
          <img src={Highlight3} alt='' className='Highlight3' />
        </div>
        <img src={kiwi} alt='kiwi' className='kiwi' />
        <img src={Highlight1} alt='' className='Highlight1' />
        <img src={Highlight2} alt='' className='Highlight2' />
      </div>
    </div>
  )
}

export default Connexion
