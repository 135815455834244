import React, { useState } from 'react'
import kiwi from '../../../assets/gif/bird.gif'
import birdHouse from '../../../assets/images/bird-house.png'
import Highlight1 from '../../../assets/svg/Highlight_1.svg'
import Highlight2 from '../../../assets/svg/Highlight_2.svg'
import Highlight3 from '../../../assets/svg/Highlight_3.svg'
import { MailOutline } from '@mui/icons-material'
import './MotDePasseOublie.css' // Assurez-vous d'avoir un fichier CSS correspondant
import { resetPassword } from '../../../api/Authentification'
import Alert from '../../Alert/Alert'
import { validateEmail } from '../../../utils/Data.utils'
import swal from 'sweetalert'

const MotDePasseOublie: React.FC = () => {
  const [email, setEmail] = useState('')

  const [showAlert, setShowAlert] = useState({
    email: false,
    invalid: false,
    sucessEmailReset: false,
  })

  const handleShowAlert = (name: string) => {
    setShowAlert((prevData) => ({
      ...prevData,
      [name]: true,
    }))
  }

  const handleCloseAlert = (name: string) => {
    setShowAlert((prevData) => ({
      ...prevData,
      [name]: false,
    }))
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      if (!validateEmail(email) || email === '') {
        handleShowAlert('email')
      } else {
        const result = await resetPassword(email)
        if (result.code === 200) {
          swal('Youpi ! Un email vous à été envoyé !', {
            icon: 'success',
          }).then(() => {
            window.location.href = '/'
          })
        } else {
          swal('Erreur ! ' + result.message, {
            icon: 'error',
          })
        }
      }
    } catch (error) {
      console.error('Erreur :', error)
    }
  }

  return (
    <div className='motDePasseOublie'>
      <div className='motDePasseOublie-content'>
        <h1>
          Vous avez oublié votre <span>mot de passe ?</span>
        </h1>
        <div className='motDePasseOublie-form'>
          <div>
            <label className='label'>E-mail :</label>
            <div className='input input-email'>
              <MailOutline />
              <input
                type='text'
                placeholder='Entrez votre adresse email'
                className='input'
                name='email'
                value={email}
                onChange={handleEmailChange}
              />
            </div>
          </div>
          {showAlert.email && (
            <Alert
              type='error'
              message='Email invalide'
              onClose={() => handleCloseAlert('email')}
            />
          )}
          {showAlert.invalid && (
            <Alert
              type='error'
              message='Email inconnu'
              onClose={() => handleCloseAlert('invalid')}
            />
          )}
          {showAlert.sucessEmailReset && (
            <Alert
              type='info'
              message='Un mail vous a été evoyé pour changer votre mot de passe'
              onClose={() => handleCloseAlert('sucessEmailReset')}
            />
          )}
        </div>
        <button className='bg-orange' onClick={handleSubmit}>
          Valider
        </button>
      </div>
      <div className='authentification-bird-house'>
        <div className='authentification-bird-house-highlight'>
          <img src={birdHouse} alt='birdHouse' className='birdHouse' />
          <img src={Highlight3} alt='' className='Highlight3' />
        </div>
        <img src={kiwi} alt='kiwi' className='kiwi' />
        <img src={Highlight1} alt='' className='Highlight1' />
        <img src={Highlight2} alt='' className='Highlight2' />
      </div>
    </div>
  )
}

export default MotDePasseOublie
