import React from 'react';
import './PageNotFound.css';

const PageNotFound: React.FC = () => {
  return (
    <div className="not-found">
      <div className="message">
        <h1>404 Not Found</h1>
        <a href="/">Retour à l&apos;accueil</a>
      </div>
    </div>
  );
};

export default PageNotFound;
