const ServerDown = () => {
  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='bg-red-500 p-8 rounded-lg shadow-lg text-white'>
        <h2 className='text-2xl font-semibold mb-4'>Serveur Indisponible</h2>
        <p>Le serveur est actuellement indisponible. Veuillez réessayer ultérieurement.</p>
      </div>
    </div>
  )
}

export default ServerDown
