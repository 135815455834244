/* eslint-disable react/no-unescaped-entities */
const GeneralConditionsPage = () => {
  return (
    <div className='min-h-screen bg-gray-100 p-6 flex flex-col items-center'>
      <div className='bg-white rounded-lg shadow-lg p-8 max-w-4xl'>
        <h1 className='text-4xl font-bold mb-4 text-center' style={{ color: '#ff5733' }}>
          Conditions générales
        </h1>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            1. Acceptation des Conditions ; Modifications.
          </h2>
          <p className='text-gray-700 mb-2'>
            Les présentes Conditions Générales de Service (les "Conditions") constituent un accord
            juridique contraignant entre vous et Voyo, une société constituée en vertu des lois
            Françaises et dont le siège social est situé à [adresse entreprise] ("Voyo", "nous",
            "notre" et "nos"). Les Conditions régissent votre utilisation de notre site internet,
            applications logicielles, ressources et services en ligne permettant la mise en relation
            entre des particuliers souhaitant déléguer la visite d'un bien à louer et des personnes
            intéressées par la visite de ces biens, ainsi que tout autre service ou produit que nous
            pouvons offrir de temps à autre (collectivement, notre "Service"). Les Conditions
            régissent toute utilisation du Service, que vous y accédiez à partir de notre site
            internet à l'adresse http://voyo.fr (le "Site"), de nos applications et sites web
            mobiles, de nos offres d'assistance en ligne, ou de tout autre point d'accès que nous
            mettons à votre disposition. Les Conditions de Garantie, les Conditions Générales de
            Voyo, la Politique de protection de la visite et les autres politiques applicables à
            votre utilisation du Service sont incorporées par référence aux présentes Conditions
            Générales de service. EN ACCEPTANT CES CONDITIONS AU COURS DU PROCESSUS D'INSCRIPTION OU
            EN ACCÉDANT OU EN UTILISANT LE SERVICE SANS INSCRIPTION, VOUS ACCEPTEZ CES CONDITIONS.
            SI VOUS N'ÊTES PAS D'ACCORD AVEC CES CONDITIONS, VOUS NE DEVEZ PAS LES ACCEPTER, AUQUEL
            CAS VOUS N'AVEZ PAS LE DROIT D'UTILISER LE SERVICE.
            <br />
            Vous comprenez et acceptez que nous puissions modifier les Conditions de temps à autre,
            et que de telles modifications seront effectives lorsque nous publierons les Conditions
            modifiées sur le Service Voyo, dans la limite permise par le droit applicable. Votre
            accès et utilisation continue du Service après publication de notre part des Conditions
            modifiées sera réputé valoir acceptation et respect de votre part des Conditions
            modifiées.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            2. Le Service Voyo
          </h2>

          <h3 className='text-gray-700 underline mb-2 text-base'>2.1 Nature du Service Voyo.</h3>
          <p className='text-gray-700 mb-2'>
            Le Service Voyo est une plateforme en ligne comprenant une application web de bureau, et
            d'autres outils, ressources et services connexes permettant la mise en relation, la
            communication et les interactions entre les particuliers souhaitant déléguer la visite
            d'un bien à louer (les "Mandants") et les personnes intéressées par la visite de ces
            biens (les "Visiteurs"). Le Service Voyo inclut également des fonctionnalités
            d'assistance, ainsi que d'autres services. Certaines composantes du Service Voyo peuvent
            être soumises à des frais, comme décrit ci-dessous dans l'article 9.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            2.2 Voyo ne fournit pas de services de visite.
          </h3>
          <p className='text-gray-700 mb-2'>
            Voyo agit en tant qu'intermédiaire neutre entre les Mandants et les Visiteurs. Voyo ne
            fournit pas directement les services de visite et, à l'exception des fonctionnalités
            d'assistance et des autres ressources et assistance spécifiquement décrites dans le
            Service Voyo. Nous ne garantissons pas la qualité des services de visite effectués par
            les Visiteurs, ni n'assumons aucune responsabilité quant à vos interactions et relations
            avec les utilisateurs. Les Visiteurs répertoriés sur Voyo ne sont pas sous la direction
            ou le contrôle de Voyo, et ils déterminent librement comment effectuer les visites. Bien
            que nous puissions fournir des conseils généraux sur la sécurité et les procédures de
            visite sur notre site, Voyo n'emploie, ne recommande ni ne cautionne les Visiteurs ou
            les Mandants. Dans la mesure permise par la loi applicable, nous ne serons pas
            responsables des actions ou du comportement des Visiteurs ou des Mandants, en ligne ou
            hors ligne. Vous devez faire preuve de prudence et utiliser votre jugement indépendant
            avant de choisir un Visiteur, de déléguer une visite ou d'interagir de quelque manière
            que ce soit avec les autres utilisateurs du Service Voyo. Les Mandants et les Visiteurs
            sont seuls responsables des décisions qu'ils prennent dans leur intérêt respectif.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            2.3 Décharge de responsabilité.
          </h3>
          <p className='text-gray-700 mb-2'>
            Voyo n'est pas responsable des réclamations, blessures, pertes, préjudices et/ou
            dommages découlant de vos interactions ou transactions avec d'autres utilisateurs, ainsi
            que des actions et/ou omissions des Visiteurs et des Mandants, que ce soit en ligne ou
            hors ligne. Vous reconnaissez et acceptez que, dans la limite permise par la loi
            applicable, VOTRE UTILISATION ET/OU VOTRE FOURNITURE DE SERVICES DE VISITE S'EFFECTUE
            EXCLUSIVEMENT À VOS PROPRES RISQUES ET PÉRILS. (Toute responsabilité financière que Voyo
            peut avoir envers ses utilisateurs en relation avec leur conduite est limitée aux
            obligations de remboursement spécifiées dans la Garantie Voyo).
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            2.4 Transactions entre les Mandants et les Visiteurs.
          </h3>
          <p className='text-gray-700 mb-2'>
            Le Service Voyo peut être utilisé pour trouver un visiteur, ainsi que pour faciliter les
            paiements, mais toutes les transactions effectuées via le Service Voyo sont
            exclusivement entre les Mandants et les Visiteurs. À l'exception des remboursements
            limités et de la "Protection de visite" spécifiés à l'article 9.6 et de la Garantie
            Voyo, vous acceptez que Voyo ne puisse être tenue responsable des dommages associés aux
            services de visite (qui peuvent inclure des dommages matériels ou des problèmes liés au
            bien visité) ou résultant de toute autre transaction entre les utilisateurs du Service
            Voyo.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>2.5 Réservations.</h3>
          <p className='text-gray-700 mb-2'>
            Les Mandants et les Visiteurs effectuent des transactions entre eux à partir du Service
            Voyo lorsqu'ils conviennent tous deux d'une "réservation" comprenant les frais, la
            période, la politique d'annulation et les autres conditions de la visite via le
            mécanisme de réservation fournit via le Service Voyo (une "Réservation"). Une
            réservation peut être initiée par uniquement par un Mandant en sélectionnant le(s)
            type(s) de visite à effectuer et en suivant les instructions qui s'affichent à l'écran.
            Si vous êtes Mandant et que vous lancez une réservation, vous acceptez de payer les
            frais de visite décrits dans la réservation lorsque vous cliquez sur "Demande de
            réservation". Si vous êtes Mandant et qu'un Visiteur accepte une Réservation, vous
            acceptez de payer les frais de visite décrits dans la Réservation lorsque vous cliquez
            sur "Payer maintenant". Toutes les demandes sont soumises à l'acceptation de la partie
            réceptrice. Le récepteur n'est pas obligé d'accepter votre demande (ou toute autre
            demande) et peut, à sa discrétion, la refuser pour quelque raison que ce soit. Une fois
            que vous avez effectué une Réservation, vous acceptez d'honorer le prix et les autres
            conditions de cette Réservation, tels qu'indiqués dans la confirmation de Réservation.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            2.6 Les Mandants sont seuls responsables de l'évaluation des visiteurs.
          </h3>
          <p className='text-gray-700 mb-2'>
            Les Mandants sont seuls responsables de l'évaluation de l'aptitude des Visiteurs à
            effectuer les visites qu'ils proposent. Veuillez consulter notre Centre d'aide pour
            obtenir des conseils sur la manière de prendre des décisions éclairées concernant le
            choix des Visiteurs. Bien que Voyo effectue une vérification limitée des profils des
            Visiteurs et facilite les vérifications des antécédents ou de l'identité des Visiteurs
            effectuées par un prestataire tiers, cette vérification est limitée et Voyo ne garantit
            pas que cette vérification est exacte, complète, concluante ou à jour. De même, Voyo
            n'approuve pas les avis sur les Visiteurs peuvent être publiés par d'autres Mandants sur
            le Service Voyo, et Voyo ne prend aucun engagement quant à l'exactitude ou à la
            légitimité de ces avis.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>2.7 Google Maps.</h3>
          <p className='text-gray-700 mb-2'>
            L'utilisation du Service Voyo nécessite l'utilisation des fonctionnalités et du contenu
            de Google Maps, qui sont soumis aux : Conditions d'utilisation supplémentaires de Google
            Maps/Google Earth en vigueur à l'adresse https://maps.google.com/help/terms_maps.html (y
            compris la Politique d'utilisation en vigueur à l'adresse
            https://cloud.google.com/maps-platform/terms/aup/); aux Règles de confidentialité de
            Google en vigueur à l'adresse https://www.google.com/policies/privacy/ (collectivement,
            les "Conditions Google"). En utilisant le Service Voyo, vous reconnaissez et acceptez
            les Conditions Google telles qu'elles s'appliquent à vous (par exemple, en tant
            qu’utilisateur final). Toute utilisation non autorisée des fonctionnalités et du contenu
            de Google Maps peut entraîner la suspension ou la résiliation de votre accès au Service
            Voyo.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            3. Certification de Conformité avec la loi Applicable.
          </h2>

          <p className='text-gray-700 mb-2'>
            En accédant au Service Voyo et en l'utilisant, vous certifiez que : <br />
            1. Vous êtes âgé d'au moins 18 ans ou de l'âge de la majorité dans votre juridiction,
            selon l'âge le plus élevé, <br />
            2. Vous vous conformerez à toutes les lois et réglementations applicables à vos
            activités menées par le biais du Service Voyo ou liées à celui-ci.
            <br />
            Pour les Mandants, cela signifie, entre autres, que vous vous assurez que les biens à
            visiter sont légalement mis à disposition, que vous respectez les lois et
            réglementations en matière de location de biens, ainsi que toute obligation fiscale ou
            autre obligation légale associée. Vous êtes également responsable de vous conformer à
            toutes les lois et réglementations relatives à la protection des données personnelles et
            à la confidentialité des informations des Visiteurs. Pour les Visiteurs, cela signifie,
            entre autres, que vous certifiez être légalement autorisé à effectuer des visites de
            biens dans la juridiction où vous proposez vos services, que vous avez respecté et que
            vous respecterez toutes les lois et réglementations qui vous sont applicables, y compris
            celles relatives à la confidentialité et à la protection des données personnelles. Vous
            reconnaissez que Voyo est en droit de se fier aux certifications de votre part prises en
            application des présentes, et qu'elle n'est pas responsable de s'assurer que tous les
            utilisateurs se sont conformés aux lois et réglementations applicables, et qu'elle ne
            sera pas responsable de tout manquement d'un utilisateur à cet égard.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            4. Utilisation du Service Voyo ; Suspension.
          </h2>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            4.1 Votre conduite sur le Service Voyo.
          </h3>
          <p className='text-gray-700 mb-2'>En utilisant le Service Voyo, vous acceptez :</p>
          <ul className='list-disc list-inside text-gray-700 mb-2'>
            <li>
              Utiliser le Service Voyo conformément à la loi et dans le respect de son objectif
              initial.
            </li>
            <li>
              Ne pas utiliser le Service Voyo pour organiser des visites de biens qui contreviennent
              aux lois et réglementations en vigueur.
            </li>
            <li>
              Éviter d'introduire des virus ou tout autre élément malveillant dans le Service Voyo.
            </li>
            <li>
              Ne pas utiliser le Service Voyo de manière illégale ou en violation des lois en
              vigueur.
            </li>
            <li>
              Négocier et finaliser les services uniquement au sein du Service Voyo, sans chercher à
              les conclure en dehors de la plateforme.
            </li>
            <li>
              Ne pas utiliser le Service Voyo pour promouvoir d'autres produits, services ou
              activités concurrentes à Voyo.
            </li>
            <li>
              Publier des commentaires objectifs, basés sur votre propre expérience et respectueux
              des présentes Conditions.
            </li>
            <li>
              Ne pas partager de contenu à caractère pornographique, menaçant, harcelant, abusif,
              diffamatoire ou violant les droits de propriété intellectuelle d'autrui.
            </li>
            <li>
              Ne pas pratiquer le spam ou envoyer des communications commerciales non sollicitées.
            </li>
            <li>
              Utiliser le Service Voyo à des fins personnelles uniquement et ne pas usurper
              l'identité d'une autre personne.
            </li>
            <li>
              Ne pas céder votre compte à une tierce personne ou engager des activités frauduleuses.
            </li>
            <li>
              Fournir des informations véridiques et précises lors de la création de votre profil et
              éviter de créer plusieurs comptes.
            </li>
            <li>
              Ne pas perturber le fonctionnement du Service Voyo ni l'expérience des autres
              utilisateurs.
            </li>
            <li>
              Ne pas solliciter des informations confidentielles d'autres utilisateurs, telles que
              des coordonnées bancaires.
            </li>
          </ul>

          <h3 className='text-gray-700 underline mb-2 text-base'>4.2 Suspension et Résiliation.</h3>
          <p className='text-gray-700 mb-2'>
            Vous reconnaissez et acceptez que nous ne sommes pas tenus de fournir le Service Voyo
            dans une zone géographique spécifique, et que nous pouvons décider d'interrompre le
            Service à tout moment. Nous nous réservons le droit de suspendre ou de résilier votre
            accès au Service Voyo dans les situations suivantes : (1) si nous estimons, à notre
            seule discrétion, que votre comportement sur le Site ou le Service Voyo est inapproprié,
            dangereux, malhonnête ou en violation de ces conditions ; ou (2) si nous jugeons
            nécessaire, à notre seule discrétion, de protéger Voyo, ses utilisateurs ou le public.
            Vous pouvez également suspendre ou mettre fin à votre utilisation du Service Voyo à tout
            moment, sans justification nécessaire. Si vous souhaitez désactiver votre compte,
            veuillez nous contacter. Veuillez noter que les obligations de paiement en cours
            subsistent malgré la suspension ou la résiliation de votre compte.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            5. Inscription ; Sécurité du Compte.
          </h2>
          <p className='text-gray-700 mb-2'>
            Pour accéder à certaines fonctionnalités du Service Voyo, vous devrez créer un nom
            d'utilisateur, un mot de passe et un profil utilisateur. En choisissant d'utiliser le
            Service Voyo, vous vous engagez à fournir des informations précises vous concernant et à
            les maintenir à jour. Vous acceptez de ne pas vous faire passer pour quelqu'un d'autre
            et de ne pas créer plus d'un compte (à moins que Voyo ne suspende ou résilie votre
            compte, auquel cas vous ne devez pas créer de comptes supplémentaires). Vous êtes
            responsable de la confidentialité de votre nom d'utilisateur et de votre mot de passe
            pour le Service Voyo, ainsi que de toute activité effectuée depuis votre compte. Vous
            vous engagez à nous informer rapidement de toute utilisation non autorisée de votre
            compte.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            6. Confidentialité.
          </h2>
          <p className='text-gray-700 mb-2'>
            La collecte et l'utilisation de vos informations personnelles sur le Service Voyo sont
            détaillées dans notre Politique de confidentialité. En accédant ou en utilisant le
            Service Voyo, vous reconnaissez avoir lu et compris cette Politique de confidentialité.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            7. Votre Contenu.
          </h2>

          <h3 className='text-gray-700 underline mb-2 text-base'>7.1 Votre Contenu.</h3>
          <p className='text-gray-700 mb-2'>
            Il est possible que nous vous demandions ou que nous vous permettons (ou à quelqu'un
            agissant en votre nom) de transmettre ou de télécharger des textes, des photos, des
            images, des vidéos, des avis, des informations et des documents sur votre profil du
            Service Voyo ou dans le cadre de l'utilisation du Service Voyo et/ou de la participation
            à des campagnes promotionnelles que nous menons sur le Site (collectivement, "Votre
            Contenu"). Par exemple, les Visiteurs peuvent être invités à créer un profil comprenant
            une photo et d'autres informations, ainsi qu'à soumettre des photos des biens qu'ils
            visitent.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>7.2 Droits d'exploitation.</h3>
          <p className='text-gray-700 mb-2'>
            Sous réserve des limitations concernant votre utilisation et votre divulgation de vos
            informations personnelles telles que décrites dans notre Politique de confidentialité,
            vous accordez à Voyo un droit irrévocable, perpétuel, non exclusif, exempt de redevance
            et mondial d'utiliser, de copier, de représenter, d'afficher publiquement, de
            reproduire, d'adapter, de modifier, de transmettre, de diffuser, de créer des œuvres
            dérivées et/ou de distribuer Votre Contenu dans le cadre de la fourniture et/ou de la
            promotion du Service Voyo, y compris la possibilité de sous-licencier ces droits à des
            tiers.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>7.3 Renonciation.</h3>
          <p className='text-gray-700 mb-2'>
            Si votre nom, votre voix, votre image, votre personnalité ou votre apparence sont inclus
            dans Votre Contenu, vous renoncez par la présente à toute réclamation ou action, connue
            ou inconnue, pour diffamation, violation des droits d'auteur, atteinte à la vie privée,
            à la publicité ou à la personnalité, ou toute autre réclamation similaire découlant de
            l'utilisation de Votre Contenu conformément aux droits accordés en vertu de l'article
            7.2 et aux autres dispositions des présentes Conditions. De plus, vous déchargez Voyo et
            ses utilisateurs de toute responsabilité à cet égard.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            7.4 Déclarations et garanties concernant votre contenu.
          </h3>
          <ul className='list-disc list-inside text-gray-700 mb-2'>
            <li>
              Vous êtes le propriétaire ou le concédant des droits de Votre Contenu, et que vous
              disposez de tous les droits, consentements et autorisations nécessaires pour accorder
              la licence mentionnée à l'article 7.2 et la renonciation mentionnée à l'article 7.3
              concernant Votre Contenu.
            </li>
            <li>
              Vous avez obtenu toutes les autorisations nécessaires des personnes apparaissant dans
              Votre Contenu.
            </li>
            <li>Votre Contenu ne viole pas la loi ni les présentes Conditions.</li>
          </ul>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            7.5 Droit de supprimer ou de filtrer.
          </h3>
          <p className='text-gray-700 mb-2'>
            Bien que nous ne soyons pas tenus de le faire, nous nous réservons le droit de
            surveiller, filtrer, modifier et/ou supprimer Votre Contenu sur le Service Voyo. Notre
            application des présentes Conditions en ce qui concerne Votre Contenu relève de notre
            discrétion, et le fait de ne pas appliquer les présentes Conditions dans un cas donné ne
            constitue pas une renonciation à notre droit de les appliquer dans d'autres cas. Nous
            n'avons aucune obligation de conserver ou de vous fournir des copies de Votre Contenu,
            et nous n'assumons aucune responsabilité à votre égard en cas de suppression,
            divulgation, perte ou modification de votre Contenu. Il est de votre seule
            responsabilité de conserver des copies de sauvegarde de votre Contenu.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>7.6 Avis.</h3>
          <p className='text-gray-700 mb-2'>
            Le Service Voyo peut offrir la possibilité de laisser des avis publics et/ou privés sur
            d'autres utilisateurs ou leurs biens. Vous reconnaissez que même les avis privés peuvent
            être partagés avec des tiers conformément à la loi applicable et à notre Politique de
            confidentialité, et que Voyo n'a aucune obligation de conserver ou de stocker les avis
            indéfiniment. Nous n'avons aucune obligation de vous communiquer le contenu des avis
            vous concernant soumis par d'autres utilisateurs du Service Voyo, que ce soit avant ou
            après la désactivation de votre compte sur le Service Voyo. Nous n'assumons aucune
            responsabilité à votre égard en cas de suppression, divulgation, perte ou modification
            de ces avis. Nous nous réservons le droit de filtrer, de modifier ou de supprimer les
            avis publiés sur le Service Voyo à tout moment.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            8. Changement de Numéro de Téléphone.
          </h2>
          <p className='text-gray-700 mb-2'>
            Dans le cas où vous désactivez un numéro de téléphone mobile qui nous a été fourni, vous
            acceptez de mettre à jour rapidement les informations de votre compte Rover afin de vous
            assurer que les messages ne sont pas envoyés à la personne qui acquiert votre ancien
            numéro.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            9. Frais et Paiement.
          </h2>

          <h3 className='text-gray-700 underline mb-2 text-base'>9.1 Tarifs.</h3>
          <p className='text-gray-700 mb-2'>
            Tous les tarifs, commissions et autres paiements affichés sur le Service Voyo ou
            facturés par son intermédiaire sont indiqués et payables dans la devise locale.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>9.2 Frais pour les Mandants.</h3>
          <p className='text-gray-700 mb-2'>
            Les Mandants peuvent acheter des visites auprès d'un Visiteur en effectuant une
            Réservation conformément à l'article 2.5. Si vous êtes Mandant, vous concluez une
            transaction avec un Visiteur lorsque vous acceptez une Réservation, et vous acceptez
            ainsi de payer le montant total indiqué dans la Réservation, incluant les frais de
            service payables à Voyo. Le Visiteur, et non Voyo, est responsable de l'exécution des
            visites. Les frais de service pour les Mandants sont décrits ici. Lorsque la loi
            applicable l'exige, le montant facturé comprend également les taxes applicables.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>9.3 Frais pour les Visiteurs.</h3>
          <p className='text-gray-700 mb-2'>
            Les Visiteurs peuvent accepter d'effectuer des visites pour un Mandant en acceptant une
            Réservation conformément à l'article 2.5. Si vous êtes Visiteur, vous devez confirmer la
            Réservation avant son expiration, sinon le Mandant n'aura pas l'obligation de conclure
            la transaction. Une fois la Réservation confirmée par les deux parties, vous acceptez
            d'appliquer le prix indiqué dans votre Réservation. L'achat de visites est une
            transaction entre le Mandant et le Visiteur. Le rôle de Voyo est de faciliter la
            transaction. Nous collecterons (directement ou indirectement par le biais d'un tiers
            autorisé) le paiement auprès du Mandant au moment de la Réservation et (sauf si un
            paiement est retenu conformément à l'article 9.7) nous verserons le paiement sur le
            compte du Visiteur 48 heures après la fin de la période de visite indiquée dans la
            Réservation. Les frais de service pour les Visiteurs sont décrits ici. Sauf indication
            contraire via le Service Voyo, ces frais sont calculés en pourcentage des frais que le
            Mandant accepte de vous payer dans le cadre d'une Réservation, et ils sont prélevés sur
            chaque Réservation. Lorsque la loi applicable l'exige, le montant facturé comprend
            également les taxes applicables.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>9.4 Frais de Service.</h3>
          <p className='text-gray-700 mb-2'>
            Nous facturons des frais de service pour certains aspects du Service Voyo. Si vous êtes
            un Visiteur, et sauf indication contraire via le Service Voyo, nos frais de service sont
            calculés en pourcentage des frais que le Mandant accepte de vous payer dans le cadre
            d'une Réservation, et ils sont prélevés sur chaque Réservation.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            9.5 Annulations et Remboursements.
          </h3>
          <ul className='list-disc list-inside text-gray-700 mb-2'>
            <li>
              1. Protection des réservations. <br />
              En cas d'annulation d'une réservation par un visiteur avant ou pendant la période de
              visite convenue, nous procéderons au remboursement des frais versés par le mandant
              pour les visites non effectuées, ainsi que des frais de service qui nous ont été
              acquittés. Veuillez noter que des annulations répétées sans motif valable, et sans
              proposition de remplaçant, pourraient entraîner la désactivation de votre compte.
            </li>
            <li>
              2. Annulation par le visiteur. <br />
              En cas d'annulation d'une réservation par un visiteur avant ou pendant la période de
              visite convenue, nous procéderons au remboursement des frais versés par le mandant
              pour les visites non effectuées, ainsi que des frais de service qui nous ont été
              acquittés. Veuillez noter que des annulations répétées sans motif valable, et sans
              proposition de remplaçant, pourraient entraîner la désactivation de votre compte.
            </li>
            <li>
              3. Annulation par le mandant. <br />
              En cas d'annulation d'une réservation par un mandant avant ou pendant la période de
              visite convenue, nous procéderons au remboursement des frais conformément à la
              politique d'annulation choisie par le visiteur lors de la réservation. Il est
              primordial que tous les visiteurs sélectionnent une politique d'annulation avant de
              finaliser une réservation, afin que les mandants soient informés de cette politique
              avant de réserver. Pour de plus amples détails sur les conditions d'annulation,
              veuillez consulter notre centre d'aide.
            </li>
            <li>
              4. Remboursements en cas de non-respect des engagements.
              <br />
              Si nous constatons, à notre discrétion, que le visiteur n'a pas respecté ses
              engagements envers le mandant ou qu'il a enfreint les présentes conditions, nous nous
              réservons le droit d'annuler la réservation concernée et d'effectuer un remboursement
              total ou partiel au profit du mandant.
            </li>
            <li>
              5. Conditions générales relatives aux annulations. <br />
              Pour toute annulation d'une réservation, nous vous invitons à utiliser les
              fonctionnalités dédiées sur notre plateforme. Aux fins des présentes conditions et de
              cette section 9.6, la date d'annulation est considérée comme la date à laquelle un
              utilisateur procède à l'annulation sur Voyo, indépendamment de toute communication
              distincte en dehors de notre plateforme.
            </li>
            <li>
              6. Règlement des litiges concernant les paiements ; paiement en dehors de Voyo. <br />
              Les paiements aux visiteurs sont effectués dans les 48 heures suivant la fin d'une
              réservation. Tout différend relatif aux paiements doit être résolu directement entre
              le mandant et le visiteur. Voyo n'a pas l'obligation d'intervenir en tant que
              médiateur ou de faciliter la résolution de ce différend. De plus, Voyo ne peut être
              tenu responsable des pourboires, des primes ou de tout autre paiement effectué en
              dehors de notre plateforme
            </li>
          </ul>

          <h3 className='text-gray-700 underline mb-2 text-base'>9.7 Blocage des Paiements.</h3>
          <p className='text-gray-700 mb-2'>
            En tant que Prestataire, vous devez comprendre que Voyo se réserve le droit de bloquer
            les montants qui vous sont dus conformément à l'article 9.3 si nous avons des raisons
            raisonnables de suspecter une activité frauduleuse associée à votre compte ou pour
            d'autres motifs similaires nécessitant une intervention pour protéger les intérêts de
            Rover, de la communauté Rover ou les droits de tiers. Nous pouvons également recommander
            aux fournisseurs de services de paiement tiers de limiter l'accès à vos fonds dans de
            telles circonstances.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            9.8 Consentement de prélèvement.
          </h3>
          <p className='text-gray-700 mb-2'>
            Lorsque vous effectuez des paiements pour des services, y compris les réservations de
            visites, sur notre site, vous serez invité à fournir des informations de paiement
            valides et à jour. Vous devrez conserver ces informations de paiement sur votre compte
            aussi longtemps que vous avez des réservations confirmées ou en cours. En tant qu'agent
            de paiement au nom du prestataire de services, notre plateforme facilite les paiements
            des utilisateurs aux prestataires. Vous nous donnez votre consentement pour prélever les
            frais dûs via les informations de paiement que vous avez fournies, dès qu'elles sont
            exigibles. Dans le cas où votre méthode de paiement principale expire, devient invalide
            ou ne peut être débitée, vous nous autorisez à utiliser toute autre méthode de paiement
            alternative enregistrée sur notre plateforme. Vous êtes responsable de maintenir à jour
            vos informations de paiement. Si nous ne pouvons pas prélever les frais à l'échéance en
            raison de l'invalidité de vos informations de paiement, ou si nous ne recevons pas votre
            paiement à temps, vous comprenez que ni notre plateforme ni le prestataire ne pourront
            être tenus responsables de tout manquement à fournir les services associés à ces frais.
            À moins d'indication contraire explicite dans nos conditions, tous les frais payés via
            notre site ne sont pas remboursables une fois qu'ils ont été réglés.
          </p>
          <h3 className='text-gray-700 underline mb-2 text-base'>9.9 Taxes.</h3>
          <p className='text-gray-700 mb-2'>
            À l'exception des impôts sur le revenu et les recettes de Voyo ou lorsque Voyo est
            autrement tenu de collecter des taxes, vous reconnaissez que vous êtes seul responsable
            du paiement de toutes les taxes applicables résultant de votre achat, fourniture ou
            utilisation des Services via Voyo. Ceci inclut, sans limitation, toute forme de taxe de
            vente, de TVA ou d'impôt sur le revenu sur les frais payés ou reçus par vous via Voyo.
          </p>
          <h3 className='text-gray-700 underline mb-2 text-base'>9.10 Services de Paiements.</h3>
          <p className='text-gray-700 mb-2'>
            Les paiements sur Voyo sont traités par notre partenaire Stripe, et sont soumis à leur
            contrat de services (disponible à l'adresse https://stripe.com/legal) ainsi qu'au
            contrat de connexion de comptes Stripe si vous recevez des paiements via Voyo
            (disponible à l'adresse https://stripe.com/connect-account/legal) (collectivement, les
            "Conditions Stripe"). Pour utiliser Voyo et recevoir des paiements, vous devrez créer un
            compte Stripe et accepter les Conditions Stripe. En acceptant, vous autorisez Voyo à
            accéder à votre compte Stripe et à effectuer les actions nécessaires pour le traitement
            des paiements liés aux services proposés sur Voyo, y compris les remboursements si
            nécessaire. Assurez-vous de fournir des informations exactes et complètes sur vous et
            votre entreprise, et comprenez que ces informations pourront être partagées avec Stripe,
            ainsi que les détails des transactions, dans le but de faciliter les services de
            paiement. Voyo se réserve également le droit de changer de prestataire de services de
            paiement ou d'utiliser des prestataires alternatifs selon sa discrétion.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            10. Responsabilité et indemnisation
          </h2>

          <h3 className='text-gray-700 underline mb-2 text-base'>10.1 Responsabilité</h3>
          <p className='text-gray-700 mb-2'>
            Dans la mesure permise par la loi applicable, vous reconnaissez et acceptez que Voyo ne
            sera pas responsable des pertes, dommages, réclamations, responsabilités, frais ou
            dépenses découlant de : <br />
            (a) Votre utilisation du site Voyo et des services associés ;
            <br />
            (b) Votre violation des présentes conditions d'utilisation ; <br />
            (c) Votre interaction ou transaction avec d'autres utilisateurs de Voyo, en ligne ou
            hors ligne ; <br />
            (d) Tout différend ou litige avec d'autres utilisateurs de Voyo ; <br />
            (e) Votre contenu publié sur Voyo ; <br />
            (f) Les actes ou omissions de votre animal de compagnie ou des animaux dont vous avez la
            responsabilité ; <br />
            (g) Votre utilisation non autorisée ou illégale des informations obtenues par le biais
            des vérifications des antécédents ou d'identification ; <br />
            (h) Toute déclaration inexacte, trompeuse ou en violation du droit applicable faite par
            vous ; <br />
            (i) Tout préjudice corporel ou dommage matériel causé à des tiers par votre animal de
            compagnie ou les animaux dont vous avez la responsabilité.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>10.2 Indemnisation</h3>
          <p className='text-gray-700 mb-2'>
            Vous acceptez d'indemniser, de défendre et de dégager de toute responsabilité Voyo,
            ainsi que ses dirigeants, administrateurs, employés et représentants, de toute
            réclamation, demande, action en justice, perte, dépense, dommage ou responsabilité, y
            compris les honoraires d'avocat raisonnables et les frais judiciaires, découlant
            directement ou indirectement de : <br />
            (a) Votre utilisation de Voyo et des services associés ; <br />
            (b) Votre violation des présentes conditions d'utilisation ; <br />
            (c) Votre interaction ou transaction avec d'autres utilisateurs de Voyo, en ligne ou
            hors ligne ; <br />
            (d) Tout différend ou litige avec d'autres utilisateurs de Voyo ; <br />
            (e) Votre contenu publié sur Voyo ; <br />
            (f) Les actes ou omissions de votre animal de compagnie ou des animaux dont vous avez la
            responsabilité ; <br />
            (g) Votre utilisation non autorisée ou illégale des informations obtenues par le biais
            des vérifications des antécédents ou d'identification ; <br />
            (h) Toute déclaration inexacte, trompeuse ou en violation du droit applicable faite par
            vous ; <br />
            (i) Tout préjudice corporel ou dommage matériel causé à des tiers par votre animal de
            compagnie ou les animaux dont vous avez la responsabilité.
            <br />
            <br />
            Vous vous engagez à coopérer pleinement avec Voyo dans la défense de toute réclamation
            visée par cette section. Voyo se réserve le droit exclusif de prendre en charge la
            défense et le contrôle de toute réclamation faisant l'objet d'une indemnisation en vertu
            de cette section. Vous ne pouvez régler aucune réclamation de ce type sans l'accord
            écrit préalable de Voyo.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            11. Limitation de la Responsabilité
          </h2>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            11.1 Exclusion de Certains Types de Dommages
          </h3>
          <p className='text-gray-700 mb-2'>
            Dans toute la mesure permise par la loi applicable, Voyo décline expressément toute
            responsabilité à votre égard pour tout dommage indirect, spécial, accessoire ou
            consécutif. Cela inclut, mais sans s'y limiter, les frais de déplacement, les pertes
            commerciales, les pertes de bénéfices, les pertes de revenus, les pertes de contrats,
            les pertes de données, les pertes de fonds de commerce ou toute autre perte ou dépense
            similaire découlant de, ou en relation avec, l'utilisation ou l'incapacité d'utilisation
            du Service Voyo. Cette exclusion de responsabilité s'étend également aux dommages liés à
            toute information obtenue par le biais du Service Voyo, à la suppression d'informations
            de votre profil ou de vos avis du Service Voyo, à la suspension ou à la résiliation de
            votre accès au Service Voyo, ainsi qu'à toute défaillance, erreur, omission,
            interruption, défaut ou retard de fonctionnement ou de transmission du Service Voyo,
            même si Voyo a été informée de la possibilité de tels dommages.
          </p>
          <h3 className='text-gray-700 underline mb-2 text-base'>
            11.2 Limite de notre Responsabilité envers vous
          </h3>
          <p className='text-gray-700 mb-2'>
            Sauf interdiction en vertu de la loi applicable, la responsabilité globale de Voyo
            envers vous ou toute autre partie dans le cadre d'un litige découlant du Service Voyo ou
            des présentes Conditions, quelle qu'en soit la cause, est expressément limitée aux
            montants effectivement payés par vous à Voyo (à l'exclusion des montants payés aux
            prestataires) au cours des douze (12) mois précédant l'événement à l'origine de la
            responsabilité. Il est important de noter que toute responsabilité financière de Voyo
            envers les utilisateurs liée au comportement des utilisateurs est strictement limitée
            aux obligations de remboursement énoncées dans la Garantie Voyo.
          </p>
          <h3 className='text-gray-700 underline mb-2 text-base'>
            11.3 Exclusion de Responsabilité pour les Actions non liées à Voyo
          </h3>
          <p className='text-gray-700 mb-2'>
            Dans toute la mesure autorisée par la loi applicable, Voyo décline toute responsabilité
            pour tout dommage direct, indirect, général, spécial, compensatoire ou consécutif
            découlant de, ou en relation avec, votre conduite ou celle de toute autre personne liée
            au Service Voyo. Cette exclusion de responsabilité englobe, mais sans s'y limiter, les
            dommages matériels, le vol, les blessures corporelles, le décès, la détresse
            émotionnelle et tout autre dommage résultant de la confiance accordée aux informations
            ou au contenu affiché ou transmis par le Service Voyo, ainsi que toute interaction avec
            d'autres utilisateurs du Service Voyo, qu'elle ait lieu en ligne ou hors ligne. Elle
            s'applique également à toute réclamation, perte ou dommage découlant de la conduite
            d'utilisateurs cherchant à vous escroquer ou à vous nuire.
            <br />
            <br />
            Si vous êtes impliqué dans un litige avec un prestataire ou un utilisateur du Service
            Voyo, vous acceptez de dégager Voyo de toute réclamation, demande et dommage de quelque
            nature que ce soit, connus ou inconnus, découlant de, ou en relation avec, de tels
            litiges, à l'exception de ce qui est expressément prévu dans la Garantie Voyo. En aucun
            cas Voyo ne sera responsable des conséquences directes ou indirectes du non-respect par
            un prestataire ou un utilisateur des lois et réglementations applicables.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            12. Garantie pour le service Voyo.
          </h2>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            12.1 Disponibilité et Fiabilité des Informations
          </h3>
          <p className='text-gray-700 mb-2'>
            Les informations et contenus disponibles sur le Service Voyo, y compris les textes,
            graphiques, liens, conseils ou autres éléments, sont fournis dans leur état actuel et
            selon leur disponibilité. Les avis, profils, conseils, opinions, déclarations, offres ou
            autres informations ou contenus fournis par des tiers via le Service Voyo sont la
            responsabilité exclusive de leurs auteurs respectifs, et Voyo décline toute
            responsabilité à cet égard. Dans les limites autorisées par la loi applicable, Voyo ne
            garantit pas l'exactitude, l'adéquation ou l'exhaustivité des informations et des
            contenus présents sur le Service Voyo.
          </p>
          <h3 className='text-gray-700 underline mb-2 text-base'>
            12.2 Opinions et Conseils des Tiers
          </h3>
          <p className='text-gray-700 mb-2'>
            Voyo ne souscrit, n'approuve ni ne garantit l'exactitude ou la fiabilité des opinions,
            conseils ou déclarations émis par des parties autres que Voyo via le Service Voyo. Ces
            opinions, conseils ou déclarations restent la responsabilité exclusive de leurs auteurs
            respectifs, et Voyo décline toute responsabilité à cet égard.
          </p>
          <h3 className='text-gray-700 underline mb-2 text-base'>
            12.3 Disponibilité et Performance du Service Voyo
          </h3>
          <p className='text-gray-700 mb-2'>
            Dans les limites autorisées par la loi applicable, Voyo ne garantit pas que votre
            utilisation du Service Voyo sera sécurisée, exempte de virus informatiques,
            ininterrompue, toujours disponible, sans erreur ou répondra à vos besoins. Voyo ne
            garantit pas non plus que tout défaut dans le Service Voyo sera corrigé.
          </p>
          <h3 className='text-gray-700 underline mb-2 text-base'>12.4 Exclusion de Garanties</h3>
          <p className='text-gray-700 mb-2'>
            Dans les limites autorisées par la loi applicable, Voyo exclut expressément toutes les
            garanties, qu'elles soient expresses, implicites ou légales, concernant le Service Voyo.
            Voyo rejette spécifiquement toutes les garanties implicites de qualité marchande,
            d'adéquation à un usage particulier, de non-violation et d'exactitude. En outre, Voyo ne
            fait aucune déclaration ni ne donne aucune garantie, de quelque nature que ce soit,
            expressément ou implicitement, concernant l'adéquation de tout prestataire offrant des
            services via le Service Voyo.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            13. Cas de force majeur
          </h2>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            13.1 Exonération de Responsabilité
          </h3>
          <p className='text-gray-700 mb-2'>
            Voyo ne pourra être tenue responsable de tout retard ou défaut d'exécution découlant de
            circonstances indépendantes de sa volonté, telles que des cas de force majeure. Ces
            circonstances incluent, sans s'y limiter, les catastrophes naturelles, les tremblements
            de terre, les ouragans, les feux de forêt, les inondations, les guerres, les actes de
            terrorisme, les émeutes, les embargos, les incendies, les accidents, les pandémies, les
            maladies, les grèves ou tout autre événement de nature similaire.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            13.2 Conséquences sur les Politiques d'Annulation
          </h3>
          <p className='text-gray-700 mb-2'>
            En cas de survenance de telles circonstances, les politiques d'annulation mentionnées
            dans la Section 9.6 peuvent ne pas s'appliquer. Voyo se réserve le droit, à sa
            discrétion raisonnable, d'émettre des remboursements selon des conditions qui peuvent
            différer de la politique d'annulation choisie par le Prestataire.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            14. IMPORTANT : Obligations des visiteurs
          </h2>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            14.1 Responsabilité fiscale des visiteurs
          </h3>
          <p className='text-gray-700 mb-2'>
            Les Visiteurs utilisant notre Service sont responsables de se conformer à toutes les
            lois fiscales et aux exigences de déclaration de revenus en vigueur dans leur
            juridiction. En tant que Visiteur, vous reconnaissez et acceptez que les paiements que
            vous recevez pour les services de visite peuvent être soumis à des obligations fiscales.
            Vous êtes tenu de vous informer sur les lois fiscales locales, nationales et régionales
            qui s'appliquent à vos revenus générés grâce à notre Service, ainsi que sur les
            éventuelles déclarations fiscales requises. Nous ne fournissons pas de conseils fiscaux
            et ne sommes pas responsables de la gestion de vos obligations fiscales. Il est de votre
            responsabilité de tenir des registres précis de vos revenus et de les déclarer aux
            autorités fiscales compétentes, le cas échéant. Veuillez vous renseigner sur vos
            obligations <a href='https://www.impots.gouv.fr/accueil'>fiscales</a>, sur{' '}
            <a href='https://www.urssaf.fr/accueil.html'>la sécurité sociale</a> et nos engagements
            DAC7.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            14.2 Responsabilité des professionnels
          </h3>
          <p className='text-gray-700 mb-2'>
            Tout professionnel utilisant notre Service est tenu de divulguer clairement son statut
            de professionnel lors de l'inscription et surtout lors des interactions avec les
            utilisateurs. Le non-respect de cette obligation peut entraîner des sanctions légales, y
            compris des amendes et des poursuites pénales, conformément aux lois et réglementations
            en vigueur. Nous encourageons vivement les professionnels à être transparents quant à
            leur statut, ce qui contribue à instaurer la confiance au sein de notre communauté
            d'utilisateurs et à garantir la conformité aux réglementations locales et nationales
            applicables.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            15. Collecte des Informations Fiscales
          </h2>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            15.1 Obligation de Fourniture des Informations Fiscales:
          </h3>
          <p className='text-gray-700 mb-2'>
            Tout utilisateur de la plateforme Voyo qui génère des revenus via les services proposés
            s'engage à fournir les informations fiscales nécessaires, incluant, sans s'y limiter,
            son numéro fiscal ou toute autre donnée requise par les autorités compétentes en vertu
            des obligations légales en vigueur.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            15.2. Exactitude des Informations:
          </h3>
          <p className='text-gray-700 mb-2'>
            L'utilisateur garantit que les informations fiscales fournies sont exactes, complètes et
            à jour. L'utilisateur s'engage à informer immédiatement Voyo de toute modification de
            ces informations.
          </p>

          <h3 className='text-gray-700 underline mb-2 text-base'>
            15.3. Conséquences de la Non-Fourniture:
          </h3>
          <p className='text-gray-700 mb-2'>
            En cas de non-fourniture des informations fiscales demandées, Voyo se réserve le droit
            de suspendre temporairement l'accès aux services de la plateforme, de bloquer les
            paiements ou de prendre toute autre mesure jugée nécessaire jusqu'à ce que l'utilisateur
            fournisse les informations requises.
          </p>
          <h3 className='text-gray-700 underline mb-2 text-base'>
            15.4.Responsabilité et Sanctions:
          </h3>
          <p className='text-gray-700 mb-2'>
            L'utilisateur reconnaît que le non-respect de ces obligations peut entraîner des
            sanctions fiscales, y compris, mais sans s'y limiter, des amendes, qui pourraient être
            imputées à Voyo. En conséquence, l'utilisateur accepte de dégager Voyo de toute
            responsabilité en cas de sanction liée à la non-fourniture ou à l'inexactitude de ses
            informations fiscales.
          </p>
          <h3 className='text-gray-700 underline mb-2 text-base'>
            15.5 Transmission des Informations aux Autorités :
          </h3>
          <p className='text-gray-700 mb-2'>
            Conformément aux lois applicables,Voyo peut être tenue de transmettre les informations
            fiscales de l'utilisateur aux autorités fiscales compétentes. L'utilisateur accepte
            expressément cette transmission pour se conformer aux obligations légales.
          </p>
        </section>
      </div>
    </div>
  )
}

export default GeneralConditionsPage
