import Axios from 'axios'

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN

export const getAllVisites = async (token: string) => {
  try {
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/visites/user-visites`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const createVisite = async (token: string, visiteData: object) => {
  try {
    const response = await Axios.post(`${SERVER_APP_DOMAIN}api/visites`, visiteData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const getVisite = async (token: string, visiteId: number) => {
  try {
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/visites/${visiteId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const updateVisite = async (token: string, visiteId: number, visiteData: object) => {
  try {
    const response = await Axios.put(`${SERVER_APP_DOMAIN}api/visites/${visiteId}`, visiteData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const deleteVisite = async (token: string, visiteId: number) => {
  try {
    const response = await Axios.delete(`${SERVER_APP_DOMAIN}api/visites/${visiteId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}




