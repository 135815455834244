import React, { useState } from 'react'
import './index.css'
import './index.mobile.css' // Assurez-vous que le chemin du fichier CSS est correct.
import Navigation from './Navigation'
import chicken from '../../assets/images/chick.png'
import Menu from '../Menu/Menu'
import Footer from '../Footer/Footer'
import Part4 from './part4/Part4'
import Part3 from './part3/Part3'
import Part2 from './part2/Part2'
import Part1 from './part1/Part1'
import { createVisite } from '../../api/Visites'
import { ProfilType } from './Profil.type'
import { io } from 'socket.io-client'
import swal from 'sweetalert'
import PageFinal from './PageFinal'
import { createNotifications } from '../../api/Notifications'

interface ProgrammationVisiteProps {
  authState: {
    email: string
    connectedAccountID: string
    firstname: string
    lastname: string
    phoneNumber: string
    createdAt: Date
    updatedAt: Date
    userImg: string
    birthdate: string
    city: string
    isVisiteur: boolean
    isVerifiedStripeAccount: boolean
  }
  authProps: {
    token: string
    isLoggedIn: boolean
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
  }
}

const ProgrammationVisite: React.FC<ProgrammationVisiteProps> = ({
  authState,
  authProps,
}: ProgrammationVisiteProps) => {
  const [showLastPart, setShowLastPart] = useState(false)
  const [missingValue, setMissingValue] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setFormData] = useState<{
    date: Date
    heureVisite: string
    proprietaire: string
    adresseBien: string
    ville: string
    codePostal: string
    typeBien: string
    prix: { email: string; prix: number }[]
    informationsComplementaires: string
    selectedProfiles: ProfilType[]
  }>({
    date: new Date(),
    heureVisite: '',
    proprietaire: '',
    adresseBien: '',
    ville: '',
    codePostal: '',
    typeBien: '',
    prix: [],
    informationsComplementaires: '',
    selectedProfiles: [],
  })

  const serverAppDomain = process.env.REACT_APP_WEBSOCKET_URL
  const socket = io(serverAppDomain as string, { transports: ['websocket'] })
  const { token } = authProps

  const [formDataValidPart1, setFormDataValidPart1] = useState({
    date: false,
    heureVisite: false,
  })
  const [formDataValidPart2, setFormDataValidPart2] = useState({
    proprietaire: false,
    adresseBien: false,
    ville: false,
    codePostal: false,
    typeBien: false,
  })
  const [formDataValidPart3, setFormDataValidPart3] = useState({
    selectedProfile: false,
  })

  const componentsToRender = [
    <Part1
      key='1'
      setFormData={setFormData}
      formData={formData}
      setFormDataValid={setFormDataValidPart1}
      formDataValid={formDataValidPart1}
    />,
    <Part2
      key='2'
      setFormData={setFormData}
      formData={formData}
      setFormDataValid={setFormDataValidPart2}
      formDataValid={formDataValidPart2}
    />,
    <Part3
      key='3'
      setFormData={setFormData}
      formData={formData}
      setFormDataValid={setFormDataValidPart3}
      formDataValid={formDataValidPart3}
      token={authProps.token}
      userEmail={authState.email}
    />,
    <Part4 key='3' setFormData={setFormData} formData={formData} />,
  ]

  const handleFormSubmit = async () => {
    try {
      if (formData) {
        const visiteursEmail = formData.selectedProfiles.map((profil) => profil.email)
        const visite = {
          date: formData.date,
          heureVisite: formData.heureVisite,
          proprietaire: formData.proprietaire,
          adresseBien: formData.adresseBien,
          ville: formData.ville,
          codePostal: formData.codePostal,
          typeBien: formData.typeBien,
          visiteursEmail,
          prix: formData.prix,
          informationsComplementaires: formData.informationsComplementaires,
        }

        const response = await createVisite(token, visite)

        if (response.success) {
          for (const visite of response.visites) {
            const data = {
              type: 'Visite',
              content: null,
              userImg: authState.userImg,
              firstName: authState.firstname,
              lastName: authState.lastname,
              chatId: visite.chatId,
            }

            await createNotifications(token, authState.firstname, visiteursEmail, data)
          }
          window.scrollTo({ top: 0, behavior: 'smooth' })
          window.localStorage.removeItem('basketToFinish')
          setIsLoading(true)
          setTimeout(() => {
            setShowLastPart(true)
            setIsLoading(false)
          }, 500)
        } else {
          swal('Erreur ! Une erreur est survenue dans votre demande de visite !', {
            icon: 'error',
          })
        }
      }
    } catch (error) {
      console.error('Erreur lors de la création du profil visiteur', error)
    }
  }

  return (
    <div className='mt-10'>
      <Menu authState={authState} authProps={authProps} />
      {showLastPart ? (
        <>
          <PageFinal totalProfiles={formData.selectedProfiles.length} />
        </>
      ) : (
        <>
          <div className='programmation-visite'>
            <div className='programmation-visite-titre-container'>
              <h1 className='programmation-visite-titre'>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                Programmer la visite d&apos;un bien par l'un de nos visiteurs
              </h1>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='187'
                height='84'
                viewBox='0 0 187 84'
                fill='none'
              >
                <path
                  d='M154.556 6.23943C120.112 -0.524946 43.0276 -4.1608 10.2418 35.4108C-30.7403 84.8753 92.0328 86.1848 138.769 78.5337C166.733 73.9558 185 62.755 185 46.5498C185 32.5982 153.281 11.6298 95.8738 15.5933'
                  stroke='url(#paint0_linear_105_2012)'
                  strokeWidth='4'
                  strokeLinecap='round'
                />
                <defs>
                  <linearGradient
                    id='paint0_linear_105_2012'
                    x1='2'
                    y1='2'
                    x2='131.982'
                    y2='134.149'
                    gradientUnits='userSpaceOnUse'
                  >
                    <stop stopColor='#F40000' />
                    <stop offset='1' stopColor='#F75000' />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            {isLoading ? (
              <div className='flex justify-center items-center'>
                <div className='border-t-2 border-b border-b-red-100 border-solid rounded-full animate-spin w-14 h-14 border-t-orange-400'></div>
              </div>
            ) : (
              <Navigation
                formData={formData}
                setFormData={setFormData}
                authProps={authProps}
                components={componentsToRender}
                validation={[formDataValidPart1, formDataValidPart2, formDataValidPart3]}
                handleFormSubmit={handleFormSubmit}
                missingValue={missingValue}
                setMissingValue={setMissingValue}
              />
            )}
          </div>
        </>
      )}
      <Footer />
    </div>
  )
}

export default ProgrammationVisite
