import Axios from 'axios'

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN

export const getAllVisiteurs = async (token: string,userEmail:string) => {
  try {
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/profileVisiteur/visiteurs?userEmail=${userEmail}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const createProfileVisiteur = async (token: string, profileData: object) => {
  try {
    const response = await Axios.post(`${SERVER_APP_DOMAIN}api/profileVisiteur/`, profileData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const getProfileVisiteur = async (token: string) => {

  try {
    if(!token)return
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/profileVisiteur/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const updateProfileVisiteur = async (token: string, profileData: object) => {
  try {
    const response = await Axios.put(`${SERVER_APP_DOMAIN}api/profileVisiteur/`, profileData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const deleteProfileVisiteur = async (token: string) => {
  try {
    const response = await Axios.delete(`${SERVER_APP_DOMAIN}api/profileVisiteur/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}
