import React from 'react'
import { decodeJWT, isTokenExpired } from '../../utils/Token.utils'
import Axios from 'axios'
import { isAuthenticate } from '../../api/Authentification'

const withAuth = <P extends object>(Component: React.ComponentType<P>) => {
  return class AuthenticatedComponent extends React.Component<P> {
    async componentDidMount() {
      const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN
      const serverStarted = await Axios.get(SERVER_APP_DOMAIN + 'api').then(
        (response) => response.status,
      )
      if (serverStarted === 200) {
        const newToken = localStorage.getItem('token')
        const token = String(newToken)
        if (typeof token === 'undefined' || !token || token === null || token === 'null') {
          localStorage.removeItem('token')
          window.location.reload()
          window.location.href = '/inscription'
        } else {
          const decodedToken = decodeJWT(token)
          const response = await isAuthenticate(token.replace(/^"(.*)"$/, '$1'))
          if (isTokenExpired(decodedToken) || !response.user.email) {
            localStorage.removeItem('token')
            window.location.reload()
            window.location.href = '/inscription'
          }
        }
      } else {
        window.location.href = '/server-down'
        localStorage.removeItem('token')
        window.location.reload()
      }
    }

    render() {
      return <Component {...this.props} />
    }
  }
}

export default withAuth
