import Axios from 'axios';

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN;

export const createDisponibilite = async (token: string, data: string) => {
  try {
    const response = await Axios.post(`${SERVER_APP_DOMAIN}api/disponibilites`, {'date' : data}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Erreur lors de la requête :', error);
    throw error;
  }
};

export const getDisponibiliteByUser = async (token:string) => {
  try {
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/disponibilites`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Erreur lors de la requête :', error);
    throw error;
  }
};

export const deleteDisponibilite = async (token: string, id: number) => {
  try {
    const response = await Axios.delete(`${SERVER_APP_DOMAIN}api/disponibilites/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Erreur lors de la requête :', error);
    throw error;
  }
};
