export function decodeJWT(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const decodedToken = JSON.parse(atob(base64));

  return decodedToken;
}

export function isTokenExpired(token: { exp: number }) {
  // Get the current timestamp in seconds
  const currentTimestamp = Math.floor(Date.now() / 1000);

  // Check if the expiration time has passed
  if (token.exp < currentTimestamp) {
    return true; // Token has expired
  }

  return false; // Token is still valid
}