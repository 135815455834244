import { QueryBuilder } from '@mui/icons-material'
import React from 'react'
import { ProfilType } from '../Profil.type'

interface FormDataProps {
  formData: {
    date: Date
    heureVisite: string
    proprietaire: string
    adresseBien: string
    ville: string
    codePostal: string
    prix: { email: string; prix: number }[]
    informationsComplementaires: string
    typeBien: string
    selectedProfiles: ProfilType[]
  }
  setFormData: React.Dispatch<
    React.SetStateAction<{
      date: Date
      heureVisite: string
      proprietaire: string
      adresseBien: string
      ville: string
      prix: { email: string; prix: number }[]
      informationsComplementaires: string
      codePostal: string
      typeBien: string
      selectedProfiles: ProfilType[]
    }>
  >
  formDataValid: any
  setFormDataValid: React.Dispatch<React.SetStateAction<any>>
}

export default function Part1({
  formData,
  setFormData,
  formDataValid,
  setFormDataValid,
}: FormDataProps) {
  const updatedFormDataValid = formDataValid

  const today = new Date().toISOString().split('T')[0]
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dateTexte = e.target.value
    const date = new Date(dateTexte)
    const isDateValid = true
    updatedFormDataValid.date = isDateValid
    setFormDataValid(updatedFormDataValid)
    setFormData({ ...formData, date })
  }

  const handleHeureVisiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const heureVisite = e.target.value
    updatedFormDataValid.heureVisite = heureVisite
    setFormDataValid(updatedFormDataValid)
    setFormData({ ...formData, heureVisite })
  }

  return (
    <div>
      <h2 className='programmation-visite-sous-titre'>Planifier votre visite</h2>
      <div className='part-content1'>
        <div>
          <label className='block text-sm font-medium text-gray-600'>Date de la visite :</label>
          <input
            type='date'
            min={today}
            placeholder='MM/DD/YYYY'
            onChange={handleDateChange}
            className='mt-1 p-2 border rounded-md w-full'
          />
        </div>
        <div>
          <label className='block text-sm font-medium text-gray-600'>Heure de visite :</label>
          <input
            type='time'
            value={formData.heureVisite}
            placeholder='JJ/MM/AAAA'
            onChange={handleHeureVisiteChange}
            className='mt-1 p-2 border rounded-md w-full'
          />
        </div>
      </div>
    </div>
  )
}
