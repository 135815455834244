import { CalendarMonth, LocationOn } from '@mui/icons-material'
import React, { useEffect } from 'react'
import etourneau from '../../../assets/images/image_oiseaux/etourneau.png'
import birdJaune from '../../../assets/images/bird-jaune.png'
import { ProfilType } from '../Profil.type'
import { convertDate } from '../../../utils/Data.utils'
interface FormDataProps {
  formData: {
    date: Date
    heureVisite: string
    proprietaire: string
    adresseBien: string
    ville: string
    codePostal: string
    typeBien: string
    prix: { email: string; prix: number }[]
    informationsComplementaires: string
    selectedProfiles: ProfilType[]
  }
  setFormData: React.Dispatch<
    React.SetStateAction<{
      date: Date
      heureVisite: string
      proprietaire: string
      adresseBien: string
      ville: string
      codePostal: string
      typeBien: string
      prix: { email: string; prix: number }[]
      informationsComplementaires: string
      selectedProfiles: ProfilType[]
    }>
  >
}

export default function Part4({ formData, setFormData }: FormDataProps) {
  const profilUsersSelected = formData.selectedProfiles

  const prixVisites = profilUsersSelected.map((profilUserSelected) => {
    const tarif = profilUserSelected.profileVisiteur.tarif
    const prix = tarif
    return {
      email: profilUserSelected.email,
      prix,
    }
  })

  useEffect(() => {
    setFormData({
      ...formData,
      prix: prixVisites,
    })
  }, [])
  return (
    <div>
      <h2 className='programmation-visite-sous-titre'>Envoyer la demande</h2>
      <div>
        <div className='recap-programmation-visite'>
          <div className='recap-programmation-visite-gauche'>
            {formData.selectedProfiles.length > 1 && <h4>Visiteurs</h4>}
            <div className='overflow-y-auto max-h-30 px-1 w-full'>
              {profilUsersSelected.map((profilUserSelected) => (
                <div className='recap-programmation-profil mb-1' key={profilUserSelected.id}>
                  <img
                    src={profilUserSelected.userImg ? profilUserSelected.userImg : etourneau}
                    alt=''
                  />
                  <div className='recap-programmation-profil-info'>
                    <div className='recap-programmation-profil-name'>
                      <p>{profilUserSelected.profileVisiteur.prenom}</p>
                    </div>
                    {profilUserSelected.profileVisiteur.note && (
                      <p>
                        {profilUserSelected.profileVisiteur.note}{' '}
                        <span>({profilUserSelected.profileVisiteur.nbAvis} avis)</span>
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <hr />
            <div className='recap-programmation-infos'>
              <h4>Résumé de votre demande</h4>
              <div>
                <CalendarMonth /> <p>{convertDate(formData.date)}</p>
              </div>
              <div>
                <LocationOn />
                <p>
                  {formData.ville}, {formData.codePostal}
                </p>
              </div>
            </div>
            <hr />
            <div className='recap-programmation-autre'>
              <h4>Informations complémentaires demandées</h4>
              <textarea
                onChange={(e) => {
                  const informationsComplementaires = e.target.value
                  setFormData({ ...formData, informationsComplementaires })
                }}
                placeholder='Toutes autres informations importantes'
              ></textarea>
            </div>
          </div>
          <div className='recap-programmation-droite'>
            <div className='recap-programmation-date'>
              <h2>Votre visite du {convertDate(formData.date)}</h2>
              <img src={birdJaune} alt='' />
            </div>
            <div className='recap-programmation-prix '>
              <h4>{formData.selectedProfiles.length > 1 ? 'Différents prix' : 'Prix détaillé'}</h4>
              <div className='overflow-auto max-h-[310px] '>
                {profilUsersSelected.map((user) => (
                  <div key={user.id} className='mx-1'>
                    <div>
                      <h5 className='font-semibold flex gap-1 mb-2'>
                        {user.profileVisiteur.prenom}
                      </h5>

                      <div className='flex justify-between gap-3'>
                        <div className='flex-col '>
                          <p>Prix visiteur</p>
                          <p>{user.profileVisiteur.tarif} €</p>
                        </div>
                        <div className='flex-col '>
                          <p>Frais de service</p>
                          <p>
                            {Math.round(Math.round(user.profileVisiteur.tarif * 100) * 0.15) / 100}{' '}
                            €
                          </p>
                        </div>
                      </div>
                      <hr className='my-1' />
                      <div className='flex justify-end w-full'>
                        <div className='recap-programmation-prix-total '>
                          <p>Total</p>
                          <h2>
                            {user.profileVisiteur.tarif +
                              Math.round(Math.round(user.profileVisiteur.tarif * 100) * 0.15) /
                                100}{' '}
                            €
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
