import { useEffect, useState, useLayoutEffect } from 'react'
import { ArrowForwardIos } from '@mui/icons-material'
import Footer from '../Footer/Footer'
import Menu from '../Menu/Menu'
import CreerProfilVisiteur from './CreerProfilVisiteur/CreerProfilVisiteur'
import PreferencesVisiteur from './PreferencesVisiteur/PreferencesVisiteur'
import { FormValuesType } from './formValueType'
import swal from 'sweetalert'
import { createProfileVisiteur } from '../../api/ProfilVisiteur'
import { getAuthLink } from '../../api/Paiement'
import { getInfoUser } from '../../api/User'

interface InscriptionVisiteurProps {
  authState: {
    email: string
    firstname: string
    lastname: string
    phoneNumber: string
    createdAt: Date
    updatedAt: Date
    userImg: string
    birthdate: string
    city: string
    isVisiteur: boolean
    connectedAccountID: string
    isVerifiedStripeAccount: boolean
  }
  authProps: {
    token: string
    isLoggedIn: boolean
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
  }
}

const InscriptionVisiteur = ({ authState, authProps }: InscriptionVisiteurProps) => {
  const [affichageCreerProfil, setAffichageCreerProfil] = useState(false)
  const [affichagePreferences, setAffichagePreferences] = useState(false)
  const [loading, setLoading] = useState(false)
  const [affichageCreerProfilSuccess, setAffichageCreerProfilSuccess] = useState(false)
  const [affichagePreferencesSuccess, setAffichagePreferencesSuccess] = useState(false)
  const { token } = authProps
  const [formValues, setFormValues] = useState<FormValuesType>({
    nom: authState.lastname,
    prenom: authState.firstname,
    civilite: '',
    dateDeNaissance: authState.birthdate,
    villeDeNaissance: authState.city,
    adresse: '',
    codePostal: '',
    telephone: authState.phoneNumber,
    email: authState.email,
    villesVisisteur: [authState.city],
    nbAvis: 0,
    note: 0,
    parlezDeVous: '',
    disponibleImmediatement: false,
    tarif: 0,
    delaiAcceptation: 0,
    preferenceModification: '',
    preferenceAnnulation: '',
  })

    useLayoutEffect(() => {
    if (!localStorage.getItem('token')?.replace(/^"|"$/g, '')) {
      window.location.href = '/inscription'
    }
    }, [token])

  useEffect(() => {
    if (authState.isVisiteur) location.href = '/'
  }, [authState.isVisiteur])

  useEffect(() => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      nom: authState.lastname,
      prenom: authState.firstname,
      dateDeNaissance: authState.birthdate,
      villeDeNaissance: authState.city,
      telephone: authState.phoneNumber,
      email: authState.email,
      villesVisisteur: [authState.city],
    }))
  }, [authState])

  const verifAccountWithStripe = async () => {
    const resUser = await getInfoUser(token)
    if (resUser?.connectedAccountID) {
      const res = (await getAuthLink(token, resUser?.connectedAccountID, authState.email)) as any
      if (res) {
        setLoading(false)
        window.location.href = res
        return
      }
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })

      const response = await createProfileVisiteur(token, formValues)
      if (response.success) {
        swal('Pouf ! Votre profil visiteur à été créé avec succès!', {
          icon: 'success',
        }).then(() => {
          setTimeout(() => {
            verifAccountWithStripe()
          }, 1000)
        })
      } else {
        swal('Error', 'Erreur lors de la création du profil visiteur', 'error')
        setLoading(false)
      }
    } catch (error) {
      swal('Error', 'An error occurred while submitting the form', 'error')
      console.error('Erreur lors de la création du profil visiteur', error)
      setLoading(false)
    }
  }

  const handleClickCreerProfil = () => {
    setAffichageCreerProfil(true)
  }

  const handleClickPreferences = () => {
    setAffichagePreferences(true)
  }

  return (
    <div>
      <Menu authState={authState} authProps={authProps} />

      {!affichageCreerProfil && !affichagePreferences && (
        <div className='max-h-[38.5dvh] min-h-[38.5dvh] z-10'>
          <div className='inscription-visiteur mt-36 flex justify-center'>
            <div className='inscription-visiteur-content p-8 bg-white rounded-md shadow-lg border-t border-gray-100 max-w-2xl mx-4 z-10'>
              {token && (
                <>
                  <h1 className='text-4xl text-gray-800 mb-4'>
                    Complétez les informations demandées pour que votre profil soit validé
                  </h1>
                  <h3 className='text-2xl text-gray-600 mt-4'>Configurez votre service</h3>

                  <section
                    onClick={handleClickCreerProfil}
                    onKeyDown={handleClickCreerProfil}
                    className={`cursor-pointer p-5 my-5 rounded-lg transition duration-300 flex items-center justify-between ${
                      affichageCreerProfilSuccess
                        ? 'bg-green-300 hover:bg-green-400'
                        : 'bg-white hover:bg-gray-100 border'
                    }`}
                  >
                    <div>
                      <h4 className={'text-lg text-gray-600'}>Créez votre profil</h4>
                      <p className={'text-gray-400'}>Établissez la confiance en parlant de vous</p>
                    </div>
                    <ArrowForwardIos />
                  </section>
                  <section
                    onClick={handleClickPreferences}
                    onKeyDown={handleClickPreferences}
                    className={`cursor-pointer p-5 my-5 rounded-lg transition duration-300 flex items-center justify-between ${
                      affichagePreferencesSuccess
                        ? 'bg-green-300 hover:bg-green-400'
                        : 'bg-white hover:bg-gray-100 border'
                    }`}
                  >
                    <div>
                      <h4 className={'text-lg text-gray-600'}>Vos visites</h4>
                      <p className='text-gray-400'>Définissez les préférences de votre service</p>
                    </div>
                    <ArrowForwardIos />
                  </section>
                </>
              )}

              {affichageCreerProfilSuccess && affichagePreferencesSuccess && (
                <>
                  {loading ? (
                    <div className='flex justify-center items-center mt-10'>
                      <button
                        className='bg-blue-500 text-white w-full max-w-2xl py-4 rounded-lg bg-orange'
                        onClick={handleSubmit}
                      >
                        <div className='border-t-2 border-b border-b-red-100 border-solid rounded-full animate-spin w-8 h-8 border-t-orange-400'></div>
                      </button>
                    </div>
                  ) : (
                    <div className='flex justify-center items-center mt-10'>
                      <button
                        className='bg-blue-500 text-white w-full max-w-2xl py-4 rounded-lg bg-orange'
                        onClick={handleSubmit}
                      >
                        Valider votre profil visiteur
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {affichageCreerProfil && (
        <CreerProfilVisiteur
          setAffichage={setAffichageCreerProfil}
          formValues={formValues}
          setAffichageCreerProfilSuccess={setAffichageCreerProfilSuccess}
          setFormValues={setFormValues}
        />
      )}
      {affichagePreferences && (
        <PreferencesVisiteur
          authState={authState}
          authProps={authProps}
          affichage={affichagePreferences}
          setAffichage={setAffichagePreferences}
          formValues={formValues}
          setAffichagePreferencesSuccess={setAffichagePreferencesSuccess}
          setFormValues={setFormValues}
        />
      )}

      <Footer />
    </div>
  )
}

export default InscriptionVisiteur
