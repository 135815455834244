import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Lottie from 'lottie-react'
import Menu from '../Menu/Menu'
import Footer from '../Footer/Footer'
import oiseauRouge from '../../assets/images/bird-rouge.png'
import background from '../../assets/images/bg.png'
import oiseauVert from '../../assets/images/oiseau-vert.png'
import oiseauPlanete from '../../assets/images/oiseau-planete.png'
import searchAnimation from '../../assets/animations/search.json'
import contractAnimation from '../../assets/animations/contract.json'
import houseAnimation from '../../assets/animations/house.json'
import separator from '../../assets/images/sep.png'

import '../../App.css'
import './index.css'
import './index.tablette.css'
import './index.mobile.css'

interface HomePageProps {
  authState: {
    email: string
    connectedAccountID: string
    firstname: string
    lastname: string
    phoneNumber: string
    createdAt: Date
    updatedAt: Date
    userImg: string
    birthdate: string
    city: string
    isVisiteur: boolean
    isVerifiedStripeAccount: boolean
  }
  authProps: {
    token: string
    isLoggedIn: boolean
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
  }
}

const HomePage: React.FC<HomePageProps> = ({ authState, authProps }) => {
  const [searchAnimationVisible, setSearchAnimationVisible] = useState(false)
  const [contractAnimationVisible, setContractAnimationVisible] = useState(false)
  const [houseAnimationVisible, setHouseAnimationVisible] = useState(false)

  const [searchRef, searchInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })

  const [contractRef, contractInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })

  const [houseRef, houseInView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  })

  useEffect(() => {
    if (searchInView) {
      setSearchAnimationVisible(true)
    }
  }, [searchInView])

  useEffect(() => {
    if (contractInView) {
      setContractAnimationVisible(true)
    }
  }, [contractInView])

  useEffect(() => {
    if (houseInView) {
      setHouseAnimationVisible(true)
    }
  }, [houseInView])

  return (
    <div className='home-page'>
      <Menu authState={authState} authProps={authProps} />

      {!authProps.isLoggedIn ? (
        <div className='introduction '>
          <div className='bulleGauche'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 90 547' fill='none'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M-207.719 546.965C-285.811 545.483 -363.12 503.407 -404.631 437.192C-440.905 379.33 -410.784 307.584 -400.081 240.121C-391.863 188.317 -383.454 137.98 -350.669 97.0561C-313.226 50.3168 -267.577 0.122681 -207.719 0.000183105C-147.805 -0.122437 -110.697 58.6775 -64.1825 96.4698C-7.93778 142.167 75.3198 168.705 87.8229 240.121C101.211 316.591 50.6382 388.912 -3.1977 444.806C-58.1684 501.879 -128.525 548.467 -207.719 546.965Z'
                fill='#FCC9AA'
              />
            </svg>
          </div>
          <div className='introductionBG '>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 645' fill='none'>
              <path
                d='M1442 -116.473C1031.5 -157.18 527.274 -154.422 76.0599 -131.064C49.1262 -129.669 23.1377 -127.988 -2 -126.047V569.228C24.0647 569.187 50.0898 569.326 76.0599 569.673C543.602 575.922 1016.48 646.885 1442 644.962V-116.473Z'
                fill='#FDDFCC'
              />
            </svg>
          </div>
          <div className='bulleDroiteOiseauRougeContainer'>
            <div className='bulleDroite'>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 488 547' fill='none'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M297.719 0.0353374C375.811 1.51663 453.12 43.593 494.631 109.808C530.905 167.67 500.784 239.416 490.081 306.879C481.863 358.683 473.454 409.02 440.669 449.944C403.226 496.683 357.577 546.877 297.719 547C237.805 547.122 200.697 488.323 154.182 450.53C97.9378 404.833 14.6802 378.295 2.17707 306.879C-11.2109 230.409 39.3618 158.088 93.1977 102.194C148.168 45.1207 218.525 -1.46685 297.719 0.0353374Z'
                  fill='#FCC9AA'
                />
              </svg>
            </div>
            <img src={oiseauRouge} className='oiseauRouge' alt='oiseau rouge' />
          </div>
          <div className='introduction-container'>
            <h1>
              <span>Voyo</span> vous libère des contraintes qui vous séparent de votre nouveau nid
              douillet
            </h1>
          </div>
        </div>
      ) : authProps.isLoggedIn && authState.isVisiteur ? (
        <div className='introduction-inscrit-visiteur mt-[75px] '>
          <img src={background} alt='Image de fond' />
          <div className='introduction-inscrit-visiteur-content'>
            <h1 className='text-xl sm:text-4xl xl:text-6xl min-w-[250px]'>
              Vous cherchez un visiteur
            </h1>
            <h3 className='text-sm sm:text-lg xl:text-xl'>
              Trouvez la bonne personne pour le lieu qui vous intéresse
            </h3>
            <a href='programmation-visite' className='btn btn-orange bg-orange'>
              Recherche
            </a>
          </div>
        </div>
      ) : (
        <div className='introduction-inscrit mt-[75px] z-0'>
          <img src={background} alt='Image de fond' />
          <div className='introduction-inscrit-content  w-full sm:w-[80%] xl:w-[65%]'>
            <div className='introduction-inscrit-card '>
              <h1 className='text-xl sm:text-4xl xl:text-6xl '>
                Gagner de l&apos;argent en visitant des logements
              </h1>
              <h3 className='text-sm sm:text-lg xl:text-xl'>
                Fixer librement vos tarifs tout en visitant selon vos libertés
              </h3>
              <a href='inscription-visiteur'>Commencer</a>
            </div>
            <div className='introduction-inscrit-card '>
              <h1 className='text-xl sm:text-4xl xl:text-6xl'>Vous recherchez un visiteur</h1>
              <h3 className='text-sm sm:text-lg xl:text-xl'>
                Trouver la bonne personne pour le lieu qui vous intéresse
              </h3>
              <a href='programmation-visite'>Recherche</a>
            </div>
          </div>
        </div>
      )}
      {!authState.isVisiteur && !authProps.isLoggedIn && (
        <section className='part2HomePage '>
          <div className='part2HomePage-droite'>
            <img src={oiseauVert} className='' alt='oiseau jaune' />
          </div>

          <div className='part2HomePage-gauche'>
            <section>
              <h3>Déléguez vos visites</h3>

              <p>
                Faites confiance à notre communauté de visiteurs pour prendre soin de vos visites.
                Tous les éléments vous serons enevoyés via la plateforme en s’adaptant à vos
                exigences
              </p>
              <button
                className='bg-orange -mt-2  '
                onClick={() => (window.location.href = '/programmation-visite')}
              >
                Trouver un visiteur
              </button>
            </section>
            <div className='flex justify-center items-center'>
              <img src={separator} alt='' className='w-[80%]' />
            </div>

            <section>
              <h3>Devenez visiteur</h3>
              <p>
                Inscrivez-vous en tant que visiteur dès aujourd’hui et effectuez des visites pour le
                compte d’autres personnes en fonction de vos disponibilités
              </p>
              <button
                className='bg-orange -mt-2'
                onClick={() => (window.location.href = '/inscription-visiteur')}
              >
                Devenez visiteur
              </button>
            </section>
          </div>
        </section>
      )}

      <div
        className={`partie-jaune z-[-1] ${
          authProps.isLoggedIn && !authState.isVisiteur ? 'sm:mt-20' : ''
        } `}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1440 1164'
          fill='#FFF3D7'
          width='100%'
          height='100%'
          preserveAspectRatio='none'
        >
          <path d='M0 0.0384004C425.52 -1.88527 898.398 69.0778 1365.94 75.3265C1391.91 75.6736 1417.94 75.813 1444 75.7717V743V1164H0V743V0.0384004Z' />
        </svg>
        <div className='formes-partie-jaune'>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 142 973' fill='#FFECBC'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M-238.984 972.937C-100.222 970.302 37.1492 895.457 110.91 777.674C175.365 674.75 121.843 547.128 102.826 427.126C88.2217 334.977 73.2798 245.439 15.0247 172.643C-51.5082 89.5032 -132.621 0.218201 -238.984 0.000305176C-345.444 -0.217773 -411.381 104.375 -494.033 171.6C-593.974 252.886 -741.915 300.092 -764.132 427.126C-787.921 563.15 -698.058 691.794 -602.397 791.219C-504.72 892.74 -379.703 975.609 -238.984 972.937Z'
            />
          </svg>
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 108 792' fill='#FFECBC'>
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M309.703 0.622417C196.836 2.76563 85.0995 63.6439 25.1033 159.448C-27.3242 243.165 16.2101 346.971 31.679 444.58C43.5576 519.533 55.7112 592.363 103.095 651.574C157.213 719.199 223.189 791.823 309.703 792C396.297 792.177 449.93 707.102 517.158 652.422C598.449 586.305 718.783 547.908 736.853 444.58C756.203 333.939 683.11 229.302 605.3 148.43C525.85 65.8544 424.163 -1.55102 309.703 0.622417Z'
            />
          </svg>
        </div>
        <div className='description-homepage'>
          <div className='description-homepage-container'>
            <div className='description-homepage-part'>
              <section className='section-text  '>
                <h3>Être responsable de l&apos;environnement</h3>
                <p>
                  Parce que vous ne vous déplacez plus inutilement pour visiter un bien que vous
                  avez déjà vu en photos. La vérification est faite par quelqu&apos;un déjà présent
                  sur place, c&apos;est... éco-logique !
                </p>
              </section>
            </div>
            <div className='flex justify-center items-center'>
              <img src={separator} alt='' className='min-w-[80%]' />
            </div>

            <div className='description-homepage-part'>
              <section className='section-text'>
                <h3>Gagnez du temps lors de votre déménagement</h3>
                <p>
                  Une personne se déplace en votre nom et vous remet un rapport détaillé
                  personnalisé respectant les points que vous lui avez mentionnés. Votre temps se
                  libère et vous avec
                </p>
              </section>
            </div>
            <div className='flex justify-center items-center'>
              <img src={separator} alt='' className='min-w-[80%]' />
            </div>
            <div className='description-homepage-part'>
              <section className='section-text'>
                <h3>Des coûts réduits</h3>
                <p>
                  Déplacement, hébergement sur place, repas. Autant de choses que vous n’avez plus à
                  payer si vous passez par Voyo
                </p>
              </section>
            </div>
          </div>
          <img src={oiseauPlanete} alt='oiseau avec planete' />
        </div>
      </div>

      <div className='roadmap'>
        <div className='roadmap-item' ref={searchRef}>
          {searchAnimationVisible && <Lottie animationData={searchAnimation} loop={false} />}
          <div className='roadmap-content'>
            <p>Trouver un visiteur</p>
            <span className=''>1</span>
          </div>
        </div>

        <div className='roadmap-item' ref={contractRef}>
          {contractAnimationVisible && <Lottie animationData={contractAnimation} loop={false} />}
          <div className='roadmap-content'>
            <p>Reserver et payer</p>
            <span>2</span>
          </div>
        </div>
        <div className='roadmap-item' ref={houseRef}>
          {houseAnimationVisible && <Lottie animationData={houseAnimation} loop={false} />}
          <div className='roadmap-content'>
            <p>On visite pour vous</p>
            <span>3</span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default HomePage
