/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import separator from '../../assets/images/sep.png'
import oiseaux from '../../assets/images/oiseaux.png'
import lettre from '../../assets/images/lettre.png'

const PageFinal: React.FC<{ totalProfiles: number }> = ({ totalProfiles }) => {
  const handleRedirect = () => {
    window.location.href = 'www.voyo.fr/dashboard'
  }

  return (
    <>
      <div className='min-h-[50vh] md:min-h-[70vh] w-full flex justify-center items-center relative'>
        <div className='flex flex-col relative'>
          <div className='mx-5 sm:mx-20 sm:max-w-[700px] mt-[30%] sm:mt-[10%]'>
            <div className='flex justify-end'>
              <img src={lettre} alt='separator' className='w-28 sm:w-auto' />
            </div>
            <div className='flex justify-center items-center mt-1 mb-3'>
              <img src={separator} alt='separator' className='w-2/3 md:w-auto' />
            </div>
            <p className='text-lg sm:text-3xl font-bold text-gray-800'>
              Votre demande a été transmise au visiteur{totalProfiles > 1 ? 's' : ''} nous vous
              remercions de bien vouloir patienter le temps qu'il{totalProfiles > 1 ? 's' : ''}
              prenne{totalProfiles > 1 ? 'nt' : ''} connaissance de votre demande
            </p>
            <br />
            <p className='sm:text-2xl font-bold text-gray-800'>
              Vous pouvez retrouver vo{totalProfiles > 1 ? 's' : 'tre'} proposition
              {totalProfiles > 1 ? 's' : ''} dans votre boite au lettres dans la section demandes en
              attente
            </p>
            <br />
            <p className='sm:text-2xl font-bold text-gray-800'>
              N'hésitez pas à {totalProfiles > 1 ? 'leur' : 'lui'} envoyer des informations
              complémentaires directement via la messagerie fournie par Voyo
            </p>
            <div className='flex justify-center items-center my-3'>
              <img src={separator} alt='separator' className='w-2/3 md:w-auto' />
            </div>
            <div className='flex justify-center'>
              <button
                onClick={handleRedirect}
                className='bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700'
              >
                Aller au tableau de bord
              </button>
            </div>
          </div>

          <img
            className='absolute -right-[15%] -bottom-[180px] sm:-bottom-[210px]'
            src={oiseaux}
            alt='oiseaux'
          />
        </div>
      </div>
    </>
  )
}

export default PageFinal
