/* eslint-disable react/no-unescaped-entities */
import './BoiteAuxLettres.css'
import { SetStateAction, useEffect, useState } from 'react'
import { getChatsByUser, setStatusChat } from '../../../api/Chat'
import { timeAgo } from '../../../utils/Data.utils'
import ChatPage from '../Messagerie/ChatPage'
import etourneau from '../../../assets/images/image_oiseaux/etourneau.png'
import { io } from 'socket.io-client'
import { getVisite, updateVisite } from '../../../api/Visites'
import dayjs from 'dayjs'
import swal from 'sweetalert'
import { refundPayment } from '../../../api/Paiement'
import CompteRendu from '../../CompteRendu/CompteRendu'
import { Close } from '@mui/icons-material'
import { createNotifications } from '../../../api/Notifications'

interface BoiteAuxLettresProps {
  token: string
  authState: {
    email: string
    firstname: string
    lastname: string
    phoneNumber: string
    createdAt: Date
    updatedAt: Date
    userImg: string
    birthdate: string
    city: string
    isVisiteur: boolean
  }
  isLoading: boolean
  setIsLoading: React.Dispatch<SetStateAction<boolean>>
}

type ChatType = {
  createdAt: string
  email: string
  id: number
  visiteId: number
  otherUser: {
    firstname: string
    lastname: string
    city: string
    userImg: string
    email: string
  }
  status: number
  updatedAt: string
  userImg: string
}

type DataVisite = {
  UserEmail: string
  adresseBien: string
  codePostal: string
  createdAt: Date
  date: Date
  email: string
  heureVisite: string
  id: 1
  informations_complementaires: string
  prix: number
  proprietaire: string
  typeBien: string
  updatedAt: Date
  ville: string
  isValidatedByVisitor: boolean
  compte_rendu: any
}

const BoiteAuxLettres: React.FC<BoiteAuxLettresProps> = ({
  token,
  authState,
  isLoading,
  setIsLoading,
}: BoiteAuxLettresProps) => {
  const [selectedBoiteAuxLettresItem, setSelectedBoiteAuxLettresItem] = useState(0)

  const [userChatList, setUserChatList] = useState<ChatType[]>([])
  const [chat, setChat] = useState<ChatType>()
  const [isUserChatsLoading, setIsUserChatsLoading] = useState(false)
  const [userChatsError, setUserChatsError] = useState(null)
  const [displayChat, setDisplayChat] = useState(false)
  const [dataVisite, setDataVisite] = useState<DataVisite>()
  const [compteRenduOpen, setOpenCompteRendu] = useState(false)

  const serverAppDomain = process.env.REACT_APP_WEBSOCKET_URL
  const socket = io(serverAppDomain as string, { transports: ['websocket'] })
  const today = new Date().toISOString().split('T')[0]
  const getDataVisite = async (visiteId: number) => {
    const response = await getVisite(token, visiteId)

    if (response.success) setDataVisite(response.visite)
  }

  const currentDate = dayjs()

  const getUserChats = async () => {
    if (token) {
      setIsUserChatsLoading(true)
      setUserChatsError(null)
      try {
        const response = await getChatsByUser(token)

        setIsUserChatsLoading(false)
        const userEmail = authState.email
        if (response.error) {
          setUserChatsError(response)
        } else {
          const filteredChats = response.message
            .filter((chat: any) => {
              return chat.User1Email === userEmail || chat.User2Email === userEmail
            })
            .map((chat: any) => {
              const otherUser = chat.User1Email === userEmail ? chat.User2 : chat.User1
              return {
                id: chat?.id,
                status: chat?.status,
                createdAt: chat?.createdAt,
                updatedAt: chat?.updatedAt,
                userImg: chat?.User1.userImg,
                email: userEmail,
                visiteId: chat?.visiteId,
                isValidatedByVisitor: chat?.visite?.isValidatedByVisitor,
                compteRenduIsSubmit: chat.visite?.compte_rendu?.isSubmit,
                otherUser: {
                  firstname: otherUser?.firstname,
                  lastname: otherUser?.lastname,
                  city: otherUser?.city,
                  userImg: otherUser?.userImg,
                  email: otherUser?.email,
                },
              }
            })
            .sort(
              (a: any, b: any) => (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any),
            )

          setUserChatList(filteredChats)

          const url = window.location.href
          const urlObj = new URL(url)
          const chatId = urlObj.searchParams.get('chatId')

          if (chatId) {
            setTimeout(() => {
              const targetChat = filteredChats.find((chat: any) => {
                return chat.id === Number(chatId)
              })

              if (targetChat) {
                setSelectedBoiteAuxLettresItem(targetChat.status - 1)
                getDataVisite(targetChat.id)
                setDisplayChat(!displayChat)
                setChat(targetChat)
                const newUrl = '/dashboard'
                window.history.pushState(null, '', newUrl)
                setIsLoading(false)
              }
            }, 200)
          }
        }
      } catch (error: any) {
        setIsUserChatsLoading(false)
        setUserChatsError(error)
      }
    }
  }
  useEffect(() => {
    getUserChats()
  }, [token, selectedBoiteAuxLettresItem])

  useEffect(() => {
    const url = window.location.href
    const urlObj = new URL(url)
    const fromPaiement = urlObj.searchParams.get('valid')
    if (fromPaiement) {
      setTimeout(() => {
        setSelectedBoiteAuxLettresItem(1)
        window.history.pushState({}, '', '/dashboard')
      }, 50)
    }
  }, [])

  useEffect(() => {
    socket.emit('newUser', authState.email)
  }, [])

  useEffect(() => {
    if (chat && chat.id) getDataVisite(chat.id)
  }, [compteRenduOpen])

  const menuItems = [
    { label: 'Demandes en attente' },
    { label: 'Visites à venir' },
    { label: 'Visites passées' },
    { label: 'Demandes archivées' },
  ]

  const handleMenuItemClick = (index: SetStateAction<number>) => {
    setSelectedBoiteAuxLettresItem(index)
  }

  const renderContent = (chatList: ChatType[]) => {
    const filteredChat = chatList.filter(
      (chat: ChatType) => chat.status === selectedBoiteAuxLettresItem + 1,
    )

    return isLoading ? (
      <div className='flex justify-center items-center min-h-[50vh]'>
        <div className='border-t-2 border-b border-b-red-100 border-solid rounded-full animate-spin w-40 h-40 border-t-orange-400'></div>
      </div>
    ) : (
      <div className='list-message  max-h-[80vh] pb-2 overflow-auto px-1'>
        {filteredChat.length > 0 ? (
          <>
            {filteredChat.map((chat: any) => (
              <div
                key={chat.id}
                className='message hover:bg-gray-50 bg-opacity-10 '
                onClick={() => {
                  getDataVisite(chat.id)
                  setDisplayChat(!displayChat)
                  setChat(chat)
                }}
              >
                <div className='message-content'>
                  <img
                    src={chat?.otherUser?.userImg ? chat?.otherUser?.userImg : etourneau}
                    alt='man'
                  />
                  <div>
                    <div className='message-content-user-info'>
                      <h4>{chat?.otherUser?.firstname}</h4>
                      <h4 className='uppercase'>{chat?.otherUser?.lastname[0]}</h4>
                    </div>
                    <p>{dayjs(new Date(chat.createdAt)).format('DD/MM/YYYY')}</p>

                    <p>Modifié il y a {timeAgo(chat?.updatedAt)}</p>
                  </div>

                  {chat.status === 1 ? (
                    <>
                      {!authState.isVisiteur ? (
                        <>
                          {!chat?.isValidatedByVisitor ? (
                            <p className='font-semibold'>En attente de validation</p>
                          ) : (
                            <span className='text-green-400'>Visite validée prête à être payé</span>
                          )}
                        </>
                      ) : (
                        <>
                          {!chat?.isValidatedByVisitor ? (
                            <p className='font-semibold'>En attente de validation</p>
                          ) : (
                            <span className='text-green-400 '>En attente de paiement</span>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    chat.status === 3 && (
                      <>
                        {!authState.isVisiteur ? (
                          <>
                            {!chat?.compteRenduIsSubmit ? (
                              <p className='font-semibold'>Compte rendu en attente</p>
                            ) : (
                              <span className='text-green-400'>Compte rendu reçu</span>
                            )}
                          </>
                        ) : (
                          <>
                            {!chat?.compteRenduIsSubmit ? (
                              <p className='font-semibold'>Compte rendu en attente d'envoi</p>
                            ) : (
                              <span className='text-green-400 '>Compte rendu envoyé</span>
                            )}
                          </>
                        )}
                      </>
                    )
                  )}
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className='flex justify-center items-center min-h-[60vh]'>
            <p>Aucune {menuItems[selectedBoiteAuxLettresItem].label} </p>
          </div>
        )}
      </div>
    )
  }

  return !compteRenduOpen ? (
    <div className='flex  flex-col-reverse gap-5 lg:flex-row lg:justify-between relative'>
      <div className={` ${displayChat ? 'hidden' : 'flex flex-col w-full'} `}>
        <ul
          className={`flex items-start rounded-lg bg-orange-100   ${
            displayChat ? 'absolute -top-[2000px] ' : 'w-fit'
          }`}
        >
          {menuItems.map((item, index) => (
            <li
              key={index}
              onClick={() => handleMenuItemClick(index)}
              className={`boiteAuxLettres-menu-item    ${
                selectedBoiteAuxLettresItem === index ? 'boiteAuxLettres-menu-item-active' : ''
              }`}
            >
              {item.label}
            </li>
          ))}
        </ul>

        <select
          className='hidden md:block'
          value={selectedBoiteAuxLettresItem}
          onChange={(e) => handleMenuItemClick(Number(e.target.value))}
        >
          {menuItems.map((item, index) => (
            <option
              key={index}
              value={index}
              className={`select-boiteAuxLettres-menu-item ${
                selectedBoiteAuxLettresItem === index ? 'boiteAuxLettres-menu-item-active' : ''
              }`}
            >
              {item.label}
            </option>
          ))}
        </select>
        <div className={displayChat && chat ? 'hidden' : 'content-container'}>
          {renderContent(userChatList)}
        </div>
      </div>

      {displayChat && (
        <>
          {' '}
          <ChatPage chat={chat} authState={authState} token={token} />{' '}
          <div className='absolute lg:-top-[6%] -top-[1.5%] right-2 cursor-pointer '>
            <Close onClick={() => setDisplayChat(() => false)} />
          </div>
        </>
      )}

      <div
        className={`flex flex-col items-start   ${
          displayChat ? ' rounded-2xl space-y-11  md:ml-4' : 'hidden'
        }`}
      >
        {/* demande de visite non validé  */}

        {chat?.status === 1 && dataVisite ? (
          <div className='bg-orange-700 text-white p-4 rounded-2xl lg:w-80 w-full mt-5 lg:mt-0'>
            <p className='font-bold mb-2 text-xl'>Demande de visite</p>
            <div className='mb-2 flex flex-col gap-1'>
              Type de logement :{' '}
              <select
                disabled={authState.isVisiteur}
                onChange={async (e) => {
                  if (dataVisite) {
                    const newValues = { ...dataVisite, typeBien: e.target.value }
                    setDataVisite(newValues)
                    updateVisite(token, dataVisite.id, newValues)
                  }
                }}
                value={dataVisite?.typeBien}
                className={`${
                  authState?.isVisiteur ? 'text-black cursor-not-allowed' : 'text-gray-400'
                } uppercase text-xs p-1 rounded-sm cursor-pointer w-1/2`}
              >
                <option value={'appartement'}>appartement</option>
                <option value={'maison'}>maison</option>
              </select>
            </div>
            <div className='mb-2 flex flex-col gap-1'>
              <p>Date / heure : </p>
              <div className='flex gap-1'>
                <input
                  disabled={authState.isVisiteur}
                  type='date'
                  min={today}
                  onChange={(e) => {
                    if (dataVisite) {
                      const newValues = {
                        ...dataVisite,
                        date: new Date(e.target.value),
                      }
                      setDataVisite(newValues)
                      updateVisite(token, dataVisite.id, newValues)
                    }
                  }}
                  value={dayjs(dataVisite?.date).format('YYYY-MM-DD')}
                  className={`${
                    authState?.isVisiteur ? 'text-white cursor-not-allowed' : 'text-gray-400'
                  } uppercase text-xs p-1 rounded-sm cursor-pointer w-1/2`}
                />
                <input
                  type='time'
                  disabled={authState.isVisiteur}
                  onChange={(e) => {
                    if (dataVisite) {
                      const newValues = {
                        ...dataVisite,

                        heureVisite: e.target.value,
                      }
                      setDataVisite(newValues)
                      updateVisite(token, dataVisite.id, newValues)
                    }
                  }}
                  value={dataVisite.heureVisite}
                  className={`${
                    authState?.isVisiteur ? 'text-white cursor-not-allowed' : 'text-gray-400'
                  } uppercase text-xs p-1 rounded-sm cursor-pointer w-1/2`}
                />
              </div>
            </div>
            <div className='mb-2 flex flex-col gap-1'>
              <span>Ville</span>
              <input
                disabled={authState.isVisiteur}
                type='text'
                onChange={(e) => {
                  if (dataVisite) {
                    const newValues = { ...dataVisite, ville: e.target.value }
                    setDataVisite(newValues)
                    updateVisite(token, dataVisite.id, newValues)
                  }
                }}
                value={dataVisite?.ville}
                className={`${
                  authState?.isVisiteur ? 'text-white cursor-not-allowed' : 'text-gray-400'
                } uppercase text-xs p-1 rounded-sm cursor-pointer w-1/2`}
              />
            </div>

            <label htmlFor='prix'>
              Prix{' '}
              {!authState.isVisiteur && (
                <span className='text-sm '>
                  (+ {Math.round(Math.round(dataVisite?.prix * 100) * 0.15) / 100}€ de frais)
                </span>
              )}
              :
            </label>
            <input
              disabled={!authState.isVisiteur}
              type='number'
              id='prix'
              className={`${
                !authState?.isVisiteur ? 'text-white cursor-not-allowed' : 'text-gray-400'
              } w-full p-2 mb-2 border-gray-300 rounded text-gray-600`}
              placeholder='Votre prix...'
              onChange={(e) => {
                if (dataVisite) {
                  const newValues = { ...dataVisite, prix: Number(e.target.value) }
                  setDataVisite(newValues)
                  updateVisite(token, dataVisite.id, newValues)
                }
              }}
              value={dataVisite?.prix || undefined}
            />
            {authState.isVisiteur ? (
              <>
                {!dataVisite?.isValidatedByVisitor ? (
                  <div className='flex justify-between'>
                    <button
                      onClick={async () => {
                        swal({
                          title: 'Êtes-vous sûr(e) ?',
                          text: 'Voulez-vous vraiment refuser cette demande ?',
                          icon: 'warning',
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            let isUpdateStatus
                            let isUpdatedVisite
                            if (dataVisite) {
                              const newValues = { ...dataVisite, isValidatedByVisitor: false }
                              isUpdateStatus = await setStatusChat(token, chat.id, 4)
                              isUpdatedVisite = await updateVisite(token, dataVisite.id, newValues)
                            }

                            if (isUpdateStatus.success && isUpdatedVisite.success) {
                              const data = {
                                type: 'VisiteRefused',
                                content: 'Visite refusé',
                                userImg: authState.userImg,
                                firstName: authState.firstname,
                                lastName: authState.lastname,
                                chatId: chat.id,
                              }
                              if (chat.otherUser.email)
                                await createNotifications(
                                  token,
                                  authState.firstname,
                                  [chat.otherUser.email],
                                  data,
                                )

                              swal('Pouf ! Votre demande a été refusée !', {
                                icon: 'success',
                              }).then(() => {
                                window.location.href = '/dashboard'
                              })
                            } else {
                              swal('Pouf ! Impossible d annuler votre demande !', {
                                icon: 'error',
                              })
                            }
                          }
                        })
                      }}
                    >
                      Refuser
                    </button>
                    <button
                      onClick={async () => {
                        swal({
                          title: 'Êtes-vous sûr(e) ?',
                          text: 'Voulez-vous vraiment accepter cette demande ?',
                          icon: 'warning',
                          dangerMode: true,
                        }).then(async (willDelete) => {
                          if (willDelete) {
                            let isUpdate
                            if (dataVisite) {
                              const newValues = { ...dataVisite, isValidatedByVisitor: true }
                              isUpdate = await updateVisite(token, dataVisite.id, newValues)
                            }

                            if (isUpdate.success) {
                              const data = {
                                type: 'VisiteAccepted',
                                content: 'Visite acceptée',
                                userImg: authState.userImg,
                                firstName: authState.firstname,
                                lastName: authState.lastname,
                                chatId: chat.id,
                              }
                              if (chat.otherUser.email)
                                await createNotifications(
                                  token,
                                  authState.firstname,
                                  [chat.otherUser.email],
                                  data,
                                )

                              swal('Pouf ! Votre demande a été acceptée !', {
                                icon: 'success',
                              }).then(() => {
                                setTimeout(() => {
                                  getDataVisite(chat.id)
                                }, 500)
                              })
                            } else {
                              swal('Pouf ! Impossible d accepter votre demande !', {
                                icon: 'error',
                              })
                            }
                          }
                        })
                      }}
                    >
                      Accepter
                    </button>
                  </div>
                ) : (
                  <p className='text-xs italic text-yellow-400 mb-2'>
                    Demande en attente de paiement par le mandant
                  </p>
                )}
              </>
            ) : dataVisite?.isValidatedByVisitor ? (
              <>
                <p className='text-xs italic text-green-400 mb-2'>
                  Demande validée par le visiteur
                </p>
                <div className='flex justify-between'>
                  <button
                    onClick={async () => {
                      swal({
                        title: 'Êtes-vous sûr(e) ?',
                        text: 'Voulez-vous vraiment annuler cette demande ?',
                        icon: 'warning',
                        dangerMode: true,
                      }).then(async (willDelete) => {
                        if (willDelete) {
                          const isUpdate = await setStatusChat(token, chat.id, 4)

                          const data = {
                            type: 'VisiteAnnuled',
                            content: 'Visite annulé',
                            userImg: authState.userImg,
                            firstName: authState.firstname,
                            lastName: authState.lastname,
                            chatId: chat.id,
                          }
                          if (chat.otherUser.email)
                            await createNotifications(
                              token,
                              authState.firstname,
                              [chat.otherUser.email],
                              data,
                            )

                          if (isUpdate.success) {
                            swal('Pouf ! Votre demande a été annulé !', {
                              icon: 'success',
                            }).then(() => {
                              window.location.href = '/dashboard'
                            })
                          } else {
                            swal('Pouf ! Impossible d annuler votre demande !', {
                              icon: 'error',
                            })
                          }
                        }
                      })
                    }}
                    className=''
                  >
                    Annuler
                  </button>
                  <button
                    onClick={() => {
                      window.location.href = `/paiement?id=${chat.id}`
                    }}
                    className=''
                  >
                    Payer
                  </button>
                </div>
              </>
            ) : (
              <p className='text-xs italic text-yellow-400 mb-2'>
                Demande en attente de validation par le visiteur.
              </p>
            )}

            <p className='text-xs italic text-gray-300'>
              Réserver et payer par Voyo est requis par les conditions d&apos;utilisation.
              N&apos;acceptez jamais d&apos;espèces.
            </p>
          </div>
        ) : (
          chat?.status === 2 &&
          dataVisite && (
            <>
              <div className='bg-orange-700 text-white p-4 rounded-2xl lg:w-80 w-full mt-5 lg:mt-0'>
                <p className='font-bold mb-2 text-xl'>Visite à venir</p>
                <div className='mb-2 flex flex-col gap-1'>
                  <div>
                    <label htmlFor='type'>Type de logement :</label>
                    <p className='text-gray-300 uppercase text-xs p-1 rounded-sm cursor-pointer w-1/2'>
                      {dataVisite?.typeBien}
                    </p>
                  </div>
                  <div>
                    <label htmlFor='dataHour'>Date / heure :</label>
                    <p className='text-gray-300 uppercase text-xs p-1 rounded-sm cursor-pointer w-1/2'>
                      {dayjs(dataVisite?.date).format('MM/DD HH:mm')}
                    </p>
                  </div>
                  <div>
                    <label htmlFor='Ville'>Ville :</label>
                    <p className='text-gray-300 uppercase text-xs p-1 rounded-sm cursor-pointer w-1/2'>
                      {dataVisite?.ville}
                    </p>
                  </div>
                  <div>
                    <label htmlFor='Prix'>Prix :</label>
                    <p className='text-gray-300 uppercase text-xs p-1 rounded-sm cursor-pointer w-1/2'>
                      {dataVisite?.prix}€
                    </p>
                  </div>
                </div>

                {dayjs(dataVisite?.date).isBefore(currentDate) && authState.isVisiteur ? (
                  <>
                    <p className='text-xs italic text-yellow-400 mt-4 mb-2'>
                      Merci de valider si la visite à bien été effectuée.
                    </p>
                    <div className='flex justify-between'>
                      <button
                        onClick={() => {
                          swal({
                            title: 'Êtes-vous sûr(e) ?',
                            text: 'Cette visite na pas eu lieu ?',
                            icon: 'warning',
                            dangerMode: true,
                          }).then(async (willDelete) => {
                            if (willDelete) {
                              const isRefund = await refundPayment(token, dataVisite.id)

                              const isUpdate = await setStatusChat(token, chat.id, 4)

                              if (isUpdate?.success && isRefund?.success) {
                                const data = {
                                  type: 'VisiteAnnuled',
                                  content: 'Visite annulé et remboursé',
                                  userImg: authState.userImg,
                                  firstName: authState.firstname,
                                  lastName: authState.lastname,
                                  chatId: chat.id,
                                }
                                if (chat.otherUser.email)
                                  await createNotifications(
                                    token,
                                    authState.firstname,
                                    [chat.otherUser.email],
                                    data,
                                  )

                                swal('Pouf ! visite annulée', {
                                  icon: 'success',
                                }).then(() => {
                                  window.location.href = '/dashboard'
                                })
                              } else {
                                swal('Pouf ! Impossible d annuler votre demande !', {
                                  icon: 'error',
                                })
                              }
                            }
                          })
                        }}
                      >
                        Non
                      </button>
                      <button
                        onClick={async () => {
                          const isUpdate = await setStatusChat(token, chat.id, 3)
                          if (isUpdate?.success) {
                            swal({
                              title: 'Good job!',
                              text: 'Visite terminée ! Commencez maintenant votre compte rendu :)',
                              icon: 'success',
                            }).then(async (value) => {
                              if (value) {
                                const data = {
                                  type: 'VisiteFinished',
                                  content: 'Visite terminée',
                                  userImg: authState.userImg,
                                  firstName: authState.firstname,
                                  lastName: authState.lastname,
                                  chatId: chat.id,
                                }
                                if (chat.otherUser.email)
                                  await createNotifications(
                                    token,
                                    authState.firstname,
                                    [chat.otherUser.email],
                                    data,
                                  )

                                setDisplayChat(false)
                                handleMenuItemClick(2)
                              }
                            })
                          }
                        }}
                      >
                        Oui
                      </button>
                    </div>
                  </>
                ) : authState.isVisiteur ? (
                  <>
                    <p className='text-xs italic text-yellow-400 mt-4 mb-2'>
                      Une fois la visite effectuée vous pourrez effectuer votre compte rendu final.
                    </p>
                  </>
                ) : (
                  <>
                    <p className='text-xs italic text-yellow-400 mt-4 mb-2'>
                      Demande en attente de visite
                    </p>
                  </>
                )}
              </div>
            </>
          )
        )}

        {chat?.status === 3 && (
          <>
            <div className='bg-orange-700 text-white p-4 rounded-2xl lg:w-80 w-full mt-5 lg:mt-0'>
              <p className='font-bold mb-2 text-xl'>Visite Terminée</p>
              <div className='mb-2 flex flex-col gap-1'>
                <div>
                  <label htmlFor='type'>Type de logement :</label>
                  <p className='text-gray-300 uppercase text-xs p-1 rounded-sm cursor-pointer w-1/2'>
                    {dataVisite?.typeBien}
                  </p>
                </div>
                <div>
                  <label htmlFor='dataHour'>Date / heure :</label>
                  <p className='text-gray-300 uppercase text-xs p-1 rounded-sm cursor-pointer w-1/2'>
                    {dayjs(dataVisite?.date).format('MM/DD HH:mm')}
                  </p>
                </div>
                <div>
                  <label htmlFor='Ville'>Ville :</label>
                  <p className='text-gray-300 uppercase text-xs p-1 rounded-sm cursor-pointer w-1/2'>
                    {dataVisite?.ville}
                  </p>
                </div>
                <div>
                  <label htmlFor='Prix'>Prix :</label>
                  <p className='text-gray-300 uppercase text-xs p-1 rounded-sm cursor-pointer w-1/2'>
                    {dataVisite?.prix}€
                  </p>
                </div>
              </div>

              {authState.isVisiteur ? (
                <>
                  {!dataVisite?.compte_rendu.isSubmit ? (
                    <p className='text-xs italic text-green-400 mt-4 mb-2'>
                      Visite terminée merci de commencer votre compte rendu pour donner un maximum
                      d'informations à votre interlocuteur.
                    </p>
                  ) : (
                    <p className='text-xs italic text-green-400 mt-4 mb-2'>
                      Visite terminée et compte rendu envoyé.
                    </p>
                  )}

                  <div className='flex items-center justify-center w-full'>
                    <button
                      onClick={() => setOpenCompteRendu(true)}
                      className='btn btn-orange bg-orange w-fit'
                    >
                      {!dataVisite?.compte_rendu.isSubmit && !dataVisite?.compte_rendu.haveData
                        ? 'Envoyer le compte rendu'
                        : dataVisite?.compte_rendu.haveData
                        ? 'Modifier le compte rendu'
                        : 'Envoyer le compte rendu'}
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {dataVisite?.compte_rendu.isSubmit ? (
                    <div className='flex items-center justify-center w-full'>
                      <button
                        onClick={() => setOpenCompteRendu(true)}
                        className='btn btn-orange bg-orange w-fit'
                      >
                        Voir le compte rendu
                      </button>
                    </div>
                  ) : (
                    <p className='text-xs italic text-green-400 mt-4 mb-2'>
                      Visite terminée, <span className='uppercase'>{chat.otherUser.firstname}</span>{' '}
                      vous prépare votre compte rendu détaillé pour vous donner un maximum
                      d'informations sur la visite.
                    </p>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  ) : (
    <>
      <CompteRendu
        authState={authState}
        token={token}
        dataVisite={dataVisite}
        chat={chat}
        setDataVisite={setDataVisite}
        setOpenCompteRendu={setOpenCompteRendu}
      />
    </>
  )
}

export default BoiteAuxLettres
