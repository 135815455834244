import React, { FormEvent, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import { MailOutline } from '@mui/icons-material'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { register } from '../../../api/Authentification'
import { InscriptionData } from './Inscription.type'
import Alert from '../../Alert/Alert'
import { validateEmail, validatePassword } from '../../../utils/Data.utils'
import kiwi from '../../../assets/gif/bird.gif'
import birdHouse from '../../../assets/images/bird-house.png'
import logoGoogle from '../../../assets/svg/Googlelogo.svg'
import Highlight1 from '../../../assets/svg/Highlight_1.svg'
import Highlight2 from '../../../assets/svg/Highlight_2.svg'
import Highlight3 from '../../../assets/svg/Highlight_3.svg'
import './index.css'
import './index-tablette.css'
import './index-mobile.css'

const Inscription: React.FC = () => {
  const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN
  const [isComplete, setIsComplete] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const [showAlert, setShowAlert] = useState({
    email: false,
    firstname: false,
    lastname: false,
    birthdate: false,
    password: false,
    city: false,
  })

  const handleShowAlert = (name: string) => {
    setShowAlert((prevData) => ({
      ...prevData,
      [name]: true,
    }))
  }

  const handleCloseAlert = (name: string) => {
    setShowAlert((prevData) => ({
      ...prevData,
      [name]: false,
    }))
  }

  const initialData: InscriptionData = {
    email: '',
    firstname: '',
    lastname: '',
    birthdate: '',
    password: '',
    city: '',
  }

  const [data, setData] = useState(initialData)

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target
    setData((prevData) => ({ ...prevData, [name]: value }))

    // Vérification de l'âge ici
    const birthdate = new Date(value)
    const currentDate = new Date()
    const age = currentDate.getFullYear() - birthdate.getFullYear()

    if (age < 18) {
      setShowAlert((prevShowAlert) => ({ ...prevShowAlert, birthdate: true }))
    } else {
      setShowAlert((prevShowAlert) => ({ ...prevShowAlert, birthdate: false }))
    }
  }

  function handleRegister() {
    register(data)
      .then(() => {
        window.location.href = '/validation-compte'
      })
      .catch((error: any) => {
        console.error('Erreur lors de la requête :', error)
      })
  }

  const handleClick = () => {
    if (!isComplete) {
      if (validateEmail(data.email)) {
        setIsComplete(true)
      } else {
        handleShowAlert('email')
      }
    } else {
      let allInputValid = true
      for (const [key, value] of Object.entries(data)) {
        if (value === '') {
          handleShowAlert(key)
          allInputValid = false
        } else {
          handleCloseAlert(key)
        }
      }
      if (data.password !== '' && !validatePassword(data.password)) {
        handleShowAlert('password')
        allInputValid = false
      }
      if (allInputValid) {
        handleRegister()
      }
    }
  }

  const [passwordFeedback, setPasswordFeedback] = useState('')

  const handlePasswordChange = (e: FormEvent<HTMLInputElement>) => {
    const newPassword = e.currentTarget.value
    setData((prevData) => ({
      ...prevData,
      password: newPassword,
    }))

    // Vérifiez la validité du mot de passe en temps réel
    if (newPassword.length < 8) {
      setPasswordFeedback('Le mot de passe doit contenir au moins 8 caractères.')
    } else if (!/[A-Z]/.test(newPassword)) {
      setPasswordFeedback('Le mot de passe doit contenir au moins une lettre majuscule.')
    } else if (!/\d/.test(newPassword)) {
      setPasswordFeedback('Le mot de passe doit contenir au moins un chiffre.')
    } else if (!/[@#$%^&=!?,+:/;]/.test(newPassword)) {
      setPasswordFeedback('Le mot de passe doit contenir au moins un caractère spécial.')
    } else {
      setPasswordFeedback('Mot de passe valide.')
    }
  }

  return (
    <div className='inscription'>
      <div className='inscription-content'>
        <h1>
          Inscrivez-vous pour trouver un <span>visiteur</span>
        </h1>
        {!isComplete ? (
          <div className='inscription-form'>
            <a href={SERVER_APP_DOMAIN + 'api/auth/google'} className='connexionGoogle'>
              <img src={logoGoogle} alt='logo de Google' />
              <p>Continuer avec Google</p>
            </a>
            <div className='ou-container'>
              <div className='line'></div>
              <div className='or'>OU</div>
              <div className='line'></div>
            </div>
            <div>
              <label className='label'>E-mail :</label>
              <div className='input input-email'>
                <MailOutline />
                <input
                  type='text'
                  placeholder='Entrez votre adresse email'
                  className='input'
                  name='email'
                  value={data.email}
                  onChange={handleInputChange}
                />
              </div>
              {showAlert.email && (
                <Alert
                  type='error'
                  message='Mail invalide'
                  onClose={() => handleCloseAlert('email')}
                />
              )}
            </div>
          </div>
        ) : (
          <div className='inscription-form'>
            <div>
              <label className='label'>Nom :</label>
              <input
                type='text'
                placeholder='Entrez votre nom'
                className='input'
                name='lastname'
                value={data.lastname}
                onChange={handleInputChange}
              />
              {showAlert.lastname && (
                <Alert
                  type='error'
                  message='Nom invalide'
                  onClose={() => handleCloseAlert('lastname')}
                />
              )}
            </div>
            <div>
              <label className='label'>Prénom :</label>
              <input
                type='text'
                placeholder='Entrez votre prénom'
                className='input'
                name='firstname'
                value={data.firstname}
                onChange={handleInputChange}
              />
              {showAlert.firstname && (
                <Alert
                  type='error'
                  message='Prénom invalide'
                  onClose={() => handleCloseAlert('firstname')}
                />
              )}
            </div>
            <div>
              <label className='label'>Ville :</label>
              <Autocomplete
                apiKey={process.env.REACT_APP_GOOGLE}
                className='input'
                options={{
                  componentRestrictions: { country: 'fr' },
                }}
                onPlaceSelected={(place) => {
                  setData((prevData) => ({
                    ...prevData,
                    city: place.address_components[0].short_name,
                  }))
                }}
              />
              {showAlert.city && (
                <Alert
                  type='error'
                  message='Ville invalide'
                  onClose={() => handleCloseAlert('city')}
                />
              )}
            </div>
            <div>
              <label className='label'>Date de naissance :</label>
              <input
                type='date'
                placeholder='JJ/MM/AAAA'
                className='input'
                name='birthdate'
                value={data.birthdate}
                onChange={handleInputChange}
              />
              {showAlert.birthdate && (
                <Alert
                  type='error'
                  message='Date de naissance invalide. Vous devez avoir plus de 18 ans.'
                  onClose={() => handleCloseAlert('birthdate')}
                />
              )}
            </div>
            <div>
              <label className='label'>Mot de passe :</label>
              <div className='input input-password'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder='Tapez votre mot de passe'
                  className='input'
                  name='password'
                  value={data.password}
                  onChange={handlePasswordChange}
                />
                {showPassword ? (
                  <VisibilityOffIcon onClick={togglePasswordVisibility} />
                ) : (
                  <VisibilityIcon onClick={togglePasswordVisibility} />
                )}
              </div>
              {showAlert.password && (
                <Alert
                  type='error'
                  message='Mot de passe invalide'
                  onClose={() => handleCloseAlert('password')}
                />
              )}
              {passwordFeedback && (
                <div
                  className={
                    passwordFeedback === 'Mot de passe valide.'
                      ? 'password-valid'
                      : 'password-invalid'
                  }
                >
                  <Alert
                    type='warning'
                    message={passwordFeedback}
                    onClose={() => handleCloseAlert('password')}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <button className='bg-orange mb-5' onClick={handleClick}>
          {!isComplete ? 'Continuer' : 'Terminer'}
        </button>
        <p className='inscription-compte'>
          Vous avez déjà un compte ?
          <a href='/connexion' className='btn btn-orange bg-orange'>
            Se connecter
          </a>
        </p>
      </div>
      <div className='authentification-bird-house'>
        <div className='authentification-bird-house-highlight'>
          <img src={birdHouse} alt='birdHouse' className='birdHouse' />
          <img src={Highlight3} alt='' className='Highlight3' />
        </div>
        <img src={kiwi} alt='kiwi' className='kiwi' />
        <img src={Highlight1} alt='' className='Highlight1' />
        <img src={Highlight2} alt='' className='Highlight2' />
      </div>
    </div>
  )
}

export default Inscription
