import Axios from 'axios'

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN

export const createMessage = async (token: string, chatId: number, content: string) => {
  try {
    const response = await Axios.post(
      `${SERVER_APP_DOMAIN}api/message`,
      {
        chatId,
        content,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const getMessagesByChat = async (token: string, chatId: number) => {
  try {
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/message/${chatId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const updateMessage = async (token: string, messageId: number, content: string) => {
  try {
    const response = await Axios.put(
      `${SERVER_APP_DOMAIN}api/messages/${messageId}`,
      {
        content,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const deleteMessage = async (token: string, messageId: number) => {
  try {
    const response = await Axios.delete(`${SERVER_APP_DOMAIN}api/message/${messageId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}
