import React from 'react'

const CookiePolicyPage = () => {
  return (
    <div className='min-h-screen bg-gray-100 p-6 flex flex-col items-center'>
      <div className='bg-white rounded-lg shadow-lg p-8 max-w-4xl'>
        <h1 className='text-4xl font-bold mb-4 text-center' style={{ color: '#ff5733' }}>
          Politique d&apos;utilisation des cookies
        </h1>
        <p className='text-gray-600 text-center mb-8'>Dernière mise à jour : 28.08.2024</p>

        <section className='mb-6'>
          <p className='text-gray-700'>
            Chez Voyo, nous utilisons des cookies et des technologies similaires pour améliorer
            votre expérience sur notre site web, personnaliser le contenu et les publicités, fournir
            des fonctionnalités de réseaux sociaux et analyser notre trafic. Cette politique
            d&apos;utilisation des cookies explique ce que sont les cookies, comment nous les
            utilisons, et comment vous pouvez les contrôler.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            1. Que sont les cookies ?
          </h2>
          <p className='text-gray-700'>
            Les cookies sont de petits fichiers texte qui sont placés sur votre appareil
            (ordinateur, smartphone, tablette) lorsque vous visitez un site web. Ils permettent au
            site de reconnaître votre appareil et de mémoriser certaines informations concernant vos
            préférences ou vos actions passées.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            2. Types de cookies que nous utilisons
          </h2>
          <p className='text-gray-700 mb-4'>
            Nous utilisons les types de cookies suivants sur notre site :
          </p>
          <ul className='list-disc list-inside text-gray-700 space-y-2'>
            <li>
              <strong>Cookies strictement nécessaires :</strong> Ces cookies sont indispensables au
              fonctionnement du site et ne peuvent pas être désactivés dans nos systèmes. Ils sont
              généralement installés en réponse à des actions que vous avez effectuées et qui
              constituent une demande de services, comme la connexion à votre compte ou le
              remplissage de formulaires.
            </li>
            <li>
              <strong>Cookies de performance :</strong> Ces cookies nous permettent de compter les
              visites et les sources de trafic afin de mesurer et d&apos;améliorer la performance de
              notre site. Ils nous aident à savoir quelles pages sont les plus et les moins
              populaires et à voir comment les visiteurs naviguent sur le site.
            </li>
            <li>
              <strong>Cookies de fonctionnalités :</strong> Ces cookies permettent au site de
              fournir des fonctionnalités améliorées et une personnalisation. Ils peuvent être
              définis par nous ou par des fournisseurs tiers dont nous avons ajouté les services à
              nos pages.
            </li>
            <li>
              <strong>Cookies de ciblage/publicitaires :</strong> Ces cookies peuvent être installés
              sur notre site par nos partenaires publicitaires. Ils peuvent être utilisés par ces
              entreprises pour établir un profil de vos intérêts et vous montrer des publicités
              pertinentes sur d&apos;autres sites.
            </li>
          </ul>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            3. Comment contrôlez-vous les cookies ?
          </h2>
          <p className='text-gray-700'>
            Vous pouvez contrôler et gérer les cookies de plusieurs manières. Vous pouvez configurer
            votre navigateur pour bloquer ou supprimer les cookies, ou pour vous alerter
            lorsqu&apos;un cookie est envoyé à votre appareil. Cependant, veuillez noter que si vous
            désactivez les cookies, certaines parties de notre site pourraient ne pas fonctionner
            correctement.
          </p>
          <p className='text-gray-700 mt-2'>
            Vous pouvez également gérer vos préférences de cookies directement sur notre site via
            notre outil de gestion des cookies, accessible à tout moment en bas de page.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            4. Cookies tiers
          </h2>
          <p className='text-gray-700'>
            Nous travaillons avec des tiers, comme des fournisseurs de services d&apos;analyse ou
            des réseaux publicitaires, qui peuvent également installer des cookies sur votre
            appareil lorsque vous visitez notre site. Ces cookies sont soumis aux politiques de
            confidentialité des tiers concernés.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            5. Modifications de cette politique
          </h2>
          <p className='text-gray-700'>
            Nous pouvons être amenés à modifier cette politique d&apos;utilisation des cookies de
            temps en temps afin de refléter les changements dans nos pratiques ou pour d&apos;autres
            raisons opérationnelles, légales ou réglementaires. Toute modification sera publiée sur
            cette page, et nous vous encourageons à consulter régulièrement cette page pour être
            informé de la version la plus récente.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            6. Contact
          </h2>
          <p className='text-gray-700'>
            Si vous avez des questions concernant notre utilisation des cookies ou cette politique,
            vous pouvez nous contacter à l&apos;adresse suivante :{' '}
            <a href='mailto:hello@voyo.fr' className='text-blue-500 underline'>
              hello@voyo.fr
            </a>
          </p>
        </section>
      </div>
    </div>
  )
}

export default CookiePolicyPage
