import { ErrorOutline } from '@mui/icons-material'
import React, { useState } from 'react'
import DateSlider from '../../Swiper/DateSlider'
import withAuth from '../../Security/withAuth'
import NosConseilsDialog from './NosConseilsDialog/NosConseilsDialog'
import { FormValuesType } from '../formValueType'
import swal from 'sweetalert'

interface PreferencesVisiteurProps {
  authState: {
    email: string
    firstname: string
    lastname: string
    phoneNumber: string
    createdAt: Date
    updatedAt: Date
    userImg: string
    birthdate: string
    city: string
    isVisiteur: boolean
  }
  authProps: {
    token: string
    isLoggedIn: boolean
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
  }
  affichage: boolean
  setAffichage: React.Dispatch<React.SetStateAction<boolean>>
  formValues: FormValuesType
  setFormValues: React.Dispatch<React.SetStateAction<FormValuesType>>
  setAffichagePreferencesSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

const PreferencesVisiteur: React.FC<PreferencesVisiteurProps> = ({
  authState,
  authProps,
  setAffichage,
  formValues,
  setFormValues,
  setAffichagePreferencesSuccess,
}: PreferencesVisiteurProps) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [amount, setAmount] = useState(0)
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({})
  const [delaiAcceptation, setDelaiAcceptation] = useState('')
  const { token } = authProps

  const handleDialogOpen = () => {
    setOpenDialog(true)
  }

  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  // Handler pour mettre à jour la valeur delaiAcceptation
  const handleDelaiAcceptationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      delaiAcceptation: parseInt(e.target.value, 10),
    }))
    setDelaiAcceptation(e.target.value)
  }

  // Handler pour mettre à jour la valeur preferenceModification
  const handlePreferenceModificationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      preferenceModification: e.target.value,
    }))
  }

  // Handler pour mettre à jour la valeur preferenceAnnulation
  const handlePreferenceAnnulationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      preferenceAnnulation: e.target.value,
    }))
  }

  const handleAmountChange = (e: { target: { value: any } }) => {
    const inputValue = Number(e.target.value)
    if (inputValue >= 0) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        tarif: inputValue,
      }))
    }
  }

  const handleSubmit = async () => {
    try {
      setValidationErrors({})

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })

      const requiredFields = [
        'delaiAcceptation',
        'preferenceAnnulation',
        'preferenceModification',
        'tarif',
      ]

      const newErrors: Record<string, string> = requiredFields.reduce((errors, fieldName) => {
        if (!(formValues as any)[fieldName] || (formValues as any)[fieldName] === '') {
          errors[fieldName] = 'Ce champ est obligatoire'
        }
        return errors
      }, {} as Record<string, string>)

      if (Object.keys(newErrors).length > 0) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          ...newErrors,
        }))
        return
      }

      swal('Good job!', 'On continue!', 'success')
      setAffichage(false)
      setAffichagePreferencesSuccess(true)
    } catch (error) {
      swal('Error', 'An error occurred while submitting the form', 'error')
      console.error(error)
    }
  }

  return (
    <div className='pt-32 pb-10 mx-auto w-7/12 mb-2em'>
      <div className='p-t-15vh mx-auto max-w-55vw mb-2em'>
        <h1 className='text-2xl mb-20px font-bold'>Créez votre profil</h1>
        <h2 className='text-1.6em mt-20px mb-2% font-bold'>Faites-vous connaître</h2>
        <div className='p-5 p-2em rounded-xl mb-5 bg-gray-100 shadow-lg mt-5'>
          <h3 className='text-18px mt-20px'>Vos tarifs</h3>
          {validationErrors.tarif && <p className='text-red-500'>{validationErrors.tarif}</p>}
          <div className='flex mt-5 items-center space-x-2'>
            <div className='flex items-center'>
              <div className='cursor-pointer text-red-500' onClick={handleDialogOpen}>
                <ErrorOutline />
              </div>
            </div>
            <p>Nos conseils</p>
          </div>

          <NosConseilsDialog open={openDialog} onClose={handleDialogClose} />
          <span className='text-0.8em italic block mb-1em'>
            Ce que vous souhaitez que les demandeurs paient par service
          </span>
          <input
            className='rounded-full outline-none pl-3 w-full p-10px mt-1 py-2 px-5 mb-1'
            type='number'
            placeholder={String(formValues.tarif)}
            onChange={handleAmountChange}
          />
          <p className='mt-5'>
            Ce que vous gagnerez par service:{' '}
            <b>{(formValues.tarif * 0.8 > 1 ? formValues.tarif * 0.8 : 0).toFixed(2)} €</b>
          </p>
        </div>
      </div>

      <section className='p-5 bg-gray-100 rounded-xl shadow-lg mt-5 '>
        <h2 className='text-xl font-semibold mb-2'>Vos disponibilités actuelles</h2>
        <span className='text-gray-500 block mb-4'>
          Vous pourrez modifier ultérieurement votre calendrier dans le tableau de bord
        </span>

        <DateSlider token={token} isEditable={true} />

        <h4 className='mt-3 font-semibold'>Vos délais d&apos;acceptation de visite *</h4>
        <span className='text-gray-500 block mb-4'>
          Combien de temps à l&apos;avance le demandeur doit-il vous proposer une visite?
        </span>

        <select
          name='delaie'
          className='w-full p-2 rounded-lg'
          onChange={handleDelaiAcceptationChange}
          value={delaiAcceptation}
        >
          <option>Délai minimum</option>
          <option>1 jour</option>
          <option>3 jours</option>
          <option>7 jours</option>
        </select>
        {validationErrors.delaiAcceptation && (
          <p className='text-red-500'>{validationErrors.delaiAcceptation}</p>
        )}
      </section>

      <section className='p-5 bg-gray-100 rounded-xl shadow-lg mt-5'>
        <h3 className='text-lg font-semibold mb-3'>Vos préférences de modification *</h3>
        <span className='text-gray-500 mb-4 block'>
          Combien de temps à l&apos;avance le demandeur peut-il modifier le moment de votre visite
        </span>
        <div className='space-y-2'>
          <div className='flex items-center'>
            <input
              type='radio'
              name='modification'
              className='mr-2'
              onChange={handlePreferenceModificationChange}
              value='Même jour'
              checked={formValues.preferenceModification === 'Même jour'}
            />
            <label className='text-gray-700'>Même jour</label>
          </div>
          <div className='flex items-center'>
            <input
              type='radio'
              name='modification'
              className='mr-2'
              onChange={handlePreferenceModificationChange}
              value='Un jour'
              checked={formValues.preferenceModification === 'Un jour'}
            />
            <label className='text-gray-700'>Un jour</label>
          </div>
          <div className='flex items-center'>
            <input
              type='radio'
              name='modification'
              className='mr-2'
              onChange={handlePreferenceModificationChange}
              value='Deux jours'
              checked={formValues.preferenceModification === 'Deux jours'}
            />
            <label className='text-gray-700'>Deux jours</label>
          </div>
          <div className='flex items-center'>
            <input
              type='radio'
              name='modification'
              className='mr-2'
              onChange={handlePreferenceModificationChange}
              value='Trois jours'
              checked={formValues.preferenceModification === 'Trois jours'}
            />
            <label className='text-gray-700'>Trois jours</label>
          </div>
          <div className='flex items-center'>
            <input
              type='radio'
              name='modification'
              className='mr-2'
              onChange={handlePreferenceModificationChange}
              value='Une semaine'
              checked={formValues.preferenceModification === 'Une semaine'}
            />
            <label className='text-gray-700'>Une semaine</label>
          </div>
        </div>
        {validationErrors.preferenceModification && (
          <p className='text-red-500'>{validationErrors.preferenceModification}</p>
        )}
      </section>

      <section className='p-5 bg-gray-100 rounded-xl shadow-lg mt-5'>
        <h3 className='text-lg font-semibold mb-3'>Vos préférences d&apos;annulation *</h3>
        <span className='text-gray-500 mb-4 block'>
          Combien de temps à l&apos;avance le demandeur peut-il annuler une visite
        </span>

        <div className='space-y-2'>
          <div className='flex items-center'>
            <input
              type='radio'
              name='annulation'
              className='mr-2'
              onChange={handlePreferenceAnnulationChange}
              value='Même jour'
              checked={formValues.preferenceAnnulation === 'Même jour'}
            />
            <label className='text-gray-700'>Même jour</label>
          </div>
          <div className='flex items-center'>
            <input
              type='radio'
              name='annulation'
              className='mr-2'
              onChange={handlePreferenceAnnulationChange}
              value='Un jour'
              checked={formValues.preferenceAnnulation === 'Un jour'}
            />
            <label className='text-gray-700'>Un jour</label>
          </div>
          <div className='flex items-center'>
            <input
              type='radio'
              name='annulation'
              className='mr-2'
              onChange={handlePreferenceAnnulationChange}
              value='Deux jours'
              checked={formValues.preferenceAnnulation === 'Deux jours'}
            />
            <label className='text-gray-700'>Deux jours</label>
          </div>
          <div className='flex items-center'>
            <input
              type='radio'
              name='annulation'
              className='mr-2'
              onChange={handlePreferenceAnnulationChange}
              value='Trois jours'
              checked={formValues.preferenceAnnulation === 'Trois jours'}
            />
            <label className='text-gray-700'>Trois jours</label>
          </div>
          <div className='flex items-center'>
            <input
              type='radio'
              name='annulation'
              className='mr-2'
              onChange={handlePreferenceAnnulationChange}
              value='Une semaine'
              checked={formValues.preferenceAnnulation === 'Une semaine'}
            />
            <label className='text-gray-700'>Une semaine</label>
          </div>
        </div>
        {validationErrors.preferenceAnnulation && (
          <p className='text-red-500'>{validationErrors.preferenceAnnulation}</p>
        )}
      </section>

      <p className='my-5'>
        Remarque: les visiteurs doivent se conformer à la loi et à la règlementation applicable.
        <a href=''>Conditions d&apos;utilisation</a>
      </p>
      <button className='bg-orange w-fit' onClick={handleSubmit}>
        Valider
      </button>
    </div>
  )
}

export default withAuth(PreferencesVisiteur)
