import React from 'react'
import './ValidationCompte.css'
import withAuth from '../../Security/withAuth'

const ValidationCompte: React.FC = () => {
  return (
    <div className='validationCompte'>
      <div className='validationCompte-content'>
        <h1>Merci de vous être inscrit!</h1>
        <p>
          Un e-mail de confirmation a été envoyé à votre adresse e-mail. Veuillez vérifier votre
          boîte de réception et cliquer sur le lien de confirmation pour activer votre compte.
          <br />
          <br />
          Si vous ne recevez pas l&apos;e-mail de confirmation dans quelques minutes, veuillez
          vérifier votre dossier de courrier indésirable (spam).
        </p>
      </div>
    </div>
  )
}

export default ValidationCompte
