import React from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'

interface NosConseilsDialogProps {
  open: boolean
  onClose: () => void
}

function NosConseilsDialog({ open, onClose }: NosConseilsDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className='text-xl font-bold'>Conseils</DialogTitle>
      <DialogContent className='space-y-4'>
        <ul className='list-disc pl-5'>
          <li>
            Évaluez la valeur de vos services en fonction de votre expertise et de la demande du
            marché.
          </li>
          <li>
            Recherchez les tarifs pratiqués par d&apos;autres professionnels du secteur pour rester
            compétitif.
          </li>
          <li>
            N&apos;hésitez pas à ajuster vos tarifs en fonction de l&apos;évolution de votre
            expérience et de vos compétences.
          </li>
        </ul>
        <p>
          Rappelez-vous, fixer des tarifs justes et compétitifs contribuera à attirer davantage de
          demandeurs et à établir des relations durables.
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NosConseilsDialog
