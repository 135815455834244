import React from 'react'

const DAC7Info = () => {
  return (
    <div className='min-h-screen bg-gray-100 p-6 flex flex-col items-center'>
      <div className='bg-white rounded-lg shadow-lg p-8 max-w-4xl'>
        <h1 className='text-4xl font-bold mb-4 text-center' style={{ color: '#ff5733' }}>
          Directive DAC7 - Transparence Fiscale
        </h1>

        <section className='mb-6'>
          <p className='text-gray-700'>
            La Directive DAC7, émanant de l&apos;Union européenne, représente un changement
            significatif dans la manière dont les plateformes numériques comme Voyo interagissent
            sur le plan fiscal.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            Qu&apos;est-ce que la DAC7 ?
          </h2>
          <p className='text-gray-700'>
            La Directive sur la coopération administrative (DAC7) est une initiative de l&apos;Union
            européenne visant à renforcer la transparence fiscale dans le secteur des plateformes
            numériques. Son objectif principal est de faciliter la coopération administrative entre
            les États membres, afin de lutter plus efficacement contre l&apos;évasion fiscale.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            Implications pour Voyo
          </h2>
          <p className='text-gray-700'>
            En tant que plateforme numérique, Voyo est pleinement engagée dans la conformité à la
            DAC7. Cela signifie que nous partageons activement des informations cruciales avec les
            autorités fiscales pour assurer une transparence totale dans nos opérations.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            Notre Engagement envers la Transparence
          </h2>
          <p className='text-gray-700'>
            Chez Voyo, nous considérons la transparence fiscale comme une partie intégrante de notre
            responsabilité envers nos utilisateurs et la société dans son ensemble. Voici comment
            nous nous engageons à respecter la DAC7 :
          </p>
          <ul className='list-disc list-inside text-gray-700'>
            <li>
              Déclaration Complète : Nous fournissons des déclarations complètes sur nos
              transactions.
            </li>
            <li>Coopération Totale : Nous coopérons pleinement avec les autorités fiscales.</li>
            <li>
              Protection de la Vie Privée : Nous protégeons la vie privée de nos utilisateurs.
            </li>
          </ul>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            Limites de notre Assistance en Matière Fiscale
          </h2>
          <p className='text-gray-700'>
            Bien que nous soyons engagés à respecter les obligations fiscales découlant de la DAC7,
            notre équipe ne peut pas fournir d&apos;assistance pour des questions fiscales
            spécifiques. Nous recommandons vivement à nos utilisateurs de consulter des
            professionnels de la fiscalité pour des conseils personnalisés.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            Ce que cela signifie pour vous
          </h2>
          <p className='text-gray-700'>
            En tant qu&apos;utilisateur de Voyo, vous pouvez être assuré que votre expérience sur
            notre plateforme est conforme aux normes les plus élevées en matière de transparence
            fiscale. Nous croyons que cette conformité renforce la confiance que vous placez en
            nous.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            Questions ou Préoccupations ?
          </h2>
          <p className='text-gray-700'>
            Si vous avez des questions spécifiques sur la manière dont Voyo applique la DAC7 ou des
            préoccupations liées à la confidentialité, n&apos;hésitez pas à nous contacter. Vous
            pouvez également retrouver des informations directement sur les sites de
            l&apos;administration fiscale, ou consulter notre section FAQ.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            Questions Fréquentes (FAQ)
          </h2>
          <a href='/faq-dac7' className='text-blue-500 underline'>
            Accéder à notre FAQ sur la DAC7
          </a>
        </section>
      </div>
    </div>
  )
}

export default DAC7Info
