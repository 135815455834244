import React from 'react'

const DAC7Questions = () => {
  return (
    <div className='min-h-screen bg-gray-100 p-6 flex flex-col items-center'>
      <div className='bg-white rounded-lg shadow-lg p-8 max-w-4xl'>
        <h1 className='text-4xl font-bold mb-4 text-center' style={{ color: '#ff5733' }}>
          Questions Fréquentes (FAQ) - Directive DAC7
        </h1>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            1. Qu’est-ce que la directive DAC7 ?
          </h2>
          <p className='text-gray-700'>
            La directive DAC7 est une réglementation européenne qui impose aux plateformes
            numériques comme Voyo de collecter et de déclarer certains revenus générés par les
            utilisateurs. L&apos;objectif est d&apos;assurer la transparence fiscale en Europe.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            2. Pourquoi suis-je concerné par la DAC7 en utilisant Voyo ?
          </h2>
          <p className='text-gray-700'>
            Si vous utilisez Voyo pour réaliser des visites rémunérées de biens, vous pourriez être
            concerné par la DAC7. La directive oblige Voyo à transmettre aux autorités fiscales les
            informations sur les revenus générés par les utilisateurs de la plateforme.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            3. Est-ce une démarche unique ou une obligation de déclaration continue ?
          </h2>
          <p className='text-gray-700'>
            La déclaration relative à la directive DAC7 est une obligation annuelle. Les
            informations collectées seront transmises chaque mois de janvier pour les revenus et
            données de l&apos;année précédente. Par exemple, les informations relatives à
            l&apos;année 2024 seront déclarées en janvier 2025.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            4. Quelles informations seront collectées et déclarées ?
          </h2>
          <p className='text-gray-700'>
            Voyo doit collecter et transmettre des informations telles que votre nom, adresse,
            identifiant fiscal, ainsi que les montants des revenus générés par vos activités sur la
            plateforme. Ces données seront utilisées pour satisfaire aux exigences de la directive
            DAC7.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            5. Comment Voyo protège mes données personnelles ?
          </h2>
          <p className='text-gray-700'>
            Voyo prend la protection de vos données très au sérieux. Les informations collectées
            pour la DAC7 sont sécurisées et ne seront transmises qu’aux autorités fiscales
            compétentes dans le cadre de la réglementation. Vous pouvez consulter notre{' '}
            <a href='/politique-de-confidentialite' className='text-blue-500 underline'>
              Politique de confidentialité
            </a>
            pour plus de détails.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            6. Que dois-je faire si mes informations personnelles ou fiscales changent ?
          </h2>
          <p className='text-gray-700'>
            Il est important de maintenir vos informations à jour sur Voyo. Vous pouvez modifier vos
            données personnelles directement via votre compte. Si vos informations fiscales
            changent, assurez-vous de les mettre à jour rapidement pour éviter toute non-conformité
            avec la directive DAC7.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            7. Que se passe-t-il si je ne mets pas à jour mes informations ?
          </h2>
          <p className='text-gray-700'>
            Si vos informations ne sont pas à jour ou complètes, cela peut entraîner des difficultés
            lors de la déclaration annuelle ou un risque de non-conformité avec la directive DAC7.
            Voyo vous contactera si des informations sont manquantes ou incorrectes.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            8. Où puis-je en savoir plus sur la DAC7 et mes obligations fiscales ?
          </h2>
          <p className='text-gray-700'>
            Vous pouvez consulter les ressources officielles suivantes pour plus d&apos;informations
            :
            <br />
            <a
              href='https://economie.gouv.fr/obligations-des-plateformes-numeriques'
              className='text-blue-500 underline'
            >
              Obligations des plateformes numériques - economie.gouv.fr
            </a>
            <br />
            <a
              href='https://www.impots.gouv.fr/portail/cooperation-administrative'
              className='text-blue-500 underline'
            >
              Coopération administrative - impots.gouv.fr
            </a>
          </p>
        </section>
      </div>
    </div>
  )
}

export default DAC7Questions
