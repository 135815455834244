import { Close } from '@mui/icons-material'
import React from 'react'
import { FiAlertCircle, FiCheckCircle, FiInfo, FiXCircle } from 'react-icons/fi'
import { IconType } from 'react-icons/lib'

type AlertProps = {
  type: 'success' | 'error' | 'warning' | 'info'
  message: string
  onClose?: () => void
}

const Alert: React.FC<AlertProps> = ({ type, message, onClose }) => {
  const iconMap: Record<string, IconType> = {
    success: FiCheckCircle,
    error: FiXCircle,
    warning: FiAlertCircle,
    info: FiInfo,
  }

  return (
    <div
      className={`${
        type === 'success'
          ? 'bg-green-100 text-green-800'
          : type === 'error'
          ? 'bg-red-100 text-red-800'
          : type === 'warning'
          ? 'bg-yellow-100 text-yellow-800'
          : 'bg-blue-100 text-blue-800'
      } p-4 rounded-md flex items-center mt-10 justify-between`}
      role='alert'
    >
      <div className='flex items-center'>
        <span className='mr-2'>{React.createElement(iconMap[type], { className: 'w-5 h-5' })}</span>
        <span>{message}</span>
      </div>
      {onClose && (
        <button
          className='text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-800 rounded-full p-1'
          onClick={onClose}
        >
          <Close />
        </button>
      )}
    </div>
  )
}

export default Alert
