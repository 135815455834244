import React, { useEffect, useState } from 'react'
import oiseaux from '../../assets/images/oiseaux.png'
import stripeLogo from '../../assets/images/logo-stripe.png'
import voyoRose from '../../assets/images/logo-voyo-rose.png'
import paypal from '../../assets/images/paypal-logo.png'
import separator from '../../assets/images/sep.png'

import { loadStripe, Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js'
import { createPaymentIntent } from '../../api/Paiement'
import swal from 'sweetalert'
import { getVisite, updateVisite } from '../../api/Visites'
import withAuth from '../Security/withAuth'
import { setStatusChat } from '../../api/Chat'

type DataVisite = {
  UserEmail: string
  adresseBien: string
  codePostal: string
  createdAt: Date
  date: Date
  email: string
  heureVisite: string
  id: 1
  informations_complementaires: string
  prix: number
  proprietaire: string
  typeBien: string
  updatedAt: Date
  ville: string
  isValidatedByVisitor: boolean
  connectedAccountID: string
}
interface PaiementProps {
  authProps: {
    token: string
    isLoggedIn: boolean
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
  }
}

const Paiement: React.FC<PaiementProps> = ({ authProps }) => {
  const [chatId, setChatId] = useState(0)
  const [stripe, setStripe] = useState<Stripe | null>(null)
  const [elements, setElements] = useState<StripeElements | null>(null)
  const [card, setCard] = useState<StripeCardElement | null>(null)
  const [disableButton, setDisableButton] = useState(true)
  const [showStripe, setShowStripe] = useState(false)
  const [showPaypal, setShowPaypal] = useState(false)
  const [dataVisite, setDataVisite] = useState<DataVisite>()
  const [clientSecret, setClientSecret] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [total, setTotal] = useState(0)
  const [totalMinFrais, setTotalMinFrais] = useState(0)
  const [frais, setFrais] = useState(0)

  const style = {
    base: {
      color: '#32325d',
      fontFamily: 'Roboto, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#32325d',
      },
    },
    invalid: {
      fontFamily: 'Roboto, sans-serif',
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  }

  const getDataVisite = async (visiteId: number) => {
    if (authProps.token && visiteId) {
      const response = await getVisite(authProps.token, visiteId)
      if (response.success) {
        const connectedAccountID = response?.connectedAccountID
        const prixEnEuros = response.visite.prix

        const TOTAL = Math.round(prixEnEuros * 100)
        // 20% pour visiteur et 15% pour mandant
        const fraisVisiteur = Math.round(TOTAL * 0.2)
        const fraisMandant = Math.round(TOTAL * 0.15)

        const totalForVoyo = fraisVisiteur + fraisMandant
        const totalCostForMandant = TOTAL + fraisMandant

        setTotal(prixEnEuros + fraisMandant / 100)
        setFrais(fraisMandant / 100)
        setTotalMinFrais(prixEnEuros)

        const { clientSecret, success, message } = await createPaymentIntent(
          authProps?.token,
          totalCostForMandant,
          connectedAccountID,
          totalForVoyo,
        )

        if (success) {
          setClientSecret(clientSecret)
          setDataVisite({ ...response.visite, connectedAccountID: response.connectedAccountID })
          setFirstLoad(false)
        } else {
          setError(message)
          setFirstLoad(false)
        }
      }
    }
  }
  const initializeStripe = async () => {
    if (process.env.REACT_APP_STRIPE) {
      const stripeInstance = await loadStripe(process.env.REACT_APP_STRIPE)
      setStripe(stripeInstance)
    }
  }

  const gotoLastPage = () => {
    const lastVisitedPage = document.referrer

    if (!lastVisitedPage) window.location.href = '/'
    if (lastVisitedPage === window.location.href) {
      window.location.href = '/'
    } else {
      window.location.href = lastVisitedPage
    }
  }

  const handlePayment = async () => {
    if (stripe && card && dataVisite && clientSecret)
      try {
        setIsLoading(true)
        const { token, error } = await stripe.createToken(card)

        let result
        if (error?.message) {
          console.error('Erreur lors de la création du token :', error)
          setIsLoading(false)
          setError('Erreur lors du paiement:' + error.message)
        } else {
          if (token && token?.card?.id !== undefined) {
            result = await stripe.confirmCardPayment(clientSecret, {
              payment_method: {
                card: { token: token?.id },
              },
            })
          } else {
            console.error('Le token ou token.card nest pas défini.')
          }
        }
        if (result) {
          if (result?.error) {
            if (result.error.message) setError(result.error.message)
            console.error(result.error.message)
          } else {
            setIsLoading(false)
            // Paiement réussi

            if (chatId) await setStatusChat(authProps.token, chatId, 2)
            if (dataVisite) {
              const newValues = { ...dataVisite, paymentIntentId: result?.paymentIntent.id }
              await updateVisite(authProps.token, dataVisite.id, newValues)
            }
            swal({
              title: 'Good job!',
              text: 'Paiement effectué !',
              icon: 'success',
            }).then((value) => {
              if (value) {
                window.location.href = '/dashboard?valid=true'
              }
            })
          }
        }
      } catch (error) {
        setIsLoading(false)
        setError('Erreur lors du paiement:' + error)
        console.error('Erreur lors du paiement:', error)
      }
  }

  useEffect(() => {
    initializeStripe()
  }, [])

  useEffect(() => {
    if (stripe) {
      const elementsInstance = stripe.elements()
      setElements(elementsInstance)

      if (elementsInstance) {
        const cardElement = elementsInstance.create('card', { style, hidePostalCode: true })
        setCard(cardElement)
      }
    }
  }, [stripe])

  useEffect(() => {
    const url = window.location.href
    const urlObj = new URL(url)
    const id = urlObj.searchParams.get('id')
    if (id) {
      setChatId(Number(id))
      getDataVisite(Number(id))
    } else window.location.href = '/'
  }, [authProps.token, stripe, elements])

  useEffect(() => {
    if (card && showStripe) {
      card.mount('#card-element')
      card.on('change', (event) => {
        setDisableButton(!(event.complete && !event.error))
      })
    }
  }, [card, showStripe])

  return firstLoad ? (
    <div className='fixed top-0 left-0 h-screen w-screen flex justify-center items-center'>
      <div className='border-t-2 border-b border-b-red-100 border-solid rounded-full animate-spin w-20 h-20 border-t-orange-400'></div>
    </div>
  ) : (
    <div className='flex flex-col justify-between w-screen h-screen '>
      <a href='/' className='flex justify-start'>
        <img src={voyoRose} alt='voyoRose' className='w-[70px] m-3' />
      </a>
      <div className='flex justify-center  w-full '>
        <div className='flex mx-1  md:mx-5 w-full  gap-2 md:gap-20 h-[600px] z-10 '>
          {/* left */}
          <div className=' mx-2 gap-5 w-[65%]'>
            <div className='flex flex-col gap-5 max-w-[700px]'>
              <div className='flex flex-col gap-10 '>
                <div>
                  <h1 className='text-gray-600 font-semibold'>Paiement</h1>
                  <p className='text-[#ffe2ce] -mt-3 '>
                    Toutes les transactions sont sécurisées et chiffrées
                  </p>
                </div>

                <div className='flex flex-col gap-2 bg-[#ffe2ce] border border-gray-300 py-3 px-2 rounded-lg'>
                  {/* Paypal */}
                  <div className='flex gap-2 items-center'>
                    <img src={paypal} alt='stripeLogo' className='sm:w-[250px] w-[150px]' />

                    <input
                      className='mt-4'
                      type='checkbox'
                      id='toggleStripe'
                      onChange={(e) => {
                        if (e.isTrusted) setShowStripe(false)

                        setShowPaypal((prevStripe) => !prevStripe)
                      }}
                      checked={showPaypal}
                    />
                  </div>
                  <div className='flex justify-center items-center'>
                    <img src={separator} alt='separator' className='w' />
                  </div>

                  {/* Stripe */}
                  <div className='flex flex-col gap-3'>
                    <div className='flex gap-2 items-center'>
                      <img src={stripeLogo} alt='stripeLogo' className='sm:w-[250px] w-[150px]' />

                      <input
                        className='mt-4'
                        type='checkbox'
                        id='toggleStripe'
                        onChange={(e) => {
                          if (e.isTrusted) setShowPaypal(false)
                          setShowStripe((prevShowStripe) => !prevShowStripe)
                        }}
                        checked={showStripe}
                      />
                    </div>
                    {showStripe && (
                      <>
                        <div
                          id='card-element'
                          className='w-full h-9 px-3 py-2 rounded-lg bg-white border-gray-300'
                        ></div>
                        {error && <div className='text-red-500 text-center'>{error}</div>}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='flex justify-end'>
                <button
                  onClick={handlePayment}
                  className='px-7 py-1 font-medium  rounded-lg border bg-gray-600 bg-opacity-80   text-white'
                >
                  {isLoading ? (
                    <div className='border-t-2 border-b border-b-red-100 border-solid rounded-full animate-spin w-6 h-6 border-t-orange-400'></div>
                  ) : (
                    'Payer'
                  )}
                </button>
              </div>
            </div>
          </div>

          {/* right */}

          <div className='bg-[#ffe2ce] min-h-[730px] h-screen w-[35%] absolute right-0 top-0 bottom-0 bg-opacity-90 '></div>

          <div className='flex flex-col gap-10 z-10 mx-2  w-[35%]   '>
            <div className='flex justify-between items-center'>
              <h2 className='text-gray-600 font-medium sm:text-4xl text-xl '>VISITE</h2>
              <span className='text-gray-600 font-semibold  sm:text-3xl text-xl '>
                {dataVisite ? totalMinFrais : ''}€
              </span>
            </div>

            <div className='flex justify-center items-center'>
              <img src={separator} alt='separator' className='w' />
            </div>

            <div className='flex flex-col items-center gap-2 md:flex-row justify-between text-gray-600 font-semibold'>
              {' '}
              <input
                className='w-full max-w-[220px] p-1 py-2 rounded-sm border border-gray-200'
                type='text'
                placeholder='Code promo'
              />
              <button className='px-5 py-1 w-full max-w-[150px]  sm:h-8 font-medium   rounded-lg border bg-gray-600 bg-opacity-80   text-white'>
                Appliquer
              </button>
            </div>

            <div className='flex justify-center items-center'>
              <img src={separator} alt='separator' className='w' />
            </div>

            <div>
              <p className='flex justify-between text-gray-600 font-semibold sm:text-2xl text-xl '>
                Sous-total{' '}
                <span className='text-gray-600 font-semibold  sm:text-3xl text-xl'>
                  {dataVisite?.prix ? totalMinFrais : ''}€
                </span>
              </p>
              <p className='flex justify-between text-gray-600 font-semibold sm:text-2xl text-xl '>
                Frais{' '}
                <span className='text-gray-600 font-semibold  sm:text-3xl text-xl'>
                  {dataVisite ? frais : ''}€
                </span>
              </p>
            </div>

            <div className='flex justify-center items-center'>
              <img src={separator} alt='separator' className='w' />
            </div>
            <div>
              <p className='flex justify-between text-gray-600 font-semibold sm:text-2xl text-xl '>
                Total{' '}
                <span className='text-gray-600 font-semibold  sm:text-3xl text-xl'>
                  {dataVisite ? total : ''}€
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <img src={oiseaux} alt='' className='absolute bottom-0 right-0 -z-10' />

      {/* return */}
      <div className='flex justify-start'>
        {' '}
        <button
          onClick={gotoLastPage}
          className='px-2 py-1 rounded-lg font-medium  bg-gray-600 bg-opacity-80 mb-3 ml-5  text-white'
        >
          Retour
        </button>
      </div>
    </div>
  )
}

export default withAuth(Paiement)
