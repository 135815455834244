import React from 'react'

const AboutPage = () => {
  return (
    <div className='min-h-screen bg-gray-100 p-6 flex flex-col items-center'>
      <div className='bg-white rounded-lg shadow-lg p-8 max-w-4xl'>
        <h1 className='text-4xl font-bold mb-4 text-center' style={{ color: '#ff5733' }}>
          Qui suis-je ?
        </h1>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold text-gray-800 mb-2'>Présentation de Voyo</h2>
          <p className='text-gray-700'>
            Voyo est une entreprise française émergente. La mission de Voyo est de fournir un
            service de mise en relation permettant de faciliter la délégation des visites de biens à
            louer.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold text-gray-800 mb-2'>Notre Histoire</h2>
          <p className='text-gray-700'>
            En tant qu’étudiants, nous avons été confrontés à la complexité du processus de
            déménagement. Trouver un logement à louer devient souvent un défi majeur lorsque la
            nouvelle destination est éloignée de notre lieu de résidence actuel ou située dans une
            zone à forte demande. Cette expérience nous a poussé à réfléchir à des solutions
            innovantes pour résoudre cette problématique, donnant ainsi naissance à Voyo.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold text-gray-800 mb-2'>Notre Mission</h2>
          <p className='text-gray-700'>
            Voyo s&apos;efforce de fournir des solutions pratiques et abordables aux particuliers,
            leur permettant de déménager en toute tranquillité. Je comprends que les visites de
            logements peuvent être chronophages et coûteuses, et qu&apos;elles peuvent même
            entraîner la perte d&apos;opportunités dans des zones très demandées. Je suis consciente
            des contraintes personnelles qui peuvent empêcher ces déplacements.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold text-gray-800 mb-2'>Nos Engagements</h2>
          <p className='text-gray-700'>
            Les fonds versés par le mandant ne sont versés au visiteur que lorsque le service a été
            pleinement réalisé. Cela inclut la capture d&apos;images, de vidéos et le remplissage
            des fiches requises, en plus de la validation de la prise en compte de vos critères.
            Votre satisfaction est au cœur de notre démarche.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold text-gray-800 mb-2'>Devenir Visiteur</h2>
          <p className='text-gray-700'>
            Si vous envisagez de devenir visiteur sur Voyo, nous serons ravis de vous accueillir.
            Cette opportunité peut vous permettre de compléter vos revenus. J’encourage une
            utilisation responsable de cette plateforme.
          </p>
        </section>
      </div>
    </div>
  )
}

export default AboutPage
