export function convertDate(oldDate: Date) {
  const date = new Date(oldDate)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = String(date.getFullYear())
  const formattedDate = `${day}/${month}/${year}`
  return formattedDate
}

export const getMonthName = (monthNumber: number) => {
  const date = new Date()
  date.setMonth(monthNumber - 1)
  return date.toLocaleString('eu-EU', { month: 'short' })
}

export const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+.[^\s@]+$/
  return emailRegex.test(email)
}

export const validatePhoneNumber = (phone: string) => {
  const phoneRegex = /^[0-9]{10}$/
  return phoneRegex.test(phone)
}

export const validatePassword = (password: string) => {
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!?,+:/;]).{8,}$/
  return passwordRegex.test(password)
}

export const listeProchains15Jours = (dateReelle: Date) => {
  const prochains15Jours = []
  const date = new Date(dateReelle)

  // Options de formatage pour la date en français avec jours et mois en majuscules
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    localeMatcher: 'best fit',
  }
  const dateFormatter = new Intl.DateTimeFormat('fr-FR', options)

  // Boucle pour ajouter les 15 prochains jours
  for (let i = 0; i < 15; i++) {
    date.setDate(date.getDate() + 1) // Incrémente la date d'un jour
    const dateFormatee = dateFormatter.format(new Date(date)) // Formatage en français avec 3 lettres en majuscules

    const dateObj = {
      day: dateFormatee.slice(0, 3), // Les 3 premières lettres du jour en majuscules
      number: date.getDate(),
      month: dateFormatee.split(' ')[2].slice(0, -1), // Les 3 dernières lettres du mois en majuscules
      date: date.toLocaleDateString(),
    }
    prochains15Jours.push(dateObj) // Ajoute l'objet de date à la liste
  }

  return prochains15Jours
}

export const timeAgo = (dateString: string) => {
  const date = new Date(dateString)
  const now = new Date()
  const timeDiff = now.getTime() - date.getTime()

  const seconds = Math.floor(timeDiff / 1000)
  const minutes = Math.floor(timeDiff / (1000 * 60))
  const hours = Math.floor(timeDiff / (1000 * 60 * 60))
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
  const weeks = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7))

  if (seconds < 60) {
    return `${seconds} ${seconds === 1 ? 'seconde' : 'secondes'}`
  } else if (minutes < 60) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
  } else if (hours < 24) {
    return `${hours} ${hours === 1 ? 'heure' : 'heures'}`
  } else if (days < 7) {
    return `${days} ${days === 1 ? 'jour' : 'jours'}`
  } else {
    return `${weeks} ${weeks === 1 ? 'semaine' : 'semaines'} depuis`
  }
}

export const calculateTimeAgo = (commentDate: string | number | Date) => {
  const currentDate: Date = new Date()
  const commentDateObj: Date = new Date(commentDate)
  const timeDifference: number = currentDate.getTime() - commentDateObj.getTime()

  // Convertir la différence en secondes
  const secondsAgo = Math.floor(timeDifference / 1000)

  if (secondsAgo < 60) {
    return `Il y a ${secondsAgo} seconde${secondsAgo !== 1 ? 's' : ''}`
  } else {
    const minutesAgo = Math.floor(secondsAgo / 60)
    if (minutesAgo < 60) {
      return `Il y a ${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''}`
    } else {
      const hoursAgo = Math.floor(minutesAgo / 60)
      if (hoursAgo < 24) {
        return `Il y a ${hoursAgo} heure${hoursAgo !== 1 ? 's' : ''}`
      } else {
        const daysAgo = Math.floor(hoursAgo / 24)
        if (daysAgo < 7) {
          return `Il y a ${daysAgo} jour${daysAgo !== 1 ? 's' : ''}`
        } else {
          const weeksAgo = Math.floor(daysAgo / 7)
          if (weeksAgo < 4) {
            return `Il y a ${weeksAgo} semaine${weeksAgo !== 1 ? 's' : ''}`
          } else {
            const monthsAgo = Math.floor(daysAgo / 30)
            if (monthsAgo < 12) {
              return `Il y a ${monthsAgo} mois${monthsAgo !== 1 ? 's' : ''}`
            } else {
              const yearsAgo = Math.floor(daysAgo / 365)
              return `Il y a ${yearsAgo} an${yearsAgo !== 1 ? 's' : ''}`
            }
          }
        }
      }
    }
  }
}
