import React from 'react'

const HowItWorksPage = () => {
  return (
    <div className='min-h-screen bg-gray-100 p-6 flex flex-col items-center'>
      <div className='bg-white rounded-lg shadow-lg p-8 max-w-4xl'>
        <h1 className='text-4xl font-bold mb-6 text-center' style={{ color: '#ff5733' }}>
          Découvrez le Fonctionnement de Voyo
        </h1>

        <p className='text-gray-700 mb-6'>
          Bienvenue sur Voyo, votre allié pour simplifier la recherche de logement. Découvrez
          comment notre plateforme facilite la délégation de visites de biens à louer. Suivez le
          guide pour explorer notre processus en quelques étapes clés :
        </p>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            1. Inscrivez-vous Facilement
          </h2>
          <p className='text-gray-700'>
            Commençons par la première étape : créez votre compte sur Voyo en quelques clics. Il
            vous suffit de fournir quelques informations de base : nom, adresse e-mail, et vous
            voilà prêt à explorer notre univers.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            2. Demandeurs et Visiteurs
          </h2>
          <p className='text-gray-700'>
            Chez Voyo, nous mettons en relation deux types d&apos;utilisateurs : les mandants et les
            visiteurs.
          </p>
          <ul className='list-disc list-inside text-gray-700'>
            <li>
              <strong>Mandants</strong> : Ce sont ceux qui cherchent à louer un bien immobilier. Ils
              commencent par définir les bases de leur demande, comme la localisation, la date, et
              l&apos;heure.
            </li>
            <li>
              <strong>Visiteurs</strong> : Les visiteurs sont prêts à effectuer des visites de biens
              en tant que délégués des demandeurs. Les demandeurs sélectionnent ensuite le visiteur
              qui correspond le mieux à leurs attentes.
            </li>
          </ul>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            3. Définissez Votre Demande de Visite
          </h2>
          <p className='text-gray-700'>
            En tant que mandant, vous commencez par définir les bases de votre demande. Indiquez la
            localisation du bien, la date et l&apos;heure de la visite.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            4. Découvrez les Profils des Visiteurs
          </h2>
          <p className='text-gray-700'>
            Une fois votre demande de visite définie, vous découvrez les profils des visiteurs
            disponibles. Ils ont hâte de vous aider à trouver le logement idéal.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            5. Choisissez Votre Visiteur Idéal
          </h2>
          <p className='text-gray-700'>
            Explorez les profils des visiteurs, leurs compétences, leurs disponibilités, et
            choisissez celui qui correspond le mieux à vos attentes.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            6. Proposez une Visite au Visiteur Sélectionné
          </h2>
          <p className='text-gray-700'>
            Après avoir sélectionné le visiteur idéal, proposez-lui la visite. C&apos;est maintenant
            à vous de jouer !
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            7. Option d&apos;Urgence pour les Zones Tendues
          </h2>
          <p className='text-gray-700'>
            Dans les zones tendues, comme Paris, où les biens à louer partent rapidement, nous
            offrons une fonctionnalité d&apos;urgence. Les visiteurs peuvent signaler leur
            disponibilité immédiate et leur capacité à visiter le bien dans la journée, ce qui vous
            permet de réagir rapidement aux opportunités.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            8. Confirmation de la Visite
          </h2>
          <p className='text-gray-700'>
            Une fois que le visiteur a accepté votre proposition de visite, la visite est confirmée
            et planifiée en fonction de la disponibilité du visiteur et de vos préférences.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            9. La Visite en Action
          </h2>
          <p className='text-gray-700'>
            Le visiteur effectue la visite du bien conformément à la date et à l&apos;heure
            convenues. Il recueille des informations détaillées, prend des photos, et, si
            nécessaire, des vidéos pour vous offrir un compte rendu complet.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            10. Le Compte Rendu Détaillé
          </h2>
          <p className='text-gray-700'>
            Après la visite, le visiteur télécharge un compte rendu détaillé. Vous êtes
            instantanément notifié lorsque ce précieux document est prêt.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            11. Votre Approbation et le Paiement du Visiteur
          </h2>
          <p className='text-gray-700'>
            Vous avez maintenant la possibilité d&apos;approuver le compte rendu du visiteur et de
            libérer le paiement selon les termes convenus si le service a été à la hauteur de vos
            attentes.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            12. Remboursement en Cas de Non-Satisfaction
          </h2>
          <p className='text-gray-700'>
            Si la visite n&apos;a pas été satisfaisante ou conforme aux termes établis, vous pouvez
            demander un remboursement partiel ou complet, en accord avec notre politique de
            remboursement.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-4' style={{ color: '#ff5733' }}>
            13. Devenir Visiteur
          </h2>
          <p className='text-gray-700'>
            Si vous envisagez de devenir visiteur sur notre plateforme, nous vous réservons un
            accueil chaleureux. Nous prônons une utilisation responsable de notre plateforme. Vous
            pouvez retrouver le fonctionnement des visiteurs directement sur notre site.
          </p>
        </section>

        <p className='text-gray-700'>
          Chez Voyo, notre mission est de simplifier le processus de recherche de logement en vous
          permettant de déléguer les visites de biens. Nous nous engageons à garantir la
          transparence, la sécurité, et votre satisfaction tout au long de ce processus.
        </p>
        <p className='text-gray-700 mt-4'>
          Si vous avez des questions supplémentaires sur le fonctionnement du site ou si vous avez
          besoin d&apos;assistance, n&apos;hésitez pas à nous contacter. Bonne recherche de logement
          !
        </p>
      </div>
    </div>
  )
}

export default HowItWorksPage
