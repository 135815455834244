import Axios from 'axios'
import { updateProfileInfo } from './Profile'

const SERVER_APP_DOMAIN = process.env.REACT_APP_SERVER_APP_DOMAIN

export const getInfoUser = async (token: string) => {
  try {
    const response = await Axios.get(`${SERVER_APP_DOMAIN}api/users/user-info`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data // Supposons que les informations de l'utilisateur sont dans response.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

export const updateAccount = async (token: string, data: object) => {
  try {
    const updateUser = await Axios.put(`${SERVER_APP_DOMAIN}api/users/update-account`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return updateUser.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}


export const deleteVisiteur = async (token: string, data: object) => {
  try {
    const updateUser = await Axios.put(`${SERVER_APP_DOMAIN}api/users/update-account`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return updateUser.data
  } catch (error) {
    console.error('Erreur lors de la requête :', error)
    throw error
  }
}

