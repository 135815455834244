import React, { useState, useEffect, useRef } from 'react'
import { createMessage } from '../../../api/Messagerie'
import { EmojiClickData } from 'emoji-picker-react'
import { createNotifications } from '../../../api/Notifications'

type Message = {
  chatId: number
  content: string
  createdAt: Date
  id: number
  updatedAt: Date
  userEmail: string
}

interface ChatFooterProps {
  socket: any
  chatId: number | undefined
  token: string
  authState: {
    email: string
    firstname: string
    lastname: string
    phoneNumber: string
    createdAt: Date
    updatedAt: Date
    userImg: string
    birthdate: string
    city: string
    isVisiteur: boolean
  }
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
  messages: Message[]
  otherUserEmail: string | undefined
}

const ChatFooter: React.FC<ChatFooterProps> = ({
  socket,
  chatId,
  setMessages,
  messages,
  token,
  authState,
  otherUserEmail,
}) => {
  const [messageTemp, setMessageTemp] = useState<string>('')
  const [selectedEmoji, setSelectedEmoji] = useState<EmojiClickData | null>() // État pour l'emoji sélectionné
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const [typingTimer, setTypingTimer] = useState<NodeJS.Timeout | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleTyping = () => {
    // Clear the existing typing timer if it exists
    if (typingTimer) {
      clearTimeout(typingTimer)
    }

    // Set a new typing timer with a delay of, for example, 1000 milliseconds (1 second)
    const newTypingTimer = setTimeout(() => {
      // Vous pouvez émettre un événement 'stopped typing' ici si nécessaire.
    }, 1000)

    setTypingTimer(newTypingTimer)
  }

  const handleSendMessage = async () => {
    if (chatId && token) {
      try {
        await createMessage(token, chatId, messageTemp)

        const message: Message = {
          id: messages.length + 1,
          chatId: chatId,
          content: messageTemp,
          createdAt: new Date(Date.now()),
          userEmail: authState.email,
          updatedAt: new Date(Date.now()),
        }
        setMessages([...messages, message])
      } catch (error) {
        console.error('Erreur lors de la création du message :', error)
      }
    }

    if (messageTemp.trim()) {
      if (authState.firstname) {
        socket.emit('message', {
          content: messageTemp,
          name: authState.firstname,
          userEmail: authState.email,
          id: `${socket.id}${Math.random()}`,
          socketID: socket.id,
        })

        const data = {
          type: 'message',
          content: messageTemp,
          userImg: authState.userImg,
          firstName: authState.firstname,
          lastName: authState.lastname,
          chatId,
        }
        if (otherUserEmail)
          await createNotifications(token, authState.firstname, [otherUserEmail], data)
      }
    }
    setMessageTemp('')
  }

  const handleEmojiSelect = (emoji: EmojiClickData, event: MouseEvent) => {
    const emojiString = emoji.emoji // Obtenez la chaîne de caractères de l'emoji
    setSelectedEmoji(emoji)
    setMessageTemp((prevMessage) => prevMessage + emojiString)
  }

  const handleFileUpload = (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0]
    }
  }

  useEffect(() => {
    return () => {
      if (typingTimer) {
        clearTimeout(typingTimer)
      }
    }
  }, [typingTimer])

  const handleToggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker)
  }

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  return (
    <div className='chat__footer'>
      <div className='chat__footer_form'>
        <div>
          <input
            type='text'
            placeholder='Votre message ...'
            className='message mb-5'
            value={messageTemp}
            onChange={(e) => setMessageTemp(e.target.value)}
            onFocus={() => showEmojiPicker && setShowEmojiPicker(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage()
              } else {
                handleTyping()
              }
            }}
          />
          {/* <button className='emojiBtn' onClick={handleToggleEmojiPicker}>
            😃
          </button> */}
          {/* {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiSelect} />} */}
          {/* <Send onClick={handleFileButtonClick} /> */}
          {/* <input
            type='file'
            accept='image/*'
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={(e) => handleFileUpload(e.target.files)}
          /> */}
        </div>
        <button
          className='sendBtn'
          onClick={() => {
            showEmojiPicker && setShowEmojiPicker(false)
            handleSendMessage()
          }}
        >
          Envoyer
        </button>
      </div>
    </div>
  )
}

export default ChatFooter
