import React from 'react'

const PrivacyPolicyPage = () => {
  return (
    <div className='min-h-screen bg-gray-100 p-6 flex flex-col items-center'>
      <div className='bg-white rounded-lg shadow-lg p-8 max-w-4xl'>
        <h1 className='text-4xl font-bold mb-4 text-center' style={{ color: '#ff5733' }}>
          Politique de confidentialité
        </h1>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            1. DÉFINITIONS
          </h2>
          <p className='text-gray-700'>
            <strong>L&apos;éditeur :</strong> La personne, physique ou morale, qui édite les
            services de communication au public en ligne. <br />
            <strong>Voyo :</strong> L&apos;ensemble des sites, pages Internet et services en ligne
            proposés par l&apos;Éditeur. <br />
            <strong>Visiteur :</strong> La personne proposant ses services contre rémunération.{' '}
            <br />
            <strong>Le mandant :</strong> La personne utilisant Voyo dans le but de déléguer une
            visite contre rémunération ainsi que le visiteur.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            2. INFORMATIONS PERSONNELLES QUE NOUS COLLECTONS
          </h2>
          <h3 className='text-xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            2.1 Informations nécessaires pour utiliser la plate-forme Voyo
          </h3>
          <p className='text-gray-700 mb-4'>
            <strong>Quelles informations collectons-nous ?</strong>
          </p>
          <ul className='list-disc list-inside text-gray-700 space-y-2'>
            <li>
              <strong>Création de Compte :</strong> Lorsque vous souhaitez déléguer la visite d’un
              bien, vous devez nous communiquer votre nom et prénom, votre adresse électronique.
              Vous pouvez également choisir de nous communiquer les informations suivantes : Ville,
              Date de naissance, Numéro de téléphone. <br />
              Lorsque vous créez votre profil “visiteur”, vous devez nous communiquer votre nom,
              prénom, ville, date de naissance, adresse électronique, numéro de téléphone portable
              ainsi que votre numéro fiscal. Une vérification de votre identité sera également
              demandée à l&apos;aide de Stripe.
            </li>
            <li>
              <strong>Vos recherches :</strong> Dans le but de vous proposer des profils pertinents,
              lorsque vous recherchez un visiteur, nous collectons les informations suivantes : Zone
              géographique de recherche, La date.
            </li>
          </ul>
          <p className='text-gray-700'>
            <strong>Inscription et identification préalable pour la fourniture du service :</strong>
            L&apos;utilisation du site nécessite une inscription et une identification préalable.
            Vos données nominatives (nom, prénom, email, numéro de téléphone, numéro fiscal...) sont
            utilisées pour exécuter nos obligations légales résultant des services, en vertu des
            conditions applicables. Vous ne fournirez pas de fausses informations nominatives et ne
            créerez pas de compte pour une autre personne sans son autorisation. Vos coordonnées
            devront toujours être exactes et à jour.
          </p>
        </section>

        <section className='mb-6'>
          <h3 className='text-xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            Identifiants et mots de passe
          </h3>
          <p className='text-gray-700'>
            Nous recueillons des mots de passe, des indices de mot de passe et des informations de
            sécurité similaires permettant l’authentification et l&apos;accès au compte. Nous
            n&apos;avons pas accès à ces informations puisqu&apos;ils sont chiffrés.
          </p>
          <h3 className='text-xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            Données de paiement
          </h3>
          <p className='text-gray-700'>
            Notre site utilise Stripe comme prestataire de service de paiement. Lorsque vous
            effectuez un paiement sur notre plateforme, certaines données nécessaires au traitement
            de la transaction (comme votre nom, adresse de facturation, coordonnées bancaires, etc.)
            sont transmises à Stripe. Stripe agit en tant que responsable du traitement des données
            conformément à sa propre politique de confidentialité. Nous vous recommandons de
            consulter la politique de confidentialité de Stripe pour plus de détails sur la manière
            dont vos données sont traitées. Nous ne stockons ni n&apos;avons accès à vos
            informations de paiement telles que les numéros de carte de crédit ou de compte
            bancaire. Ces informations sont traitées directement par Stripe.
          </p>
        </section>

        <section className='mb-6'>
          <h3 className='text-xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            Dans quels buts ?
          </h3>
          <ul className='list-disc list-inside text-gray-700 space-y-2'>
            <li>
              Pour gérer les comptes utilisateurs et vous permettre d&apos;accéder à notre
              plateforme.
            </li>
            <li>Pour traiter et gérer vos réservations.</li>
            <li>
              Pour répondre aux demandes et réclamations des utilisateurs et améliorer nos Services.
            </li>
            <li>
              Pour protéger nos Services, par exemple, par la surveillance et la prévention de la
              fraude.
            </li>
            <li>Pour vous demander un retour d&apos;expérience.</li>
            <li>Répondre aux exigences fiscales.</li>
          </ul>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            3. A qui transmettons-nous vos données ?
          </h2>
          <p className='text-gray-700'>
            <strong>Choix du visiteur :</strong> Lorsque vous choisissez un visiteur ou lui faites
            une demande d&apos;information complémentaire, nous transmettons à celui-ci les
            coordonnées de contact de votre compte (Prénom).
            <br />
            <strong>Vous souhaitez devenir visiteur :</strong> Lorsque vous êtes un visiteur, nous
            transmettons des données personnelles aux utilisateurs de la plateforme (Prénom).
            <br />
            <strong>Autorités compétentes :</strong> Nous pouvons être tenus de divulguer ou de
            partager vos données personnelles avec les autorités compétentes afin de nous conformer
            à une obligation légale, à une demande d&apos;un tribunal ou de la police ou afin de
            protéger nos droits ou ceux de nos clients ou de tiers.
            <br />
            <strong>Transferts d&apos;entreprise :</strong> Nous pouvons partager ou transférer vos
            données dans le cadre ou pendant les négociations de toute fusion, vente d&apos;actifs
            de la société, financement ou acquisition de tout ou partie de nos activités à une autre
            société.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            4. UTILISATION DE NOTRE MESSAGERIE INSTANTANÉE
          </h2>
          <p className='text-gray-700'>
            Dans le cadre de notre Messagerie intégrée, nous utilisons une technologie
            d&apos;analyse automatique du contenu des messages, afin de détecter des contenus non
            sollicités, y compris frauduleux, ou des programmes informatiques malveillants.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            5. COLLECTE DES DONNÉES DU TERMINAL
          </h2>
          <p className='text-gray-700'>
            Aucune collecte des données techniques : Nous ne collectons et ne conservons aucune
            donnée technique de votre appareil (adresse IP, fournisseur d&apos;accès à Internet...).
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            6. DURÉE CONSERVATION DES COOKIES
          </h2>
          <p className='text-gray-700'>
            Conformément aux recommandations de la CNIL, la durée maximale de conservation des
            cookies est de 13 mois au maximum après leur premier dépôt dans le terminal de
            l&apos;utilisateur, tout comme la durée de la validité du consentement de
            l&apos;utilisateur à l&apos;utilisation de ces cookies. La durée de vie des cookies
            n&apos;est pas prolongée à chaque visite.
            <br />
            Le consentement de l&apos;Utilisateur devra donc être renouvelé à l&apos;issue de ce
            délai.
            <br />
            <strong>Finalité cookies :</strong> Les cookies peuvent être utilisés pour des fins
            statistiques notamment pour optimiser les services rendus à l&apos;Utilisateur, à partir
            du traitement des informations concernant la fréquence d&apos;accès, la personnalisation
            des pages ainsi que les opérations réalisées et les informations consultées. Vous êtes
            informé que l&apos;éditeur est susceptible de déposer des cookies sur votre terminal. Le
            cookie enregistre des informations relatives à la navigation sur le service (les pages
            que vous avez consultées, la date et l&apos;heure de la consultation...) que nous
            pourrons lire lors de vos visites ultérieures.
          </p>
          <p className='text-gray-700'>
            <strong>Droit de l&apos;Utilisateur de refuser les cookies :</strong> Vous reconnaissez
            avoir été informé que l&apos;Éditeur peut avoir recours à des cookies. Si vous ne
            souhaitez pas que des cookies soient utilisés sur votre terminal, la plupart des
            navigateurs vous permettent de désactiver les cookies en passant par les options de
            réglage.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            7. DÉLAI DE CONSERVATION DES DONNÉES PERSONNELLES ET D&apos;ANONYMISATION
          </h2>
          <p className='text-gray-700'>
            <strong>
              Conservation des données pendant la durée de la relation contractuelle :
            </strong>
            Conformément à l&apos;article 6-5° de la loi n°78-17 du 6 janvier 1978 relative à
            l&apos; informatique, aux fichiers et aux libertés, les données à caractère personnel
            faisant l&apos; objet d&apos;un traitement ne sont pas conservées au-delà du temps
            nécessaire à l&apos;exécution des obligations définies lors de la conclusion du contrat
            ou de la durée prédéfinie de la relation contractuelle.
            <br />
            <strong>
              Conservation des données anonymisées au-delà de la relation contractuelle / après la
              suppression du compte :
            </strong>{' '}
            Nous conservons les données personnelles pour la durée strictement nécessaire à la
            réalisation des finalités décrites dans les présentes Politiques de confidentialité.
            Au-delà de cette durée, elles seront anonymisées et conservées à des fins exclusivement
            statistiques et ne donneront lieu à aucune exploitation, de quelque nature que ce soit.
            <br />
            <strong>Suppression des données après suppression du compte :</strong> Des moyens de
            purge de données sont mis en place afin d&apos;en prévoir la suppression effective dès
            lors que la durée de conservation ou d&apos;archivage nécessaire à
            l&apos;accomplissement des finalités déterminées ou imposées est atteinte. Conformément
            à la loi n°78-17 du 6 janvier 1978 relative à l&apos;informatique, aux fichiers et aux
            libertés, vous disposez par ailleurs d&apos;un droit de suppression sur vos données que
            vous pouvez exercer à tout moment en prenant contact avec l&apos;Éditeur.
            <br />
            <strong>Suppression des données après 3 ans d&apos;inactivité :</strong> Pour des
            raisons de sécurité, si vous ne vous êtes pas authentifié sur le Site pendant une
            période de trois ans, vous recevrez un email vous invitant à vous connecter dans les
            plus brefs délais, sans quoi vos données seront supprimées de nos bases de données.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            8. SUPPRESSION DU COMPTE
          </h2>
          <p className='text-gray-700'>
            <strong>Suppression du compte à la demande de l’utilisateur :</strong>{' '}
            L&apos;utilisateur a la possibilité de supprimer son Compte à tout moment, par simple
            demande à l&apos;Éditeur.
            <br />
            <strong>
              Suppression du compte en cas de violation de la Politique de Confidentialité :
            </strong>
            En cas de violation d&apos;une ou de plusieurs dispositions de la Politique de
            Confidentialité ou de tout autre document incorporé aux présentes par référence,
            l&apos;Éditeur se réserve le droit de mettre fin ou restreindre sans aucun avertissement
            préalable.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            9. TRANSFERT DES DONNÉES PERSONNELLES À L&apos;ETRANGER
          </h2>
          <p className='text-gray-700'>
            Pas de transfert en dehors de l&apos;Union européenne : L&apos;Éditeur s&apos;engage à
            ne pas transférer les données personnelles de ses Utilisateurs en dehors de l&apos;Union
            européenne.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            10. MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ
          </h2>
          <p className='text-gray-700'>
            En cas de modification de la présente Politique de Confidentialité : Nous nous engageons
            à vous informer en cas de modification substantielle de la présente Politique de
            Confidentialité, et à ne pas baisser le niveau de confidentialité de vos données de
            manière substantielle sans vous en informer et obtenir votre consentement.
          </p>
        </section>

        <section>
          <h2 className='text-2xl font-semibold mb-2' style={{ color: '#ff5733' }}>
            11. PORTABILITÉ DES DONNÉES
          </h2>
          <p className='text-gray-700'>
            <strong>Portabilité des données :</strong> L&apos;Éditeur s&apos;engage à vous offrir la
            possibilité de vous faire restituer l&apos;ensemble des données vous concernant sur
            simple demande. L&apos; utilisateur se voit ainsi garantir une meilleure maîtrise de ses
            données, et garde la possibilité de les réutiliser. Ces données devront être fournies
            dans un format ouvert et aisément réutilisable.
          </p>
        </section>
      </div>
    </div>
  )
}

export default PrivacyPolicyPage
