import React from 'react'
import { FaInstagram, FaFacebook, FaLinkedin } from 'react-icons/fa'
import logoWhite from '../../assets/images/logo_final/logo-blanc.png'

interface SocialLinkProps {
  icon: React.ReactElement
  url: string
}

const SocialLink: React.FC<SocialLinkProps> = ({ icon, url }) => {
  return (
    <a href={url} target='_blank' rel='noopener noreferrer' className='social-media-link'>
      {icon}
    </a>
  )
}

const Footer: React.FC = () => {
  return (
    <footer className='bg-[#4e4e4e] mt-80 relative   pb-10  -z-5'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 -50 1440 319'
        className='absolute bottom-full left-0 w-full  '
      >
        <path
          fill='#4e4e4e'
          fillOpacity='1'
          d='M0,128L48,117.3C96,107,192,85,288,80C384,75,480,85,576,117.3C672,149,768,203,864,202.7C960,203,1056,149,1152,144C1248,139,1344,181,1392,202.7L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
        ></path>
      </svg>
      <div className='flex gap-10 px-32'>
        <div className=''>
          <img src={logoWhite} alt='Logo' className='w-24 h-auto' />
        </div>
        <div className='w-full flex flex-wrap justify-around items-start gap-8 mx-auto'>
          <div className='flex text-center flex-col'>
            <h3 className='text-white'>En savoir plus</h3>
            <a href='#' className='text-gray-300 text-center hover:text-white mt-3'>
              Lire notre blog
            </a>
            <a href='/a-propos-de-nous' className='text-center text-gray-300 hover:text-white mt-3'>
              A propos de Voyo
            </a>
            <a
              href='/fonctionnement-voyo'
              className='text-center text-gray-300 hover:text-white mt-3'
            >
              Fonctionnement de Voyo
            </a>
          </div>
          <div className='flex text-center flex-col'>
            <h3 className='text-white'>Charte Voyo</h3>
            <a
              href='/conditions-générales'
              className='text-center text-gray-300 hover:text-white mt-3'
            >
              Conditions générales
            </a>
            <a
              href='/politique-confidentialite'
              className='text-center text-gray-300 hover:text-white mt-3'
            >
              Politique de confidentialité
            </a>
            <a
              href='/politique-utilisation-cookies'
              className='text-center text-gray-300 hover:text-white mt-3'
            >
              Politique d’utilisation des cookies
            </a>
            <a href='/DAC7' className='text-center text-gray-300 hover:text-white mt-3'>
              DAC7
            </a>
          </div>
          <div className='flex text-center flex-col'>
            <div>
              <h3 className='text-white text-center'>Besoin d’aide ?</h3>
              <a
                href='mailto:hello@voyo.fr'
                className='text-center text-gray-300 hover:text-white mt-3'
              >
                hello@voyo.fr
              </a>
            </div>

            <div className='flex gap-4 items-center justify-center mt-5 '>
              <div className='rounded-full bg-[#181818] p-1 flex items-center justify-center hover:scale-125'>
                <SocialLink icon={<FaInstagram fill='white' />} url='https://www.instagram.com/' />
              </div>
              <div className='rounded-full bg-[#181818] p-1 flex items-center justify-center hover:scale-125'>
                <SocialLink icon={<FaLinkedin fill='white' />} url='https://www.linkedin.com/' />
              </div>
              <div className='rounded-full bg-[#181818] p-1 flex items-center justify-center hover:scale-125'>
                <SocialLink icon={<FaFacebook fill='white' />} url='https://www.facebook.com/' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
